<template>
	<div class="appGrey">
		{{ computedInit }}

		<!--Form-->
		<v-row no-gutters>

			<!--Task Name-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textInput"
								:clearable="true"
								:disabled="isReadOnly"
								:error="errors.toDoTaskName"
								:error-message="errors.toDoTaskNameErrorMessage"
								label="Task Name"
								v-model.trim="form.toDoTaskName"/>
			</v-col>

			<!--Task Description -->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:clearable="true"
								:disabled="isReadOnly"
								:error="errors.toDoTaskDescription"
								:error-message="errors.toDoTaskDescriptionErrorMessage"
								label="Task Description"
								v-model.trim="form.toDoTaskDescription"/>
			</v-col>

			<!--Task Priority (dropdown, optional, default: Low) -->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:disabled="isReadOnly || !!listData.toDoListPriority"
								:hint="listData.toDoListPriority && 'Locked: Set by List'"
								:items="['Low', 'Medium', 'High']"
								label="Task Priority"
								:persistent-hint="!!listData.toDoListPriority"
								v-model.trim="form.toDoTaskPriority"/>
			</v-col>

			<!--Make Private (checkbox, optional) If the list is set to private, all tasks are private.-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<div class="d-flex">

					<!--Checkbox-->
					<div @click="handleIsPrivate"
						 class="appWhite d-flex align-center justify-center rounded-lg mr-4 pa-4"
						 style="height: 56px; width: 56px;">
						<app-icon :color="form.toDoTaskIsPrivate ? 'green' : 'red'"
								  :disabled="isReadOnly || listData.toDoListIsPrivate || listData.toDoListAssignedTo"
								  :icon="form.toDoTaskIsPrivate ? 'check' : 'close'"
								  size="32"/>
					</div>

					<!--Label-->
					<div>
						<app-text>Make Private</app-text>
						<app-text v-if="form.toDoTaskAssignedTo"
								color="orange"
								size="small-bold">
							You cannot make a shared task private
						</app-text>
						<app-text class="mt-2" color="grey9" size="small">
							Private tasks will only be visible to you
						</app-text>

						<!--Set if the parent List has set a default-->
						<app-text v-if="listData.toDoListIsPrivate" color="grey9" size="small">
							Locked: Set by List
						</app-text>

					</div>

				</div>

			</v-col>

			<!--Assign Task To (dropdown, optional) -->
			<!--If assigned to is set, private cannot be set and must be false-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:clearable="true"
								:disabled="isReadOnly || !!listData.toDoListAssignedTo  || form?.toDoTaskIsPrivate"
								:hint="listData.toDoListAssignedTo && 'Locked: Set by List'" 
								:items="usersData"
								item-text="userName"
								label="Assign Task To (optional)"
								:return-object="true"
								:persistent-hint="!!listData.toDoListAssignedTo"
								v-model.trim="assignToUserObject"/>
								<app-text v-if="form?.toDoTaskIsPrivate" color="orange" size="small">
									You cannot share a private task
								</app-text>					
			</v-col>

			<!--Due Date (date picker, optional) -->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<date-picker @emitDate="handleDateSelection($event, 'toDoTaskDueDate')"
							 :clearable="true"
							 :disabled="isReadOnly || !!listData.toDoListDueDate"
							 :date="form.toDoTaskDueDate"
							 :hint="listData.toDoListDueDate ? 'Locked: Set by List' : ''"
							 label="Task Due (optional)"
							 :max-date="[10, 'years', 'future']"
							 :persistent-hint="!!listData.toDoListDueDate"
							 v-model.trim="form.toDoTaskDueDate"/>
			</v-col>
		</v-row>

		<!--Cancel Button | Save Button-->
		<div class="mb-4 mt-4 d-flex justify-space-between">
		<!--Cancel Button-->
			<app-btn @click.native="$emit('closeTaskForm')"
					:color="'grey'"
					icon="close"
					:label="'Cancel'"/>

			<!--Save Button-->
			<app-btn @click.native="handleSaveItem"
					color="green"
					icon="save"
					label="Save"/>
		</div>
	</div>
</template>
<script>
export default {

	name: 'ToDoTaskForm',

	props: ['formData', 'isReadOnly', 'listData', 'usersData'],

	data: () => ({
		errors: {
			toDoTaskDescription: false,
			toDoTaskDescriptionErrorMessage: '',
			toDoTaskName: false,
			toDoTaskNameErrorMessage: '',
		},

		assignToUserObject: {},

		form: {
			toDoTaskAssignedTo: '',
			toDoTaskDescription: '',
			toDoTaskDueDate: 0,
			toDoTaskIsPrivate: false,
			toDoTaskName: '',
			toDoTaskPriority: 'Low',
			toDoTaskStatus: 'Incomplete',
			toDoTaskListId: '',

			createdDateTime: 0,
			createdUserId: '',
			createdUserName: '',

			modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',

			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: '',
			isDeleted: false,
		},
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * If there is any form data, assign it to the form.
		 * If there is no form data, it will be blank (new).
		 * 
		 * If statements also to check whether list defaults are set on all tasks
		 */
		computedInit() {
			const t = this
			const FORM_DATA = t.$props.formData

			if (FORM_DATA?.entityId) {
				t.form = FORM_DATA
				t.assignToUserObject = t.$props.usersData.find(item => item.entityId === FORM_DATA.toDoTaskAssignedTo)
			}
			if (t.$props.listData?.entityId) t.form.toDoTaskListId = t.$props.listData.entityId
			if (t.$props.listData?.toDoListPriority) t.form.toDoTaskPriority = t.$props.listData.toDoListPriority
			if (t.$props.listData?.toDoListIsPrivate) t.form.toDoTaskIsPrivate = t.$props.listData.toDoListIsPrivate
			if (t.$props.listData?.toDoListAssignedTo) t.form.toDoTaskAssignedTo = t.$props.listData.toDoListAssignedTo
			if (t.$props.listData?.toDoListDueDate) t.form.toDoTaskDueDate = t.$props.listData.toDoListDueDate
		},
	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {
				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false
			}
		},

		/**
		 * Create Item
		 *
		 * Create the item in the DB.
		 */
		async createItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_create('toDoTask', t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating ToDo Task: ', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem creating the ToDo Task, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Created Task'

			t.$emit('emitReloadData')
			t.$emit('closeTaskForm')
		},

		/**
		 * Handle Date Picker
		 *
		 * Takes the emitted payload as $event data from the input field, and the field name,
		 * and sets the date to the specified form field.
		 *
		 * @param date the date to set
		 * @param field the form field to set
		 */
		handleDateSelection(date, field) {
			const t = this

			// Set the date to the current form field
			t.form[field] = Number(date)
		},

		/**
		 * Handle Is Private
		 *
		 * Toggle the isPrivate boolean.
		 */
		handleIsPrivate() {
			const t = this

			// If the list is private, all tasks are private
			if (t.$props.listData.toDoListIsPrivate || t.form.toDoTaskAssignedTo) return

			t.form.toDoTaskIsPrivate = !t.form.toDoTaskIsPrivate
		},

		/**
		 * Handle Save Item
		 *
		 * Create/Update the item in the DB, after the form has passed validation.
		 */
		handleSaveItem() {
			const t = this

			// Only continue if the form has passed validation
			if (!t.validateForm()) return

			// Set the list ID
			t.form.toDoTaskListId = t.$props.listData.entityId

			t.form.toDoTaskAssignedTo = t.assignToUserObject?.entityId || ""

			// Create
			if (!t.form?.entityId) t.createItem()

			//Update
			if (t.form?.entityId) t.updateItem()
		},

		/**
		 * Update Item
		 *
		 * Update the item in the DB.
		 */
		async updateItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_update('toDoTask', t.form.entityId, t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating ToDo Task: ', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem updating the ToDo Task, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Updated Task'

			t.$emit('emitReloadPage')
			t.$emit('closeTaskForm')
		},

		/**
		 * Validate Form
		 *
		 * Validate the required form fields and return a boolean if the form has passed, or not.
		 *
		 * @returns {boolean} - if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Task Name
			if (!t.form?.toDoTaskName) {
				t.errors.toDoTaskName = true
				t.errors.toDoTaskNameErrorMessage = 'A Task Name is required.'
			}

			// Task Description
			if (!t.form?.toDoTaskDescription) {
				t.errors.toDoTaskDescription = true
				t.errors.toDoTaskDescriptionErrorMessage = 'A Task Description is required.'
			}

			return !Object.values(t.errors).includes(true)
		},
	},
}
</script>
<style scoped></style>
