<template>
	<div class="appGrey" style="min-height: 100%">

		{{ computedInit }}

		<!--Form-->
		<div>

			<div class="d-flex flex-row justify-space-between align-center mt-4">

				<!--Qualification File-->
				<div class="d-flex align-center">
					<div class="d-flex justify-center align-center appWhite rounded-lg mr-4 pa-2"
						 style="height: 72px; width: 72px">

						<!--Temporary Image-->
						<v-img v-if="tempQualificationFile"
							   :src="tempQualificationFile"
							   class="rounded-lg"
							   max-height="70px"
							   max-width="70px"
							   :style="$vuetify.breakpoint.width < 800 ? 'height: 70px' : 'aspect-ratio: 1/1; height: 70px; width: 70px'"/>

						<!--Uploaded Image-->
						<v-img v-if="!tempQualificationFile && form.qualificationFileId"
							   @click.native="handlePreview"
							   class="rounded-lg"
							   :src="MIX_getImagePath('qualificationFiles', uploadedQualificationFile.fileName, uploadedQualificationFile.fileToken)"
							   max-height="70px"
							   max-width="70px"
							   :style="$vuetify.breakpoint.width < 800 ? 'height: 70px' : 'aspect-ratio: 1/1; height: 70px; width: 70px'"/>

						<!--Default Image-->
						<app-icon v-if="!tempQualificationFile && !form.qualificationFileId"
								  color="grey9"
								  icon="missingImage"
								  size="45"/>
					</div>

					<!--Image Name Label-->
					<div>
						<!--No file uploaded text-->
						<app-text v-if="!tempQualificationFile && !form.qualificationFileId"
								  color="grey9"
								  size="small">
							No file uploaded
						</app-text>
						<!--Temporary file name label-->
						<app-text v-if="tempQualificationFile"
								  class="text-truncate"
								  color="grey9"
								  size="small">
							{{ uploadedQualificationFile.fileName }}
						</app-text>
						<!--Existing file name label-->
						<app-text v-if="form.qualificationFileId && !tempQualificationFile"
								  class="text-truncate"
								  size="small">
							{{ uploadedQualificationFile.fileName }}
						</app-text>
					</div>
				</div>

				<!--Preview Dialog-->
				<v-dialog v-if="form.qualificationFileId" max-width="75vw" v-model="isImagePreviewVisible">

					<!--Toolbar-->
					<v-toolbar color="appGrey">
						<v-spacer/>
						<close-icon @click.native="closeImagePreviewDialog()"/>
					</v-toolbar>

					<!--Image-->
					<v-img
						:src="MIX_getImagePath('qualificationFiles', uploadedQualificationFile.fileName, uploadedQualificationFile.fileToken)"
						cover/>
				</v-dialog>

				<!--Image Upload button-->
				<div>
					<image-upload v-if="!isReadOnly"
								  @emitImageUpload="emittedImageUpload"
								  folder="qualificationFiles"
								  :id-for-image="form.entityId"
								  image-for="qualificationFile"
								  label="Upload"/>
				</div>

			</div>

			<!--Image Error Message-->
			<app-text v-if="errors.qualificationFileId"
					  color="red"
					  size="small">
				{{ errors.qualificationFileIdErrorMessage }}
			</app-text>

			<!--Qualification Name Dropdown-->
			<app-form-field form-type="autoComplete"
							class="mt-4"
							:clearable="true"
							:disabled="isReadOnly || !!form?.qualificationTemporaryName"
							:error="errors.qualificationName"
							:error-message="errors.qualificationNameErrorMessage"
							:items="qualificationPresetsData"
							item-text="qualificationName"
							label="Qualification Name"
							:return-object="true"
							v-model="displayQualificationObject"/>

			<!--Add your own Qualification Name-->
			<v-row v-if="!isReadOnly || !displayQualificationObject?.entityId"
				   no-gutters
				   class="d-flex align-center mt-4">
				<!--Text-->
				<v-col :class="$vuetify.breakpoint.width < 600 ? 'mb-4' : 'pr-2'"
					   :cols="$vuetify.breakpoint.width < 600 ? 12 : 2">
					<app-text size="small">Or add your own</app-text>
				</v-col>
				<!--Text input-->
				<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
					   :cols="$vuetify.breakpoint.width < 600 ? 12 : 10">
					<app-form-field :disabled="isReadOnly || !!displayQualificationObject?.entityId"
									form-type="textInput"
									:error="errors.qualificationTemporaryName"
									:error-message="errors.qualificationTemporaryNameErrorMessage"
									label="What's the new qualification name?"
									v-model="form.qualificationTemporaryName"/>
				</v-col>
			</v-row>

			<!--Category-->
			<app-form-field form-type="autoComplete"
							class="mt-4"
							:clearable="true"
							:disabled="isReadOnly"
							:error="errors.qualificationCategoryName"
							:error-message="errors.qualificationCategoryNameErrorMessage"
							:items="qualificationCategoriesData"
							item-text="qualificationCategoryName"
							label="Category"
							:return-object="true"
							v-model="displayCategoryObject"/>

			<!--Add your own Category-->
			<v-row v-if="!isReadOnly || !displayCategoryObject?.entityId || !displayQualificationObject?.entityId"
				   no-gutters class="d-flex align-center mt-4">
				<!--Text-->
				<v-col :class="$vuetify.breakpoint.width < 600 ? 'mb-4' : 'pr-2'"
					   :cols="$vuetify.breakpoint.width < 600 ? 12 : 2">
					<app-text size="small">Or add your own</app-text>
				</v-col>
				<!--Text input-->
				<v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
					   :cols="$vuetify.breakpoint.width < 600 ? 12 : 10">
					<app-form-field
						:disabled="isReadOnly || !!qualificationCategoriesData.find(category => category.qualificationCategoryName === displayCategoryObject)?.entityId"
						:error="errors.qualificationTemporaryCategoryName"
						:error-message="errors.qualificationTemporaryCategoryNameErrorMessage"
						form-type="textInput"
						label="What's the new category name?"
						v-model="form.qualificationTemporaryCategoryName"/>
				</v-col>
			</v-row>

			<!--Issue Date | Expiry Date-->
			<v-row no-gutters class="mt-4">
				<!--Issue Date-->
				<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'mt-4 pr-2'"
					   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
					<date-picker @emitDate="handleDateSelection($event, 'qualificationIssueDate')"
								 :clearable="true"
								 :date="form.qualificationIssueDate"
								 :disabled="isReadOnly"
								 :error="errors.qualificationIssueDate"
								 :error-message="errors.qualificationIssueDateErrorMessage"
								 label="Issue Date"
								 :min-date="[10, 'years', 'past']"
								 v-model.trim="form.qualificationIssueDate"/>
				</v-col>
				<!--Expiry Date-->
				<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
					   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
					<date-picker @emitDate="handleDateSelection($event, 'qualificationExpiryDate')"
								 :clearable="true"
								 :date="form.qualificationExpiryDate"
								 :disabled="isReadOnly"
								 :error="errors.qualificationExpiryDate"
								 :error-message="errors.qualificationExpiryDateErrorMessage"
								 label="Expiry Date"
								 :max-date="[10, 'years', 'future']"
								 v-model.trim="form.qualificationExpiryDate"/>
				</v-col>
			</v-row>

			<!--Status-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'pa-0 my-2' : 'pa-0 mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field :disabled="isReadOnly"
								form-type="autoComplete"
								:items="['Pending', 'Approved', 'Rejected']"
								label="Status"
								v-model="form.qualificationStatus"/>
			</v-col>

		</div>

		<!--Save Button-->
		<div class="d-flex justify-end">
			<app-btn v-if="!isReadOnly"
					 @click.native="handleSaveItem"
					 color="green"
					 icon="save"
					 label="Save"/>
		</div>

	</div>
</template>

<script>
export default {

	name: 'QualificationsAdminForm',

	props: ['formData', 'isReadOnly', 'qualificationCategoriesData', 'qualificationPresetsData', 'usersData'],

	data: () => ({
		errors: {
			qualificationFileId: false,
			qualificationFileIdErrorMessage: '',
			qualificationName: false,
			qualificationNameErrorMessage: '',
			qualificationTemporaryName: false,
			qualificationTemporaryNameErrorMessage: '',
			qualificationCategoryName: false,
			qualificationCategoryNameErrorMessage: '',
			qualificationTemporaryCategoryName: false,
			qualificationTemporaryCategoryNameErrorMessage: '',
			qualificationIssueDate: false,
			qualificationIssueDateErrorMessage: '',
			qualificationExpiryDate: false,
			qualificationExpiryDateErrorMessage: '',
		},

		form: {
			entityId: '',
			qualificationCategoryName: '',
			// qualificationCategoryId: '',
			qualificationTemporaryCategoryName: '',
			qualificationExpiryDate: 0,
			qualificationFileId: '',
			qualificationIssueDate: 0,
			qualificationName: '',
			qualificationStatus: 'Pending',
			qualificationTemporaryName: '',
			qualificationUserId: '',

			createdDateTime: 0,
			createdUserId: '',
			createdUserName: '',
			modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: '',
			isDeleted: false,
		},

		categoryForm: {
			entityId: '',
			qualificationCategoryName: '',
			qualificationCategoryTemporaryName: '',

			createdDateTime: 0,
			createdUserId: '',
			createdUserName: '',

			modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',

			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: '',
			isDeleted: false,
		},

		presetForm: {
			entityId: '',
			qualificationName: '',
			qualificationCategoryId: '',
			qualificationTemporaryCategoryName: '',

			createdDateTime: 0,
			createdUserId: '',
			createdUserName: '',

			modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',

			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: '',
			isDeleted: false,
		},

		doesCategoryExist: false,
		doesQualificationExist: false,
		displayCategoryObject: {},
		displayQualificationObject: {},
		imageFolderName: 'qualificationFiles',
		isImagePreviewVisible: false,
		tempQualificationFile: '',
		uploadedQualificationFile: {},
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * If there is any form data, assign it to the form.
		 * If there is no form data, it will be blank (new).
		 */
		computedInit() {
			const t = this

			const FORM_DATA = t.$props.formData
			const PRESETS_DATA = t.$props.qualificationPresetsData
			const CATEGORIES_DATA = t.$props.qualificationCategoriesData

			if (FORM_DATA?.entityId) {
				t.form = FORM_DATA
				t.displayQualificationObject = PRESETS_DATA.find(preset => preset.entityId === t.form.qualificationPresetId)
				// if (t.form?.qualificationCategoryId) {
				// 	t.displayCategoryObject = CATEGORIES_DATA.find(category => category.entityId === t.form.qualificationCategoryId)
				// }


			}
		},

		/**
		 * Get Category By ID
		 *
		 * Get the category name by its ID.
		 *
		 * @returns {string} the category name
		 */
		getCategoryById() {
			const t = this
			const CATEGORIES_DATA = t.$props.qualificationCategoriesData
			const DISPLAY_QUALIFICATION_CATEGORY = t.displayQualificationObject?.qualificationCategoryId

			// Find the category by its ID
			const CATEGORY = CATEGORIES_DATA.find(category => category.entityId === DISPLAY_QUALIFICATION_CATEGORY)

			// Set the category object to the form
			t.displayCategoryObject = CATEGORY

			return CATEGORY?.qualificationCategoryName || 'None'
		},

	},

	methods: {

		/**
		 * Check Category
		 *
		 * Check if the category exists in the DB.
		 * If it does, show the error message.
		 * If it doesn't, create the new category.
		 */
		async checkCategory() {
			const t = this
			const CATEGORIES_DATA = t.$props.qualificationCategoriesData

			// Check if the category exists in the DB
			const existingCategory = CATEGORIES_DATA.find(category => category.qualificationCategoryName === t.form.qualificationTemporaryCategoryName)

			// If the category exists, show the error message. If it doesn't, create the new category.
			if (existingCategory?.qualificationCategoryName) {
				t.doesCategoryExist = true
			} else if (!existingCategory?.qualificationCategoryName) {
				t.doesCategoryExist = false
				await t.createCategory()
			}
		},

		/**
		 * Check Name
		 *
		 * Check if the name exists in the DB.
		 * If it does, show the error message.
		 * If it doesn't, create the new category.
		 */
		async checkName() {
			const t = this
			const QUALIFICATIONS_DATA = t.$props.qualificationPresetsData

			// Check if the qualification exists in the DB
			const existingQualification = QUALIFICATIONS_DATA.find(qualificationPreset => qualificationPreset.qualificationName === t.form.qualificationTemporaryName)

			// If the qualification exists, show the error message. If it doesn't, create the new qualification.
			if (existingQualification?.qualificationName) {
				t.doesQualificationExist = true
			} else {
				t.doesQualificationExist = false
				await t.createNewQualificationName()
			}
		},

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false
			}
		},

		/**
		 * Close Image Preview Dialog
		 *
		 * Close the image preview dialog.
		 */
		closeImagePreviewDialog() {
			const t = this

			// Clear the selected image data
			t.selectedImage = {}

			// Close the image preview dialog
			t.isImagePreviewVisible = false
		},

		/**
		 * Create Category
		 *
		 * Create the new category in the DB.
		 */
		async createCategory() {
			const t = this

			// Set the category name to the temporary name
			t.categoryForm.qualificationCategoryName = t.form.qualificationTemporaryCategoryName

			const RESPONSE = await t.MIX_redis_create('qualificationCategory', t.categoryForm)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating category: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem creating this category, please try again.'
				return
			}

			t.categoryForm.entityId = RESPONSE.data.entityId
			t.presetForm.qualificationCategoryId = RESPONSE.data.entityId
		},

		/**
		 * Create New Qualification Preset
		 *
		 * Create the new qualification preset in the DB.
		 */
		async createNewQualificationPreset() {
			const t = this

			// Set the qualification preset name
			t.presetForm.qualificationName = t.form.qualificationName

			// Set the qualification preset category ID
			t.presetForm.qualificationCategoryId = t.categoryForm.entityId

			const RESPONSE = await t.MIX_redis_create('qualificationPreset', t.presetForm)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating qualification: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem creating this qualification, please try again.'
				return
			}

			t.presetForm.entityId = RESPONSE.data.entityId
		},

		/**
		 * Create New Qualification
		 *
		 * Create the form and save to the DB.
		 */
		async createNewQualificationName() {
			const t = this

			// Set the qualification name to the temporary name
			t.form.qualificationName = t.form.qualificationTemporaryName
		},

		/**
		 * Create Qualification
		 *
		 * Create the form and save to the DB.
		 */
		async createQualification() {
			const t = this

			// Set the file ID
			t.form.qualificationFileId = t.uploadedQualificationFile.entityId

			const RESPONSE = await t.MIX_redis_create('qualification', t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating Qualification: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem creating this Qualification, please try again.'
				return
			}

			// Update the uploaded file doc link with newly created qualification entity id
			await t.updateUploadedQualificationFile(RESPONSE.data?.entityId)

			t.$sharedState.successMessage = 'Created Qualification'
			t.$emit('reloadData')
		},

		/**
		 * Emitted Image Upload
		 *
		 * When an image is uploaded, break apart the downloadURL and save what's required.
		 * Then, update the form with the image ID.
		 *
		 * @param payload - The image data and download URL
		 */
		async emittedImageUpload(payload) {
			const t = this

			// Set the temp profile picture to render in the UI
			t.tempQualificationFile = payload.downloadURL

			const {FOLDER_NAME, IMAGE_NAME, TOKEN} = t.MIX_extractImageDataFromUrl(payload.downloadURL)

			// Save the image data to the DB (Files collection), and add the returned image ID to the form
			t.form.qualificationFileId = await t.saveImageData(
				t.form.entityId,
				FOLDER_NAME,
				IMAGE_NAME,
				payload.image.size,
				TOKEN,
				payload.image.type
			)
		},

		/**
		 * Handle Date Picker
		 *
		 * Takes the emitted payload as $event data from the input field, and the field name,
		 * and sets the date to the specified form field.
		 *
		 * @param date the date to set
		 * @param field the form field to set
		 */
		handleDateSelection(date, field) {
			const t = this

			// Set the date to the current form field
			t.form[field] = Number(date)
		},

		/**
		 * Handle Preview
		 *
		 * Handle the preview of the image.
		 *
		 * @param image {object} - The image data
		 */
		handlePreview() {
			const t = this

			t.openImagePreviewDialog()

			//Set the selected image data
			t.selectedImage = {
				fileName: t.uploadedQualificationFile.fileName,
				fileToken: t.uploadedQualificationFile.fileToken,
			}
		},

		/**
		 * Handle Save Item
		 *
		 * Handle whether to create or update the item to the DB, after it has passed validation.
		 */
		async handleSaveItem() {
			const t = this

			// If the user has typed their own qualification name, check if it already exists
			if (t.form.qualificationTemporaryName) await t.checkName()

			// Clear doesCategoryExist before the check
			t.doesCategoryExist = false

			// If the user has typed their own category name, check if it already exists
			if (t.form.qualificationTemporaryCategoryName) await t.checkCategory()

			// Only save if the form has passed validation
			if (!t.validateForm()) return

			// Set the qualification user ID
			t.form.qualificationUserId = t.form.qualificationUserId || t.$props.usersData?.entityId || ''

			// Store the ID from the object
			// If the name is selected from the list of presets, set the ID to the form
			if (t.displayQualificationObject?.entityId) {
				t.form.qualificationName = t.displayQualificationObject.qualificationName || ''
				t.form.qualificationCategoryId = t.displayQualificationObject.qualificationCategoryId || ''
			}

			// If the category is selected from the list, set the ID to the form
			if (t.displayCategoryObject?.entityId) {
				t.form.qualificationCategoryId = t.displayCategoryObject.entityId || ''
				t.form.qualificationTemporaryCategoryName = ''
				t.doesCategoryExist = false
			}

			// Set the temporary name to blank
			t.form.qualificationTemporaryName = ''

			// Set the temporary category name to blank
			t.form.qualificationTemporaryCategoryName = ''

			// Create
			if (!t.form.entityId) await t.createQualification()

			// Create New Preset
			// If the qualification name is not selected from the list, create a new preset
			if (!t.displayQualificationObject?.entityId) await t.createNewQualificationPreset()

			// Update
			if (t.form.entityId) await t.updateQualification()
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadFileData(),
			])

			t.isLoading = false
		},

		/**
		 * Load File Data
		 *
		 * Load the file data from the DB.
		 */
		async loadFileData() {
			const t = this

			// Get files where the fileDocLink is equal to the qualification entityId
			const RESPONSE = await t.MIX_redis_getFilesWhere({
				whereKey: 'fileDocLink',
				whereValue: t.form.entityId
			})

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading file data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem loading your data, please try again.'
				return
			}

			//Find the file with the most recent created date
			const FILE_DATA = RESPONSE.data.sort((a, b) => b.createdDateTime - a.createdDateTime)[0]

			t.uploadedQualificationFile = FILE_DATA
		},

		/**
		 * Open Image Preview Dialog
		 *
		 * Open the image preview dialog.
		 */
		openImagePreviewDialog() {
			const t = this

			t.isImagePreviewVisible = true
		},

		/**
		 * Save Image Data
		 *
		 * Save the image data to the DB (Files collection).
		 *
		 * @param fileDocLink {string} - The file's document link
		 * @param fileFolder {string} - The storage folder
		 * @param fileName {string} - The file name
		 * @param fileSize {number} - The file size
		 * @param fileToken {string} - The file token
		 * @param fileType {string} - The file type
		 * @returns {Promise<*>} - The image ID
		 */
		async saveImageData(fileDocLink, fileFolder, fileName, fileSize, fileToken, fileType) {
			const t = this

			const IMAGE_DATA = {
				fileDocLink,
				fileFolder,
				fileName,
				fileSize,
				fileToken,
				fileType,

				createdDateTime: 0,
				createdUserId: '',
				createdUserName: '',
				modifiedDateTime: 0,
				modifiedUserId: '',
				modifiedUserName: '',

				isDeleted: false,
				deletedDateTime: 0,
				deletedUserId: '',
				deletedUserName: '',
			}

			//Create the file
			const RESPONSE = await t.MIX_redis_create('file', IMAGE_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error saving File Data to DB:', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem uploading your file, please try again.'
				return
			}

			return RESPONSE.data?.entityId
		},

		/**
		 * Update Qualification
		 *
		 * Update the form and save to the DB.
		 */
		async updateQualification() {
			const t = this

			// Set the Preset ID
			if (!t.form.qualificationPresetId) t.form.qualificationPresetId = t.presetForm.entityId

			const RESPONSE = await t.MIX_redis_update('qualification', t.form.entityId, t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating qualification: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem updating, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Updated Qualification'

			// Reload the data
			t.$emit('reloadData')
		},

		/**
		 * Update Uploaded Qualification File
		 *
		 * Update the uploaded file doc link with newly created qualification entity id.
		 */
		async updateUploadedQualificationFile() {
			const t = this

			// Update the uploaded file doc link with newly created qualification entity id
			t.uploadedQualificationFile.fileDocLink = t.form.entityId

			const RESPONSE = await t.MIX_redis_update('file', t.uploadedQualificationFile.entityId, t.uploadedQualificationFile)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating qualification: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem updating, please try again.'
				return
			}
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Qualification File
			const QUALIFICATION_FILE = t.form.qualificationFileId
			const QUALIFICATION_TEMPORARY_FILE = t.tempQualificationFile
			if (!QUALIFICATION_FILE && !QUALIFICATION_TEMPORARY_FILE) {
				t.errors.qualificationFileId = true
				t.errors.qualificationFileIdErrorMessage = 'Qualification file required'
			}

			// Qualification Name
			const QUALIFICATION_NAME = t.displayQualificationObject?.entityId
			const QUALIFICATION_TEMPORARY_NAME = t.form.qualificationTemporaryName
			if (!QUALIFICATION_NAME && !QUALIFICATION_TEMPORARY_NAME) {
				t.errors.qualificationName = true
				t.errors.qualificationNameErrorMessage = 'Please select or enter a qualification'
				t.errors.qualificationTemporaryName = true
				t.errors.qualificationTemporaryNameErrorMessage = 'Please select or enter a qualification'
			}

			// Qualification Name Already Exists
			if (t.doesQualificationExist) {
				t.errors.qualificationTemporaryName = true
				t.errors.qualificationTemporaryNameErrorMessage = 'This qualification already exists. Please select this qualification from list above.'
			}

			// Qualification Category
			const QUALIFICATION_CATEGORY = t.form.qualificationCategoryId
			const QUALIFICATION_DISPLAY_CATEGORY = t.displayCategoryObject?.entityId
			const QUALIFICATION_TEMPORARY_CATEGORY_NAME = t.form.qualificationTemporaryCategoryName
			if (!QUALIFICATION_CATEGORY && !QUALIFICATION_DISPLAY_CATEGORY && !QUALIFICATION_TEMPORARY_CATEGORY_NAME) {
				t.errors.qualificationCategoryId = true
				t.errors.qualificationCategoryIdErrorMessage = 'Please select or enter a category'
				t.errors.qualificationTemporaryCategoryName = true
				t.errors.qualificationTemporaryCategoryNameErrorMessage = 'Please select or enter a category'
			}

			// Qualification Category Already Exists
			if (t.doesCategoryExist) {
				t.errors.qualificationTemporaryCategoryName = true
				t.errors.qualificationTemporaryCategoryNameErrorMessage = 'This category already exists. Please select this qualification from list above.'
			}

			// Qualification Issue Date
			const QUALIFICATION_ISSUE_DATE = t.form.qualificationIssueDate
			if (!QUALIFICATION_ISSUE_DATE) {
				t.errors.qualificationIssueDate = true
				t.errors.qualificationIssueDateErrorMessage = 'Qualification issue date required'
			}

			// Qualification Expiry Date
			const QUALIFICATION_EXPIRY_DATE = t.form.qualificationExpiryDate
			if (!QUALIFICATION_EXPIRY_DATE) {
				t.errors.qualificationExpiryDate = true
				t.errors.qualificationExpiryDateErrorMessage = 'Qualification expiry date required'
			}

			return !Object.values(t.errors).includes(true)
		},

	},

	watch: {
		/**
		 * Display Qualification Object
		 *
		 * Watch for changes to the displayQualificationObject and update the form.
		 */
		displayQualificationObject() {
			const t = this

			t.displayCategoryObject = t.getCategoryById
			// t.form.qualificationName = t.displayQualificationObject.qualificationName
		},

		/**
		 * Display Category Object
		 *
		 * Watch for changes to the displayCategoryObject and update the temporary name to blank if category is selected from the dropdown.
		 */
		displayCategoryObject() {
			const t = this

			t.form.qualificationTemporaryCategoryName = ''
		},

		/**
		 * Qualification Temporary Name
		 *
		 * Watch for changes to the qualificationTemporaryName and update this value with the typed value.
		 */
		'form.qualificationTemporaryName': {
			handler() {
				const t = this

				t.form.qualificationTemporaryName = t.form.qualificationTemporaryName
			},
			deep: true,
		},

	},

	async mounted() {
		const t = this

		await t.loadData()
	},
}

</script>

<style scoped>

.text-truncate {
	width: 100px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

</style>

