<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--No Team Message - Stewards Only-->
	<div v-else-if="MIX_getCurrentUser().userType === 'Steward' && !MIX_getCurrentUser()?.userTeams.length">

		<!--Header-->
		<page-title :divider="true"
					icon="teams"
					info="View and administer Teams"
					title="Teams"/>

		<!--Message-->
		<app-text class="text-center mt-4">
			You have not been added to a team yet.
			<br>
			<br>
			Please contact Admin on:
			<br>
			<br>
			<span class="primary--text"><a :href="`mailto:${appConfig.appBranding.stewardsHelpLink}`">
				{{ appConfig.appBranding.stewardsHelpLink }}</a>
			</span>
		</app-text>
	</div>

	<!--Page Content-->
	<div v-else>

		<!--Header-->
		<page-title :divider="true"
					icon="teams"
					info="View and administer Teams"
					title="Teams"/>

		<!--Action Bar-->
		<div class="d-flex align-center mt-4">

			<!--Search-->
			<app-form-field form-type="textInput"
							append-icon="icons8-search"
							class="mr-4"
							:clearable="true"
							label="Team Name"
							style="width: 100%"
							v-model.trim="searchByTeamName"/>

			<!--Add Button-->
			<app-btn v-if="MIX_getCurrentUser().userLevel === 'Staff-Admin'"
					 @click.native="openRightPanel" class="mr-4" icon="add" label="Add"/>

			<!--Filter Button - with a numbered badge-->
			<div style="position:relative;">
				<app-btn @click.native="toggleFiltersVisibility"
						 class="mr-4" color="appWhite" icon="filter" icon-color="primary"/>
				<span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>
			</div>

			<!--More Actions Menu-->
			<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>

		</div>

		<!--Clear Filters-->
		<div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
			<app-btn @click.native="clearFilters" icon="close" label="Clear Filters"/>
		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="appWhite rounded-lg mt-4"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.id="{item}">
				<app-text size="small">{{ item.id }}</app-text>
			</template>

			<!--Team Name-->
			<template v-slot:item.teamName="{item}">
				<app-text size="small">{{ item.teamData.teamName }}</app-text>
			</template>

			<!--Team Supervisors-->
			<template v-slot:item.supervisorsData="{item}">
				<app-text v-for="item in item.supervisorsData" :key="item.entityId" size="small">
					{{ item.userName }} <span v-if="item.userInternalIdNumber !== 'S00000'"
											  class="grey--text">({{ item.userInternalIdNumber }})</span>
				</app-text>
				<app-text v-if="!item.supervisorsData.length" color="red" size="small">None</app-text>
			</template>

			<!--Team Deputies-->
			<template v-slot:item.deputiesData="{item}">
				<app-text v-for="item in item.deputiesData" :key="item.entityId" class="my-2" size="small">
					{{ item.userName }} <span v-if="item.userInternalIdNumber !== 'S00000'"
											  class="grey--text">({{ item.userInternalIdNumber }})</span>
				</app-text>
				<app-text v-if="!item.deputiesData.length" color="red" size="small">None</app-text>
			</template>

			<!--Team Stewards-->
			<template v-slot:item.stewardsData="{item}">
				<app-text :color="item.stewardsData.length ? '' : 'red'" size="small">{{ item.stewardsData.length }}
				</app-text>
			</template>

			<!--Team Quota-->
			<template v-slot:item.teamQuota="{item}">
				<app-text size="small">{{ item.teamData.teamQuota }}</app-text>
			</template>

			<!--Action Button-->
			<template v-slot:item.action="{item}">
				<app-icon @click.native="openRightPanel(item)"
						  class="cursorPointer" color="primary" icon="arrowForward"/>
			</template>

		</v-data-table>

		<!--Mobile Cards-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No data message-->
			<app-text v-if="!computedTableData.length"
					  class="text-center mt-4" color="grey9">You have no Teams to view
			</app-text>

			<team-mobile-card v-for="item in computedTableData" :key="item?.teamData?.entityId"
							  :teamUsersData="item"/>

		</div>

		<!--Filters Panel ------------------------------------------------------------------------------------------ -->
		<filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
			<div class="pa-4">

				<!--Area-->
				<page-break-title title="Area"/>
				<div class="mt-4">
					<app-form-field form-type="textInput"
									label="Area"
									v-model.trim="filterByArea"/>
				</div>

				<!--Quota-->
				<page-break-title class="mt-8" title="Quota"/>
				<div class="d-flex">

					<!--Less Than-->
					<app-form-field form-type="textInput"
									class="mt-4 pr-2"
									label="Less Than"
									style="width: 100%"
									type="number"
									v-model="filterByQuotaLessThan"/>

					<!--More Than-->
					<app-form-field form-type="textInput"
									class="mt-4 pl-2"
									label="More Than"
									style="width: 100%"
									type="number"
									v-model="filterByQuotaMoreThan"/>
				</div>

				<!--Supervisor-->
				<page-break-title class="mt-8" title="Supervisor"/>
				<div class="mt-4">
					<app-form-field form-type="textInput"
									label="Supervisor"
									v-model.trim="filterBySupervisor"/>
				</div>

				<!--Deputy-->
				<page-break-title class="mt-8" title="Deputy"/>
				<div class="mt-4">
					<app-form-field form-type="textInput"
									label="Deputy"
									v-model.trim="filterByDeputy"/>
				</div>

				<!--Steward-->
				<page-break-title class="mt-8" title="Steward"/>
				<div class="mt-4">
					<app-form-field form-type="textInput"
									label="Steward"
									v-model.trim="filterBySteward"/>
				</div>

			</div>
		</filter-panel>

		<!--Right Side ----------------------------------------------------------------------------------- Right Side-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">

			<div v-if="isRightPanelVisible"
				 class="appGrey formShadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'">

				<!--Header-->
				<div class="d-flex align-center primary pa-4">
					<app-text color="appWhite" size="normal">{{ selectedItem?.teamData?.teamName || 'New' }}</app-text>
					<v-spacer/>
					<app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close"
							  size="32"/>
				</div>

				<!--Action Bar-->
				<div class="d-flex align-center pa-4">

					<v-spacer/>

					<edit-icon v-if="tabs === 'team'" @click.native="editItem" :isActive="!isReadOnly"/>
					<delete-icon
						v-if="tabs === 'team' && selectedItem?.teamData?.entityId && ['Staff-Admin'].includes(MIX_getCurrentUser().userLevel)"
						@emitDeleteItem="deleteItem(selectedItem?.teamData)"
						itemName="teamName"
						:selectedItem="selectedItem?.teamData"/>

				</div>

				<!--Tabs-->
				<v-tabs v-model="tabs">

					<!--Team-->
					<v-tab href="#team">
						<app-text size="small">Team</app-text>
					</v-tab>

					<!--Supervisors & Deputies-->
					<v-tab v-if="selectedItem?.teamData?.entityId" href="#supervisors">
						<app-text size="small">Supervisors</app-text>
					</v-tab>

					<!--Stewards-->
					<v-tab v-if="selectedItem?.teamData?.entityId" href="#stewards">
						<app-text size="small">Stewards</app-text>
					</v-tab>

				</v-tabs>

				<!--Tabs Content-->
				<v-tabs-items v-model="tabs">

					<!--Team-->
					<v-tab-item value="team">
						<team-form @emitReloadPage="emittedReloadPage"
								   class="pa-4"
								   :formData="selectedItem && selectedItem.teamData"
								   :isReadOnly="isReadOnly"/>
					</v-tab-item>

					<!--Supervisors/Deputies-->
					<v-tab-item value="supervisors">
						<team-supervisor-form @emitReloadData="emittedReloadData"
											  class="pa-4"
											  :formData="selectedItem"
											  :isReadOnly="isReadOnly"
											  :usersData="usersData"/>
					</v-tab-item>

					<!--Stewards-->
					<v-tab-item value="stewards">
						<team-steward-form @emitReloadData="emittedReloadData"
										   class="pa-4"
										   :formData="selectedItem"
										   :isReadOnly="isReadOnly"
										   :usersData="usersData"/>
					</v-tab-item>

				</v-tabs-items>

			</div>

		</transition>

	</div>

</template>

<script>
import appConfig from '../../../appConfig.json'
import TeamForm from "@/views/teams/teamForm/TeamForm";
import TeamMobileCard from "@/views/teams/teamMobileCard/TeamMobileCard";
import TeamSupervisorForm from "@/views/teams/teamSupervisorForm/TeamSupervisorForm";
import TeamStewardForm from "@/views/teams/teamStewardForm/TeamStewardForm";

export default {

	name: "Teams",

	components: {TeamStewardForm, TeamSupervisorForm, TeamMobileCard, TeamForm},

	data: () => ({
		appConfig,
		filterByArea: '',
		filterByDeputy: '',
		filterByQuotaLessThan: null,
		filterByQuotaMoreThan: null,
		filterBySteward: '',
		filterBySupervisor: '',
		isFiltersPanelVisible: false,
		isRightPanelVisible: false,
		isLoading: true,
		isReadOnly: false,
		moreActionsMenuOption: [
			{name: 'Export', icon: 'export'}
		],
		searchByTeamName: '',
		searchUsers: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'id', align: 'start', sortable: false, hidden: true},
			{text: 'Team', value: 'teamName', align: 'start', sortable: false},
			{text: 'Supervisors', value: 'supervisorsData', align: 'start', sortable: false},
			{text: 'Deputies', value: 'deputiesData', align: 'start', sortable: false},
			{text: 'Stewards', value: 'stewardsData', align: 'center', sortable: false},
			{text: 'Quota', value: 'teamQuota', align: 'center', sortable: false},
			{text: '', value: 'action', align: 'center', sortable: false, width: '48px'},
		],
		tabs: '',

		teamsData: [],
		usersData: [],
	}),

	computed: {

		/**
		 * Computed Export CSV
		 *
		 * Return the data and headers for the CSV export
		 *
		 * @returns {{headers: {}, data: *[]}}
		 */
		computedExportCSV() {
			const t = this
			let data = []
			let headers = {}

			// Add the readable headers for the CSV columns
			headers = {
				entityId: 'ID',
				teamName: 'Name',
				teamArea: 'Area',
				teamDescription: 'Description',
				teamQuota: 'Quota',
			}

			// Add the data
			t.computedTableData.forEach(entry => {

				const DATA_OBJECT = {
					entityId: entry?.teamData?.entityId,
					teamName: entry?.teamData?.teamName,
					teamArea: entry?.teamData?.teamArea,
					teamDescription: entry?.teamData?.teamDescription,
					teamQuota: entry?.teamData?.teamQuota,
				}

				data.push(DATA_OBJECT)
			})

			return {headers, data}
		},

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter(h => !h.hidden)

			// Only those with access can view the actions column
			if (!['Staff-Admin', 'Staff-Manager'].includes(CURRENT_USER_DATA.userLevel)) headers = headers.filter(h => h.value !== 'action')

			return headers
		},

		/**
		 * Computed Number of Active Filters
		 *
		 * Count the number of active filters to display in the filters button badge.
		 *
		 * @returns {*}
		 */
		computedNumberOfActiveFilters() {
			const t = this
			return [
				t.filterByArea ? 1 : 0,
				t.filterByDeputy ? 1 : 0,
				t.filterByQuotaLessThan ? 1 : 0,
				t.filterByQuotaMoreThan ? 1 : 0,
				t.filterBySteward ? 1 : 0,
				t.filterBySupervisor ? 1 : 0,
			].reduce((acc, curr) => acc + curr, 0)
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the table data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			const TEAMS_DATA = t.teamsData
			const USERS_DATA = t.usersData
			let tableData = []

			TEAMS_DATA.forEach(team => {

				const USERS_ON_TEAM = USERS_DATA.filter(user => user.userTeams?.includes(team.entityId))
				const TEAM_SUPERVISORS = USERS_ON_TEAM?.filter(user => (user.userLevel === 'Steward-Supervisor' || user.userPayGrade === 'Chief Steward'))
				const TEAM_DEPUTIES = USERS_ON_TEAM?.filter(user => (user.userLevel === 'Steward-Deputy' || user.userPayGrade === 'Deputy Chief Steward'))
				const TEAM_STEWARDS = USERS_ON_TEAM?.filter(user => (user.userLevel === 'Steward-User' || user.userPayGrade === 'Senior Supervisor'))

				const TEAM_USER_OBJECT = {
					teamData: team,
					supervisorsData: TEAM_SUPERVISORS,
					deputiesData: TEAM_DEPUTIES,
					stewardsData: TEAM_STEWARDS,
				}

				tableData.push(TEAM_USER_OBJECT)
			})

			// If the user is a Steward, only show their team
			if (t.MIX_getCurrentUser().userType === 'Steward') tableData = tableData.filter(item => t.MIX_getCurrentUser().userTeams?.includes(item.teamData.entityId))

			// Search by Team
			if (t.searchByTeamName) {
				const SEARCH_CRITERIA = t.searchByTeamName.toUpperCase()
				tableData = tableData.filter(td => {
					const TEAM_NAME = td.teamData.teamName.toUpperCase()
					return TEAM_NAME.includes(SEARCH_CRITERIA)
				})
			}

			// Filter by Area
			if (t.filterByArea) {
				const SEARCH_CRITERIA = t.filterByArea.toUpperCase()
				tableData = tableData.filter(td => {
					const AREA = td.teamData.teamArea.toUpperCase()
					return AREA.includes(SEARCH_CRITERIA)
				})
			}

			// Filter by Quota within a range
			if (t.filterByQuotaLessThan && t.filterByQuotaMoreThan) {
				tableData = tableData.filter(item => item.teamData.teamQuota < t.filterByQuotaLessThan && item.teamData.teamQuota > t.filterByQuotaMoreThan)
			} else {

				// Filter by "Less Than"
				if (t.filterByQuotaLessThan) tableData = tableData.filter(item => item.teamData.teamQuota < t.filterByQuotaLessThan)

				// Filter by "More Than"
				if (t.filterByQuotaMoreThan) tableData = tableData.filter(item => item.teamData.teamQuota > t.filterByQuotaMoreThan)
			}

			// Filter by Supervisor
			if (t.filterBySupervisor) {
				const SEARCH_CRITERIA = t.filterBySupervisor.toUpperCase()
				tableData = tableData.filter(td => {
					if (!td.supervisorsData || !Array.isArray(td.supervisorsData)) return false
					const SUPERVISORS = td.supervisorsData.map(s => s.userName.toUpperCase())
					return SUPERVISORS.some(supervisor => supervisor.includes(SEARCH_CRITERIA))
				})
			}

			// Filter by Deputy
			if (t.filterByDeputy) {
				const SEARCH_CRITERIA = t.filterByDeputy.toUpperCase()
				tableData = tableData.filter(td => {
					if (!td.deputiesData || !Array.isArray(td.deputiesData)) return false
					const DEPUTIES = td.deputiesData.map(d => d.userName.toUpperCase())
					return DEPUTIES.some(deputy => deputy.includes(SEARCH_CRITERIA))
				})
			}

			// Filter by Steward
			if (t.filterBySteward) {
				const SEARCH_CRITERIA = t.filterBySteward.toUpperCase()
				tableData = tableData.filter(td => {
					if (!td.stewardsData || !Array.isArray(td.stewardsData)) return false
					const STEWARDS = td.stewardsData.map(s => s.userName.toUpperCase())
					return STEWARDS.some(steward => steward.includes(SEARCH_CRITERIA))
				})
			}

			tableData = tableData.sort((a, b) => a.teamData.teamName > b.teamData.teamName ? 1 : -1)

			return tableData
		},

	},

	methods: {

		/**
		 * Clear Filters
		 *
		 * Clear all the filters.
		 */
		clearFilters() {
			const t = this

			t.filterByArea = ''
			t.filterByDeputy = ''
			t.filterByQuotaLessThan = null
			t.filterByQuotaMoreThan = null
			t.filterBySteward = ''
			t.filterBySupervisor = ''
		},

		/**
		 * Close Right Panel
		 *
		 * Close the right panel.
		 */
		closeRightPanel() {
			const t = this

			t.tabs = ''
			t.selectedItem = {}
			t.isRightPanelVisible = false
		},

		/**
		 *
		 * Delete Item
		 *
		 * Delete the selected document by its ID.
		 *
		 * @param itemData {object} the item to delete
		 */
		async deleteItem(itemData) {
			const t = this

			const RESPONSE = await t.MIX_redis_delete('team', itemData.entityId, itemData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting Team', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Deleted Team'

			// Reload the data
			await t.loadData()

			// Close the panel
			t.closeRightPanel()
		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Emitted Reload Data
		 *
		 * Reload the data to refresh.
		 */
		emittedReloadData() {
			const t = this

			t.loadData()
		},

		/**
		 * Emitted Reload Page
		 *
		 * Close the panel and Reload the data to refresh the page.
		 */
		emittedReloadPage() {
			const t = this

			t.closeRightPanel()
			t.loadData()
		},

		/**
		 * Export Data
		 *
		 * Export the data to CSV.
		 */
		exportData() {
			const t = this

			t.MIX_exportDocuments(t.computedExportCSV.headers, 'Teams', t.computedExportCSV.data)
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'New') t.openRightPanel()
			if (selection === 'Export') t.exportData()
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadTeamsData(),
				t.loadUsersData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Teams Data
		 *
		 * Load Teams data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadTeamsData() {
			const t = this

			// Fetch the data
			// const RESPONSE = await t.MIX_redis_getAll('team')
			const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
				'Team',
				[],
				[
					{field: 'teamArea'},
					{field: 'teamDescription'},
					{field: 'teamName'},
					{field: 'teamQuota'}
				]
			)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Teams: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.teamsData = RESPONSE.data
		},

		/**
		 * Load Users Data
		 *
		 * Load Users data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadUsersData() {
			const t = this
			let usersData = []

			// Fetch the data
			// const RESPONSE = await t.MIX_redis_getUsersWhere([{whereKey: 'userType', whereValue: 'Steward'}])
			const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
				'User',
				[
					{whereKey: 'userStatus', whereValue: 'Approved'},
					{whereKey: 'userType', whereValue: 'Steward'}
				],
				[
					{field: 'userInternalIdNumber'},
					{field: 'userLevel'},
					{field: 'userName'},
					{field: 'userTeams'},
					{field: 'userType'},
					{field: 'userEmail'},
					{field: 'userTelephone'},
					{field: 'userPayGrade'}
				]
			)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Users: ', RESPONSE.error)
				return
			}

			// If the response is an object, return it as an array
			usersData = RESPONSE.data.entityId ? usersData.push(RESPONSE.data) : RESPONSE.data

			// Assign the data to the state
			t.usersData = usersData
		},

		/**
		 * Open Form
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		openRightPanel(item) {
			const t = this

			// New
			if (!item?.teamData?.entityId) {
				t.isReadOnly = false
				t.selectedItem = {}
			}

			// Existing
			else {
				t.isReadOnly = true
				t.selectedItem = item
			}

			t.isRightPanelVisible = true
		},

		/**
		 * Toggle Filters Visibility
		 *
		 * Toggle the visibility of the filters panel.
		 */
		toggleFiltersVisibility() {
			const t = this

			t.isFiltersPanelVisible = !t.isFiltersPanelVisible
		},

	},

	async mounted() {
		const t = this

		await t.loadData()
	},

}
</script>

<style scoped>
</style>
