<template>
	<div class="appGrey" style="min-height: 100%">

		{{ computedInit }}

		<div>

			<div class="d-flex flex-row justify-space-between align-center">
				<!--Qualfication File-->
				<div class="d-flex align-center">
					<div class="d-flex justify-center align-center appWhite rounded-lg mr-4 pa-2"
						 style="height: 72px; width: 72px">

						<!--Temporary Image-->
						<v-img v-if="tempQualificationFile && !form.qualificationFileId"
							   :src="tempQualificationFile"
							   class="rounded-lg"
							   max-height="70px"
							   max-width="70px"
							   :style="$vuetify.breakpoint.width < 800 ? 'height: 70px' : 'aspect-ratio: 1/1; height: 70px; width: 70px'"/>

						<!--Uploaded Image-->
						<v-img v-if="!tempQualificationFile && form.qualificationFileId"
							   @click.native="handlePreview"
							   class="rounded-lg"
							   :src="MIX_getImagePath('qualificationFiles', formData.fileData.fileName, formData.fileData.fileToken)"
							   max-height="70px"
							   max-width="70px"
							   :style="$vuetify.breakpoint.width < 800 ? 'height: 70px' : 'aspect-ratio: 1/1; height: 70px; width: 70px'"/>

						<!--Default Image-->
						<app-icon v-if="!tempQualificationFile && !form.qualificationFileId"
								  color="grey9"
								  icon="missingImage"
								  size="45"/>
					</div>

					<!--Image Name label-->
					<div>
						<!--No file uploaded text-->
						<app-text v-if="!tempQualificationFile && !form.qualificationFileId"
								  color="grey9"
								  size="small">
							No file uploaded
						</app-text>
						<!--Temporary file name label-->
						<app-text v-if="tempQualificationFile"
								  class="text-truncate"
								  color="grey9"
								  size="small">
							{{ uploadedQualificationFile.fileName }}
						</app-text>
						<!--Existing file name label-->
						<app-text v-if="form.qualificationFileId"
								  class="text-truncate"
								  size="small">
							{{ formData.fileData.fileName }}
						</app-text>
					</div>
				</div>

				<!--Preview Dialog-->
				<v-dialog v-if="form.qualificationFileId" max-width="75vw" v-model="isImagePreviewVisible">

					<!--Toolbar-->
					<v-toolbar color="appGrey">
						<v-spacer/>
						<close-icon @click.native="closeImagePreviewDialog()"/>
					</v-toolbar>

					<!--Image-->
					<v-img
						:src="MIX_getImagePath('qualificationFiles', formData.fileData.fileName, formData.fileData.fileToken)"
						cover/>
				</v-dialog>

				<!--Image Upload button-->
				<div>
					<image-upload v-if="!isReadOnly"
								  @emitImageUpload="emittedImageUpload"
								  folder="qualificationFiles"
								  :id-for-image="form.entityId"
								  image-for="qualificationFile"
								  label="Upload"/>
				</div>
			</div>

			<!--Image Error Message-->
			<app-text v-if="errors.qualificationFileId"
					  color="red"
					  size="small">
				{{ errors.qualificationFileIdErrorMessage }}
			</app-text>

			<!--Qualification Name Dropdown-->
			<app-form-field form-type="autoComplete"
							class="mt-4"
							:clearable="true"
							:disabled="isReadOnly || !form?.qualificationTemporaryName === ''"
							:error="errors.qualificationName"
							:error-message="errors.qualificationNameErrorMessage"
							:items="qualificationPresetsData"
							item-text="qualificationName"
							label="Qualification Name"
							:return-object="true"
							v-model="displayQualificationObject"/>
			<app-text v-if="displayQualificationObject?.entityId && displayQualificationObject?.qualificationName" color="grey9">
				in <b>{{ getCategoryById }}</b>
			</app-text>

			<!--Add your own Qualification Name Input-->
			<v-row v-if="!displayQualificationObject?.entityId" no-gutters class="d-flex align-center mt-4">
				<!--Text-->
				<v-col :class="$vuetify.breakpoint.width < 600 ? 'mb-4' : 'pr-2'"
					   :cols="$vuetify.breakpoint.width < 600 ? 12 : 2">
					<app-text size="small">Or add your own</app-text>
				</v-col>
				<!--Text input-->
				<v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
					   :cols="$vuetify.breakpoint.width < 600 ? 12 : 10">
					<app-form-field :disabled="isReadOnly || displayQualificationObject?.entityId"
									:error="errors.qualificationTemporaryName"
									:error-message="errors.qualificationTemporaryNameErrorMessage"
									form-type="textInput"
									label="What's the new qualification name?"
									v-model="form.qualificationTemporaryName"/>
				</v-col>
			</v-row>

			<!--Issue Date | Expiry Date-->
			<v-row no-gutters class="mt-4">
				<!--Issue Date-->
				<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
					   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
					<date-picker @emitDate="handleDateSelection($event, 'qualificationIssueDate')"
								 :clearable="true"
								 :date="form.qualificationIssueDate"
								 :disabled="isReadOnly"
								 :error="errors.qualificationIssueDate"
								 :error-message="errors.qualificationIssueDateErrorMessage"
								 label="Issue Date"
								 :min-date="[10, 'years', 'past']"
								 v-model.trim="form.qualificationIssueDate"/>
				</v-col>
				<!--Expiry Date-->
				<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'"
					   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
					<date-picker @emitDate="handleDateSelection($event, 'qualificationExpiryDate')"
								 :clearable="true"
								 :date="form.qualificationExpiryDate"
								 :disabled="isReadOnly"
								 :error="errors.qualificationExpiryDate"
								 :error-message="errors.qualificationExpiryDateErrorMessage"
								 label="Expiry Date"
								 :max-date="[10, 'years', 'future']"
								 v-model.trim="form.qualificationExpiryDate"/>
				</v-col>
			</v-row>

		</div>

		<!--Cancel Button | Save Button (Users View)-->
		<div v-if="parentView === 'users'" class="py-4 d-flex justify-space-between">
			<!-- Cancel Button-->
			<app-btn @click.native="$emit('closeQualificationForm')"
					:color="'grey'"
					icon="close"
					:label="'Cancel'"/>
			<!--Save Button-->
			<app-btn v-if="!isReadOnly"
					@click.native="handleSaveItem"
					color="green"
					icon="save"
					label="Save"/>
		</div>

		<!--Save Button only (MyProfile View)-->
		<div v-if="parentView === 'myProfile'" class="py-4 d-flex justify-end">
			<app-btn v-if="!isReadOnly"
					@click.native="handleSaveItem"
					color="green"
					icon="save"
					label="Save"/>
		</div>

	</div>
</template>

<script>
export default {

	name: 'UserQualificationForm',

	props: ['formData', 'fullUserData', 'isReadOnly', 'parentView', 'qualificationCategoriesData', 'qualificationPresetsData'],

	data: () => ({
		errors: {
			qualificationFileId: false,
			qualificationFileIdErrorMessage: '',
			qualificationName: false,
			qualificationNameErrorMessage: '',
			qualificationTemporaryName: false,
			qualificationTemporaryNameErrorMessage: '',
			qualificationIssueDate: false,
			qualificationIssueDateErrorMessage: '',
			qualificationExpiryDate: false,
			qualificationExpiryDateErrorMessage: '',
		},

		form: {
			entityId: '',
			qualificationPresetId: '',
			qualificationCategoryId: '',
			qualificationFileId: '',
			qualificationExpiryDate: 0,
			qualificationIssueDate: 0,
			qualificationName: '',
			qualificationStatus: 'Pending',
			qualificationTemporaryName: '',
			qualificationUserId: '',

			createdDateTime: 0,
			createdUserId: '',
			createdUserName: '',

			modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',

			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: '',
			isDeleted: false,

		},

		doesQualificationExist: false,
		displayQualificationObject: {},
		imageFolderName: 'qualificationFiles',
		isImagePreviewVisible: false,
		selectedImage: {},
		tempQualificationFile: '',
		uploadedQualificationFile: {},
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * If there is any form data, assign it to the form.
		 * If there is no form data, it will be blank (new).
		 */
		computedInit() {
			const t = this
			const FORM_DATA = t.$props.formData.qualificationData

			if (FORM_DATA?.entityId) {
				t.form = FORM_DATA
				t.displayQualificationObject = t.$props.formData?.qualificationPresetData
			}
		},


		/**
		 * Get Category By ID
		 *
		 * Get the category name by its ID.
		 *
		 * @returns {string} the category name
		 */
		getCategoryById() {
			const t = this
			const DISPLAY_CATEGORY = t.displayQualificationObject.qualificationCategoryId

			const CATEGORY = t.$props.qualificationCategoriesData.find(category => category.entityId === DISPLAY_CATEGORY)

			return CATEGORY?.qualificationCategoryName || 'None'
		},
	},

	methods: {

		/**
		 * Check Name
		 *
		 * Check if the name exists in the DB.
		 * If it does, show the error message.
		 * If it doesn't, save the category as normal.
		 */
		async checkName() {
			const t = this

			const QUALIFICATIONS_DATA = t.$props.qualificationPresetsData

			// Check if the name exists in the DB
			const existingQualification = QUALIFICATIONS_DATA.find(qualificationPreset => qualificationPreset.qualificationName === t.form.qualificationTemporaryName)

			// If the name exists, show the error message
			if (existingQualification?.qualificationName) {
				t.doesQualificationExist = true
			} else {
				t.doesQualificationExist = false
			}

		},

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false
			}
		},

		/**
		 * Close Image Preview Dialog
		 *
		 * Close the image preview dialog.
		 */
		closeImagePreviewDialog() {
			const t = this

			t.selectedImage = {}
			t.isImagePreviewVisible = false
		},

		/**
		 * Create Item
		 *
		 * Create the form and save to the DB.
		 */
		async createItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_create('qualification', t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating Qualification: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem creating this Qualification, please try again.'
				return
			}

			// Update the uploaded file doc link with newly created qualification entity id
			await t.updateUploadedQualificationFile(RESPONSE.data?.entityId)

			t.$sharedState.successMessage = 'Created Qualification'
			t.$emit('emitReloadData')
		},

		/**
		 * Emitted Image Upload
		 *
		 * When an image is uploaded, break apart the downloadURL and save what's required.
		 * Then, update the form with the image ID.
		 *
		 * @param payload - The image data and download URL
		 */
		async emittedImageUpload(payload) {
			const t = this

			// Set the temp profile picture to render in the UI
			t.tempQualificationFile = payload.downloadURL

			const {FOLDER_NAME, IMAGE_NAME, TOKEN} = t.MIX_extractImageDataFromUrl(payload.downloadURL)

			// Save the image data to the DB (Files collection), and add the returned image ID to the form
			t.form.qualificationFileId = await t.saveImageData(
				t.form.entityId,
				FOLDER_NAME,
				IMAGE_NAME,
				payload.image.size,
				TOKEN,
				payload.image.type
			)
		},

		/**
		 * Handle Date Picker
		 *
		 * Takes the emitted payload as $event data from the input field, and the field name,
		 * and sets the date to the specified form field.
		 *
		 * @param date the date to set
		 * @param field the form field to set
		 */
		handleDateSelection(date, field) {
			const t = this

			// Set the date to the current form field
			t.form[field] = Number(date)
		},

		/**
		 * Handle Save Item
		 *
		 * Handle whether to create or update the item to the DB, after it has passed validation.
		 */
		async handleSaveItem() {
			const t = this

			// Clear doesQualificationExist before the check
			t.doesQualificationExist = false

			// If the user has entered their own name, check if it already exists
			if (t.form.qualificationTemporaryName) await t.checkName()

			// Only save if the form has passed validation
			if (!t.validateForm()) return

			// Set the qualification preset ID
			t.form.qualificationPresetId = t.displayQualificationObject?.entityId || ''

            // Set the qualification file ID
			t.form.qualificationFileId = t.uploadedQualificationFile.entityId

			// Set the qualification user ID
			t.form.qualificationUserId = t.$props.fullUserData?.userData.entityId || ''

			// Store the ID from the object
			// If the name is selected from the list, set the ID to the form
			// if (t.displayQualificationObject?.entityId) {
			// 	t.form.qualificationName = t.displayQualificationObject.qualificationName || ''
			// 	t.form.qualificationCategoryId = t.displayQualificationObject.qualificationCategoryId || ''
			// }

			// Create
			if (!t.form.entityId) await t.createItem()

			// Update
			if (t.form.entityId) await t.updateItem()
		},

		/**
		 * Handle Preview
		 *
		 * Handle the preview of the image.
		 *
		 * @param image {object} - The image data
		 */
		handlePreview() {
			const t = this

			t.openImagePreviewDialog()

			t.selectedImage = {
				fileName: t.$props.formData.fileData.fileName,
				fileToken: t.$props.formData.fileData.fileToken,
			}
		},

		/**
		 * Open Image Preview Dialog
		 *
		 * Open the image preview dialog.
		 */
		openImagePreviewDialog() {
			const t = this

			t.isImagePreviewVisible = true
		},

		/**
		 * Save Image Data
		 *
		 * Save the image data to the DB (Files collection).
		 *
		 * @param fileDocLink {string} - The file's document link
		 * @param fileFolder {string} - The storage folder
		 * @param fileName {string} - The file name
		 * @param fileSize {number} - The file size
		 * @param fileToken {string} - The file token
		 * @param fileType {string} - The file type
		 * @returns {Promise<*>} - The image ID
		 */
		async saveImageData(fileDocLink, fileFolder, fileName, fileSize, fileToken, fileType) {
			const t = this

			const IMAGE_DATA = {
				fileDocLink,
				fileFolder,
				fileName,
				fileSize,
				fileToken,
				fileType,

				createdDateTime: 0,
				createdUserId: '',
				createdUserName: '',
				modifiedDateTime: 0,
				modifiedUserId: '',
				modifiedUserName: '',

				isDeleted: false,
				deletedDateTime: 0,
				deletedUserId: '',
				deletedUserName: '',
			}

			const RESPONSE = await t.MIX_redis_create('file', IMAGE_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error saving File Data to DB:', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem uploading your file, please try again.'
				return
			}

			t.uploadedQualificationFile = RESPONSE.data

			//Store this id in the qualification file id
			t.form.qualificationFileId = RESPONSE.data?.entityId

		},

		/**
		 * Update Item
		 *
		 * Update the form and save to the DB.
		 */
		async updateItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_update('qualification', t.form.entityId, t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating qualification: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem updating, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Updated Qualification'
			t.$emit('emitReloadData')
		},

		/**
		 * Update Uploaded Qualification File
		 *
		 * Update the uploaded qualification file with the qualification ID.
		 *
		 * @param qualificationId {string} - The qualification ID
		 * @returns {Promise<void>}
		 */
		async updateUploadedQualificationFile(qualificationId) {
			const t = this

			//Update the doclink
			t.uploadedQualificationFile.fileDocLink = qualificationId

			const RESPONSE = await t.MIX_redis_update('file', t.uploadedQualificationFile.entityId, t.uploadedQualificationFile)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating qualification: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem updating, please try again.'
				return
			}
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Qualification File
			const QUALIFICATION_FILE = t.form.qualificationFileId
			const QUALIFICATION_TEMPORARY_FILE = t.tempQualificationFile
			if (!QUALIFICATION_FILE && !QUALIFICATION_TEMPORARY_FILE) {
				t.errors.qualificationFileId = true
				t.errors.qualificationFileIdErrorMessage = 'Qualification file required'
			}

			// Qualification Name
			const QUALIFICATION_NAME = t.displayQualificationObject?.entityId
			const QUALIFICATION_TEMPORARY_NAME = t.form.qualificationTemporaryName
			if (!QUALIFICATION_NAME && !QUALIFICATION_TEMPORARY_NAME) {
				t.errors.qualificationName = true
				t.errors.qualificationNameErrorMessage = 'Please select or enter a qualification'
				t.errors.qualificationTemporaryName = true
				t.errors.qualificationTemporaryNameErrorMessage = 'Please select or enter a qualification'
			}

			// Qualification Name Already Exists
			if (t.doesQualificationExist === true) {
				t.errors.qualificationTemporaryName = true
				t.errors.qualificationTemporaryNameErrorMessage = 'This qualification already exists. Please select this qualification from list above.'
			}

			// Qualification Issue Date
			const QUALIFICATION_ISSUE_DATE = t.form.qualificationIssueDate
			if (!QUALIFICATION_ISSUE_DATE) {
				t.errors.qualificationIssueDate = true
				t.errors.qualificationIssueDateErrorMessage = 'Qualification issue date required'
			}

			// Qualification Expiry Date
			const QUALIFICATION_EXPIRY_DATE = t.form.qualificationExpiryDate
			if (!QUALIFICATION_EXPIRY_DATE) {
				t.errors.qualificationExpiryDate = true
				t.errors.qualificationExpiryDateErrorMessage = 'Qualification expiry date required'
			}

			return !Object.values(t.errors).includes(true)
		},

	},

	watch: {
        /**
         * Display Qualification Object
         *
         * Watch for changes to the displayQualificationObject and update the form.
         */


		// TODO fix this if delete button still needs to show temporary name or qualification name in the delete modal



        // displayQualificationObject() {
        //     const t = this

        //     t.form.qualificationTemporaryName = ''
        // },
	},
}

</script>

<style scoped>
.text-truncate {
	width: 100px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

</style>
