<template>
<div>

	<!--Name-->
	<div class="d-flex align-end">
		<app-icon class="mr-2" color="primary" icon="user"/>
		<app-text size="normal-bold">{{ userData.userName }}</app-text>
	</div>

	<!--Steward Number-->
	<div v-if="userData.userInternalIdNumber !== 'S00000'" class="d-flex align-end">
		<app-text class="ml-8" color="grey9">{{ userData.userInternalIdNumber }}</app-text>
	</div>

	<!--Email-->
	<div v-if="MIX_getCurrentUser().userLevel !== 'Steward-User' || (MIX_getCurrentUser().userLevel === 'Steward-User' && userData.userLevel !== 'Steward-User')"
		 class="d-flex align-end mt-2">
		<app-icon class="mr-2" color="primary" icon="email"/>
		<app-text>{{ userData.userEmail }}</app-text>
	</div>

	<!--Telephone-->
	<div v-if="MIX_getCurrentUser().userLevel !== 'Steward-User' || (MIX_getCurrentUser().userLevel === 'Steward-User' && userData.userLevel !== 'Steward-User')"
		 class="d-flex align-end mt-2">
		<app-icon class="mr-2" color="primary" icon="phone"/>
		<app-text>{{ userData.userTelephone }}</app-text>
	</div>

</div>
</template>

<script>
export default {

	name: "TeamMobileCardUserDetails",

	props: ['userData'],

}
</script>

<style scoped>

</style>
