<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<!--Tabs-->
		<v-tabs class="rounded-lg" v-model="pageTabs">

			<v-tab href="#organisations">
				<app-text size="small">Organisations</app-text>
			</v-tab>

		</v-tabs>

		<!--Tabs Content-->
		<v-tabs-items v-model="pageTabs">

			<v-tab-item class="appGrey pt-4" value="organisations">

				<!--Table-->
				<v-data-table v-if="$vuetify.breakpoint.width >= 600"
							  class="appWhite rounded-lg"
							  :headers="computedHeaders"
							  :items="computedOrganisationsData">

					<!--ID-->
					<template v-slot:item.entityId="{item}">
						<app-text size="small">{{ item.entityId }}</app-text>
					</template>

					<!--Organisation Name-->
					<template v-slot:item.organisationName="{item}">
						<app-text size="small">{{ item.organisationName }}</app-text>
					</template>

					<template v-slot:item.spaces="{item}">
						<app-text size="small">{{ item.organisationParkingAllocatedSpaces || 0 }}</app-text>
					</template>

					<template v-slot:item.carPark="{item}">
						<app-text size="small">{{ item.organisationParkingAllocatedCarPark || '-' }}</app-text>
					</template>

					<!--Actions-->
					<template v-slot:item.action="{item}">
						<app-icon @click.native="openRightPanel(item)"
								  class="cursorPointer" color="primary" icon="arrowForward"/>
					</template>

				</v-data-table>

			</v-tab-item>

		</v-tabs-items>

		<!--Right Side ----------------------------------------------------------------------------------- Right Side-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">

			<div v-if="isRightPanelVisible"
				 class="appGrey formShadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'"
				 v-model="isRightPanelVisible">

				<!--Header-->
				<div class="d-flex align-center primary pa-4">
					<app-text color="appWhite" size="normal">{{ selectedItem?.organisationName }}</app-text>
					<v-spacer/>
					<app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close"
							  size="32"/>
				</div>

				<!--Action Bar-->
				<div class="d-flex align-center pa-4">

					<!--Status Icon-->
					<div>
						<app-icon v-if="selectedItem?.carParkStatus === 'Open'" color="green" icon="success" size="48"/>
						<app-icon v-if="selectedItem?.carParkStatus === 'Closed'" color="red" icon="cancel" size="48"/>
					</div>

					<v-spacer/>

					<!--Action Buttons-->
					<edit-icon @click.native="editItem" :isActive="!isReadOnly"/>
					<delete-icon
						v-if="selectedItem?.entityId && ['Staff-Admin'].includes(MIX_getCurrentUser().userLevel)"
						@emitDeleteItem="deleteItem(selectedItem)"
						itemName=""
						:selectedItem="selectedItem"/>

				</div>

				<!--Tabs-->
				<v-tabs v-model="tabs">
					<v-tab href="#overview">
						<app-text size="small">Overview</app-text>
					</v-tab>
				</v-tabs>

				<!--Tabs Content-->
				<v-tabs-items v-model="tabs">
					<v-tab-item value="overview">

						<!--Form-->
						<parking-configuration-form :formData="selectedItem" :isReadOnly="isReadOnly"/>

					</v-tab-item>
				</v-tabs-items>

			</div>

		</transition>

	</div>
</template>

<script>

import ParkingCarParkForm from "@/views/parking/admin/parkingCarParks/parkingCarParkForm/ParkingCarParkForm.vue";
import ParkingConfigurationForm
	from "@/views/parking/admin/parkingConfiguration/parkingConfigurationForm/ParkingConfigurationForm.vue";

export default {

	name: "ParkingConfiguration",

	components: {ParkingConfigurationForm, ParkingCarParkForm},

	data: () => ({
		form: {
			organisationParkingAllocatedCarPark: '',
			organisationParkingAllocatedSpaces: 0,
		},
		tableHeaders: [
			{text: 'ID', value: 'entityId', align: 'left', sortable: false, hidden: true},
			{text: 'Organisation', value: 'organisationName', align: 'left', sortable: false, hidden: false},
			{text: 'Allocated Spaces', value: 'spaces', align: 'center', sortable: false, hidden: false},
			{text: 'Car Park', value: 'carPark', align: 'left', sortable: false, hidden: false},
			{text: '', value: 'action', align: 'right', sortable: false, width: '48px'},
		],
		isLoading: true,
		isReadOnly: true,
		isRightPanelVisible: false,
		pageTabs: '',
		selectedItem: {},
		tabs: '',

		// Data
		carParksData: [],
		organisationsData: [],
	}),

	computed: {

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			let headers = t.tableHeaders

			headers = headers.filter(h => !h.hidden)

			return headers
		},

		computedOrganisationsData() {
			const t = this
			let listData = t.organisationsData

			// Sort the data by name
			listData = listData.sort((a, b) => a.organisationName > b.organisationName ? 1 : -1)

			return listData
		}
	},

	methods: {

		/**
		 * Close Right Panel
		 *
		 * Close the right panel.
		 */
		closeRightPanel() {
			const t = this

			t.tabs = ''
			t.selectedItem = {}
			t.isRightPanelVisible = false
		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadCarParksData(),
				t.loadOrganisationsData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Car Parks Data
		 *
		 * Load all the Car Parks data.
		 *
		 * @returns {Promise<void>}
		 */
		async loadCarParksData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getAll('carPark')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Car Parks data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem loading this page, please try again.'
				return
			}

			// Assign the data
			t.carParksData = RESPONSE.data
		},

		/**
		 * Load Organisations Data
		 *
		 * Load all the Organisations data.
		 *
		 * @returns {Promise<void>}
		 */
		async loadOrganisationsData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getAll('organisation')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Organisations data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem loading this page, please try again.'
				return
			}

			// Assign the data
			t.organisationsData = RESPONSE.data
		},

		/**
		 * Open Form
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		openRightPanel(item) {
			const t = this

			t.selectedItem = item

			t.isRightPanelVisible = true
		},

	},

	async mounted() {
		const t = this

		await t.loadData()
	},

	beforeUpdate() {
		console.log('beforeUpdate')
	},

	updated() {
		console.log('updated')
	},

	beforeDestroy() {
		console.log('beforeDestroy')
	},

}
</script>

<style scoped>

</style>
