<template>
	<div class="" style="min-height: 100%">

		<!--Header-->
		<page-title
			:divider="true"
			icon="car"
			info="SWAPP Users QR codes on entry."
			title="Parking"/>

		<!--SWAPP Reader ------------------------------------------------------------------------------------------- -->
		<div v-if="!fullQrData?.parkingData?.length">

			<!--Error Messages-->
			<div v-if="error" class="centerInPage text-center pa-4">
				<app-text class="" v-if="noFrontCamera">You don't seem to have a front camera on your device</app-text>
				<app-text class="" v-if="noRearCamera">You don't seem to have a rear camera on your device</app-text>
				<app-text class="mt-4" v-if="error">{{ error }}</app-text>
			</div>

			<!--QR Reader-->
			<qrcode-stream v-if="!error"
						   @decode="onDecode"
						   @init="onInit"
						   :camera="camera || null"
						   class="appWhite animate__animated animate__zoomIn animate__faster"
						   :track="paintOutline"
						   style="border-radius: 14px">

				<!--Camera Buttons-->
				<!--<div class="qrReader-cameraButtons-container">-->
				<!--	<app-btn @click.native="switchCamera('default')" label="Default"/>-->
				<!--	<app-btn @click.native="switchCamera('front')" label="Front" class="mx-4"/>-->
				<!--	<app-btn @click.native="switchCamera('rear')" label="Rear" class="mr-4"/>-->
				<!--</div>-->

				<!--Loader-->
				<div class="d-flex align-center justify-center" style="height: 100%; width: 100%">
					<app-text v-if="isLoading" size="xlarge">Loading...</app-text>
				</div>

				<!--Instructions-->
				<div class="d-flex align-center justify-center"
					 style="height: 100%; width: 100%; position:absolute; top:0; bottom:0">

					<!--QR Icon-->
					<app-icon color="#FFFFFF33" icon="qrCode" size="256" style="position:absolute;"
							  class="animate__animated animate__pulse animate__fast animate__infinite"/>

					<!--Instruction-->
					<app-text color="white" size="large">Scan a QR code</app-text>

				</div>

			</qrcode-stream>

		</div>

		<!--Instructions-->
		<div v-if="!fullQrData?.parkingData?.length" class="d-flex justify-center mt-8">

			<page-start-information title="Getting Started"
									:points="['Scan the attendee\'s QR Code', 'Select a Car Park (if required)', 'Select the Vehicle Type', 'Click Save']"/>

		</div>

		<!--QR Result ---------------------------------------------------------------------------------------------- -->
		<div v-if="fullQrData?.parkingData?.length" class="mt-4">

			<!--More Information -------------------------------- -->
			<v-expansion-panels class="appWhite rounded-lg mt-4" :disabled="!!computedReservedList.length" flat>
				<v-expansion-panel class="transparent">

					<!--Header-->
					<v-expansion-panel-header class="d-flex flex-column pa-4" hide-actions>

						<app-icon v-if="isVehiclesLimitWarningVisible"
								  class="flash mb-4" color="red" icon="error" size="32"/>

						<!--Event Details-->
						<app-text class="text-center" size="normal-bold">
							{{ fullQrData?.eventData?.eventName }}
						</app-text>

						<!--Organisation/Individual's Details-->
						<app-text class="text-center mt-4" color="primary" size="medium-bold">
							{{
								fullQrData?.organisationData?.organisationName
								||
								fullQrData?.parkingData[0]?.parkingSpaceData?.parkingSpaceIndividualName
								||
								computedReservedList.length && 'Reserve List'
							}}
						</app-text>

					</v-expansion-panel-header>

					<!--Content-->
					<v-expansion-panel-content class="expansionPanel">

						<v-divider/>

						<!--Instructional Text-->
						<app-text class="mt-4" color="grey9" size="small">
							Below are the allocated spaces for this attendee, grouped by their Car Park.
						</app-text>

						<!--Spaces-->
						<div v-for="item in fullQrData.parkingData" :key="item.parkingSpaceData?.entityId">

							<!--Car Park-->
							<app-text class="primary text-center rounded-lg mt-4 pa-1" color="white" size="normal">
								{{ item.carParkData?.carParkName }}
							</app-text>

							<!--Regular Spaces ---------------- -->

							<!--Title-->
							<div class="d-flex align-center mt-4">
								<v-divider class="greyD"/>
								<app-text class="mx-4" color="grey9" size="normal-bold">Regular</app-text>
								<v-divider class="greyD"/>
							</div>
							<div class="d-flex justify-space-between mt-2">
								<div v-for="size in ['Small', 'Medium', 'Large']" :key="size"
									 class="d-flex flex-column align-center">

									<!--Icons-->
									<app-icon v-if="size === 'Small'" color="primary" icon="car" size="32"/>
									<app-icon v-if="size === 'Medium'" color="primary" icon="minibus" size="32"/>
									<app-icon v-if="size === 'Large'" color="primary" icon="truck" size="32"/>

									<!--Size-->
									<app-text>{{ size }}</app-text>

									<!--Figures-->
									<div class="d-flex">

										<!--Arrived-->
										<app-text :color="getParkingColors(item.parkingSpaceData, 'Regular', size)"
												  size="normal-bold">
											{{ item.parkingSpaceData?.[`parkingSpaceNumberOfRegular${size}VehiclesArrived`]
											}}
										</app-text>

										<app-text class="mx-1" color="grey9">/</app-text>

										<!--Required-->
										<app-text color="grey9">
											{{ item.parkingSpaceData?.[`parkingSpaceNumberOfRegular${size}VehiclesRequired`]
											}}
										</app-text>

									</div>

								</div>
							</div>

							<!--Disabled Spaces ---------------- -->

							<!--Title-->
							<div class="d-flex align-center mt-4">
								<v-divider class="greyD"/>
								<app-text class="mx-4" color="grey9" size="normal-bold">Accessible</app-text>
								<v-divider class="greyD"/>
							</div>
							<div class="d-flex justify-space-between mt-2">

								<div v-for="size in ['Small', 'Medium', 'Large']"
									 class="d-flex flex-column align-center">

									<!--Icons-->
									<div class="d-flex align-center">
										<app-icon color="blue" icon="disabled" size="24"/>

										<app-icon v-if="size === 'Small'" color="blue" icon="car" size="32"/>
										<app-icon v-if="size === 'Medium'" color="blue" icon="minibus" size="32"/>
										<app-icon v-if="size === 'Large'" color="blue" icon="truck" size="32"/>
									</div>

									<!--Size-->
									<app-text :key="size">{{ size }}</app-text>

									<!--Figures-->
									<div class="d-flex">

										<!--Arrived-->
										<app-text :color="getParkingColors(item.parkingSpaceData, 'Disabled', size)"
												  size="normal-bold">
											{{ item.parkingSpaceData?.[`parkingSpaceNumberOfDisabled${size}VehiclesArrived`]
											}}
										</app-text>

										<app-text class="mx-1" color="grey9">/</app-text>

										<!--Required-->
										<app-text color="grey9">
											{{ item.parkingSpaceData?.[`parkingSpaceNumberOfDisabled${size}VehiclesRequired`]
											}}
										</app-text>

									</div>

								</div>
							</div>

						</div>

					</v-expansion-panel-content>

				</v-expansion-panel>
			</v-expansion-panels>

			<!--Disabled Spaces Message-->
			<div v-if="computedIsAccessibleParkingRequired"
				 class="d-flex align-center justify-center blue rounded-lg mt-4 pa-1">
				<app-icon color="white" icon="disabled" size="16"/>
				<app-text class="mx-2" color="white" size="small">May contain <b>Accessible</b> parking</app-text>
				<app-icon color="white" icon="disabled" size="16"/>
			</div>

			<!--Car Park(s)-->
			<div>

				<!--Title-->
				<page-break-title class="mt-4"
								  description="Select a Car Park (if there's more than one)"
								  title="Car Park"/>

				<!--Car Park Name - If there is only one Car Park-->
				<app-text v-if="fullQrData?.parkingData?.length === 1"
						  class="text-center mt-4" color="primary" size="medium-bold">
					{{ fullQrData?.parkingData[0]?.carParkData?.carParkName }}
				</app-text>

				<!--Car Park Selection - If there are multiple Car Parks-->
				<app-form-field v-if="fullQrData?.parkingData?.length !== 1"
								form-type="autoComplete"
								class="mt-4"
								:clearable="false"
								:error="errors.selectedCarPark"
								:error-message="errors.selectedCarParkErrorMessage"
								:items="fullQrData?.parkingData"
								item-text="carParkData.carParkName"
								:return-object="true"
								v-model="selectedCarPark"/>

			</div>

			<!--Vehicle Type-->
			<div v-if="!computedReservedList.length">

				<!--Title-->
				<page-break-title class="mt-4" description="Enter how many of each vehicle type have arrived"
								  title="Vehicles"/>

				<!--Regular ---------------- -->

				<!--Vehicle Types-->
				<div class="mt-4" style="display: grid; grid-template-columns: repeat(3, 1fr); grid-gap: 16px">

					<!--Small-->
					<div class="d-flex flex-column align-center">
						<app-icon color="primary" icon="car" size="32"/>
						<app-text>Small</app-text>
						<app-form-field form-type="textInput"
										class="mt-2"
										:error="errors.parkingSpaceNumberOfRegularSmallVehiclesArrived"
										:error-message="errors.parkingSpaceNumberOfRegularSmallVehiclesArrivedErrorMessage"
										type="number"
										v-model.number.trim="form.parkingSpaceNumberOfRegularSmallVehiclesArrived"/>
					</div>

					<!--Medium-->
					<div class="d-flex flex-column align-center">
						<app-icon color="primary" icon="minibus" size="32"/>
						<app-text>Medium</app-text>
						<app-form-field form-type="textInput"
										class="mt-2"
										:error="errors.parkingSpaceNumberOfRegularMediumVehiclesArrived"
										:error-message="errors.parkingSpaceNumberOfRegularMediumVehiclesArrivedErrorMessage"
										type="number"
										v-model.number.trim="form.parkingSpaceNumberOfRegularMediumVehiclesArrived"/>
					</div>

					<!--Large-->
					<div class="d-flex flex-column align-center">
						<app-icon color="primary" icon="truck" size="32"/>
						<app-text>Large</app-text>
						<app-form-field form-type="textInput"
										class="mt-2"
										:error="errors.parkingSpaceNumberOfRegularLargeVehiclesArrived"
										:error-message="errors.parkingSpaceNumberOfRegularLargeVehiclesArrivedErrorMessage"
										type="number"
										v-model.number.trim="form.parkingSpaceNumberOfRegularLargeVehiclesArrived"/>
					</div>

				</div>

				<!--Disabled ---------------- -->

				<!--Vehicle Types-->
				<div class="mt-4" style="display: grid; grid-template-columns: repeat(3, 1fr); grid-gap: 16px">

					<!--Small-->
					<div class="d-flex flex-column align-center">
						<div class="d-flex align-center">
							<app-icon color="blue" icon="disabled" size="24"/>
							<app-icon color="blue" icon="car" size="32"/>
						</div>
						<app-text>Small</app-text>
						<app-form-field form-type="textInput"
										class="mt-2"
										:error="errors.parkingSpaceNumberOfDisabledSmallVehiclesArrived"
										:error-message="errors.parkingSpaceNumberOfDisabledSmallVehiclesArrivedErrorMessage"
										type="number"
										v-model.number.trim="form.parkingSpaceNumberOfDisabledSmallVehiclesArrived"/>
					</div>

					<!--Medium-->
					<div class="d-flex flex-column align-center">
						<div class="d-flex align-center">
							<app-icon color="blue" icon="disabled" size="24"/>
							<app-icon color="blue" icon="minibus" size="32"/>
						</div>
						<app-text>Medium</app-text>
						<app-form-field form-type="textInput"
										class="mt-2"
										:error="errors.parkingSpaceNumberOfDisabledMediumVehiclesArrived"
										:error-message="errors.parkingSpaceNumberOfDisabledMediumVehiclesArrivedErrorMessage"
										type="number"
										v-model.number.trim="form.parkingSpaceNumberOfDisabledMediumVehiclesArrived"/>
					</div>

					<!--Large-->
					<div class="d-flex flex-column align-center">
						<div class="d-flex align-center">
							<app-icon color="blue" icon="disabled" size="24"/>
							<app-icon color="blue" icon="truck" size="32"/>
						</div>
						<app-text>Large</app-text>
						<app-form-field form-type="textInput"
										class="mt-2"
										:error="errors.parkingSpaceNumberOfDisabledLargeVehiclesArrived"
										:error-message="errors.parkingSpaceNumberOfDisabledLargeVehiclesArrivedErrorMessage"
										type="number"
										v-model.number.trim="form.parkingSpaceNumberOfDisabledLargeVehiclesArrived"/>
					</div>

				</div>

				<!--No Selection Error Message-->
				<app-text v-if="errors.noVehiclesError" class="text-center mt-4" color="red" size="small">
					{{ errors.noVehiclesErrorMessage }}
				</app-text>

			</div>

			<v-divider class="mt-4"/>

			<!--Save Button-->
			<div class="d-flex justify-space-between mt-4">
				<app-btn @click.native="resetForm"
						 :block="true"
						 class="mr-2"
						 color="grey"
						 icon="cancel"
						 label="Cancel"/>
				<app-btn @click.native="saveItem"
						 :block="true"
						 class="ml-2"
						 color="green"
						 icon="save"
						 label="Save"
						 style="width: 100%"/>
			</div>

		</div>

	</div>
</template>

<script>
import {QrcodeStream} from 'vue-qrcode-reader'

export default {

	name: "ParkingSteward",

	components: {QrcodeStream},

	data: () => ({
		errors: {
			selectedCarPark: false,
			selectedCarParkErrorMessage: '',
			noVehiclesError: false,
			noVehiclesErrorMessage: '',
			parkingSpaceNumberOfRegularSmallVehiclesArrived: false,
			parkingSpaceNumberOfRegularSmallVehiclesArrivedErrorMessage: '',
			parkingSpaceNumberOfRegularMediumVehiclesArrived: false,
			parkingSpaceNumberOfRegularMediumVehiclesArrivedErrorMessage: '',
			parkingSpaceNumberOfRegularLargeVehiclesArrived: false,
			parkingSpaceNumberOfRegularLargeVehiclesArrivedErrorMessage: '',
			parkingSpaceNumberOfDisabledSmallVehiclesArrived: false,
			parkingSpaceNumberOfDisabledSmallVehiclesArrivedErrorMessage: '',
			parkingSpaceNumberOfDisabledMediumVehiclesArrived: false,
			parkingSpaceNumberOfDisabledMediumVehiclesArrivedErrorMessage: '',
			parkingSpaceNumberOfDisabledLargeVehiclesArrived: false,
			parkingSpaceNumberOfDisabledLargeVehiclesArrivedErrorMessage: '',
		},
		form: {
			parkingSpaceNumberOfDisabledSmallVehiclesArrived: 0,
			parkingSpaceNumberOfDisabledMediumVehiclesArrived: 0,
			parkingSpaceNumberOfDisabledLargeVehiclesArrived: 0,
			parkingSpaceNumberOfRegularSmallVehiclesArrived: 1,
			parkingSpaceNumberOfRegularMediumVehiclesArrived: 0,
			parkingSpaceNumberOfRegularLargeVehiclesArrived: 0,
		},
		camera: 'rear',
		error: '',
		fullQrData: [],
		isLoading: false,
		isVehiclesLimitWarningVisible: false,
		noFrontCamera: false,
		noRearCamera: false,
		selectedCarPark: {},
	}),

	computed: {

		/**
		 * Is Accessible Parking Required
		 *
		 * Check if any of the Parking Spaces require accessible parking.
		 * @returns {boolean} - True if any of the Parking Spaces require accessible parking.
		 */
		computedIsAccessibleParkingRequired() {
			const t = this
			const PARKING_DATA = t.fullQrData?.parkingData
			let isAccessibleParkingRequired = false

			// Loop through the parkingData and check if any of the Parking Spaces require accessible parking
			PARKING_DATA.forEach(item => {
				if ([
					item.parkingSpaceData?.parkingSpaceNumberOfDisabledSmallVehiclesRequired,
					item.parkingSpaceData?.parkingSpaceNumberOfDisabledMediumVehiclesRequired,
					item.parkingSpaceData?.parkingSpaceNumberOfDisabledLargeVehiclesRequired
				].some(value => value > 0)) isAccessibleParkingRequired = true
			})

			return isAccessibleParkingRequired
		},

		/**
		 * Reserved List
		 *
		 * Parse and return the Parking Space Reserved List from the QR Code.
		 * If there is no Reserved List, return an empty array.
		 *
		 * @returns {array} - The Parking Space Reserved List
		 */
		computedReservedList() {
			const t = this
			let reservedList

			// If there is no reserved list, return an empty array
			if (!t.fullQrData?.parkingData[0]?.parkingSpaceData.parkingSpaceReservedList) reservedList = []

			// If there is a reserved list, parse it and return it
			if (t.fullQrData?.parkingData[0]?.parkingSpaceData.parkingSpaceReservedList) {
				reservedList = typeof t.fullQrData?.parkingData[0]?.parkingSpaceData.parkingSpaceReservedList === 'string'
					? JSON.parse(t.fullQrData?.parkingData[0]?.parkingSpaceData.parkingSpaceReservedList)
					: t.fullQrData?.parkingData[0]?.parkingSpaceData.parkingSpaceReservedList
			}

			return reservedList
		}

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		getParkingColors(item, type, size) {
			const t = this

			let vehiclesRequired = item[`parkingSpaceNumberOf${type}${size}VehiclesRequired`]
			let vehiclesArrived = item[`parkingSpaceNumberOf${type}${size}VehiclesArrived`]
			let color

			if (vehiclesArrived === 0 || vehiclesArrived < vehiclesRequired) color = 'green'
			else if (vehiclesArrived === vehiclesRequired) {
				color = 'orange'
				t.isVehiclesLimitWarningVisible = true
			} else if (vehiclesArrived > vehiclesRequired) {
				color = 'red'
				t.isVehiclesLimitWarningVisible = true
			}

			return color
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([])

			t.isLoading = false
		},

		/**
		 * On Decode
		 *
		 * When the read has decoded the QR code (getting the location's ID),
		 * get the current user's data and check their swappStatus to know whether to swapp them in or out.
		 *
		 * @returns {Promise<void>}
		 * @param qrData - The decoded QR code data
		 */
		async onDecode(qrData) {
			const t = this
			let parsedQrData

			try {
				parsedQrData = JSON.parse(qrData)
			} catch (error) {
				console.error('Error parsing QR code data: ', error)
				t.$sharedState.errorMessage = 'There was a problem reading the QR code, please try again.'
				t.resetCamera()
				return
			}

			const RESPONSE = await t.MIX_redis_getParkingQrCodeData(parsedQrData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Parking QR Code Data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem getting the Parking Data, please try again.'
				return
			}

			t.fullQrData = RESPONSE.data
		},

		/**
		 * On Init
		 *
		 * Initialise the QR code reader by checking for loading and camera errors.
		 * Any errors will stop the camera from loading.
		 *
		 * @param promise
		 * @returns {Promise<void>}
		 */
		async onInit(promise) {
			const t = this
			t.isLoading = true

			// Initialisation Errors
			try {
				await promise
			} catch (error) {
				if (error.name === 'NotAllowedError') t.error = "ERROR: you need to grant camera access permission"
				else if (error.name === 'NotFoundError') t.error = "ERROR: no camera on this device"
				else if (error.name === 'NotSupportedError') t.error = "ERROR: secure context required (HTTPS, localhost)"
				else if (error.name === 'NotReadableError') t.error = "ERROR: is the camera already in use?"
				else if (error.name === 'OverconstrainedError') t.error = "ERROR: installed cameras are not suitable"
				else if (error.name === 'StreamApiNotSupportedError') t.error = "ERROR: Stream API is not supported in this browser"
				else if (error.name === 'InsecureContextError') t.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
				else t.error = `ERROR: Camera error (${error.name})`
			}

			// Camera Errors
			try {
				await promise
			} catch (error) {
				const triedFrontCamera = t.camera === 'front'
				const triedRearCamera = t.camera === 'rear'

				const cameraMissingError = error.name === 'OverconstrainedError'

				if (triedRearCamera && cameraMissingError) t.noRearCamera = true

				if (triedFrontCamera && cameraMissingError) t.noFrontCamera = true

				console.error(error)
			}

			t.isLoading = false
		},

		/**
		 * Paint Outline
		 *
		 * Track the QR code with a colored outline when it has been found
		 *
		 * @param detectedCodes
		 * @param ctx
		 */
		paintOutline(detectedCodes, ctx) {
			for (const detectedCode of detectedCodes) {
				const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

				ctx.strokeStyle = "red"

				ctx.beginPath();
				ctx.moveTo(firstPoint.x, firstPoint.y)
				for (const {x, y} of otherPoints) {
					ctx.lineTo(x, y);
				}
				ctx.lineTo(firstPoint.x, firstPoint.y)
				ctx.closePath()
				ctx.stroke()
			}
		},

		/**
		 * Reset Camera
		 *
		 * Reset the camera so it can be used again.
		 */
		resetCamera() {
			const t = this

			t.camera = 'off'

			setTimeout(() => {
				t.camera = 'auto'
			}, 1000)
		},

		/**
		 * Reset Form
		 *
		 * Reset the form to its default state.
		 */
		resetForm() {
			const t = this

			t.selectedCarPark = {}
			t.fullQrData = {}
			t.isVehiclesLimitWarningVisible = false
			t.form.parkingSpaceNumberOfRegularSmallVehiclesArrived = 0
			t.form.parkingSpaceNumberOfRegularMediumVehiclesArrived = 0
			t.form.parkingSpaceNumberOfRegularLargeVehiclesArrived = 0
			t.form.parkingSpaceNumberOfDisabledSmallVehiclesArrived = 0
			t.form.parkingSpaceNumberOfDisabledMediumVehiclesArrived = 0
			t.form.parkingSpaceNumberOfDisabledLargeVehiclesArrived = 0

			// Reset Qr Code Reader
			t.resetCamera()
		},

		/**
		 * Save Item
		 *
		 * Save the updated Parking Data to the database.
		 *
		 * @returns {Promise<void>}
		 */
		async saveItem() {
			const t = this
			const FULL_QR_DATA = t.fullQrData

			// Only continue if the form has passed validation
			if (!t.validateForm()) return

			// If there is only one Car Park, set it as the selected car park, otherwise get the selected parking space
			const selectedParkingSpace = FULL_QR_DATA.parkingData.length === 1
				? t.selectedCarPark = FULL_QR_DATA.parkingData[0].parkingSpaceData
				: t.selectedCarPark.parkingSpaceData

			// Update with the Arrived parking data
			selectedParkingSpace.parkingSpaceNumberOfRegularSmallVehiclesArrived = t.form.parkingSpaceNumberOfRegularSmallVehiclesArrived
			selectedParkingSpace.parkingSpaceNumberOfRegularMediumVehiclesArrived = t.form.parkingSpaceNumberOfRegularMediumVehiclesArrived
			selectedParkingSpace.parkingSpaceNumberOfRegularLargeVehiclesArrived = t.form.parkingSpaceNumberOfRegularLargeVehiclesArrived
			selectedParkingSpace.parkingSpaceNumberOfDisabledSmallVehiclesArrived = t.form.parkingSpaceNumberOfDisabledSmallVehiclesArrived
			selectedParkingSpace.parkingSpaceNumberOfDisabledMediumVehiclesArrived = t.form.parkingSpaceNumberOfDisabledMediumVehiclesArrived
			selectedParkingSpace.parkingSpaceNumberOfDisabledLargeVehiclesArrived = t.form.parkingSpaceNumberOfDisabledLargeVehiclesArrived

			const RESPONSE = await t.MIX_redis_updateParkingSpaceArrivalNumbers(selectedParkingSpace.entityId, selectedParkingSpace)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating Parking Space: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem updating the Parking Space, please try again.'
				return
			}

			// Reset the form
			t.resetForm()

			t.$sharedState.successMessage = 'Updated Parking'
		},

		/**
		 * Switch Camera
		 *
		 * Switch the device's cameras between Front, Rear, and Default.
		 *
		 * @param camera the selection from the switcher buttons for which camera to use
		 */
		switchCamera(camera) {
			const t = this

			// Reset Errors
			t.noRearCamera = false
			t.noFrontCamera = false
			t.error = ''

			switch (camera) {
				case 'front':
					t.camera = 'front'
					break
				case 'rear':
					t.camera = 'rear'
					break
				default:
					t.camera = ''
			}

		},

		/**
		 * Validate Form
		 *
		 * Validate the form and return true or false if it is valid, or not.
		 *
		 * @returns {boolean}
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Car Park
			if (t.fullQrData.parkingData.length > 1 && !t.selectedCarPark?.parkingSpaceData?.entityId) {
				t.errors.selectedCarPark = true
				t.errors.selectedCarParkErrorMessage = 'Please select a Car Park'
			}

			// Regular Vehicles Validation
			t.validateVehicleNumber("RegularSmall")
			t.validateVehicleNumber("RegularMedium")
			t.validateVehicleNumber("RegularLarge")

			// Disabled Vehicles Validation
			t.validateVehicleNumber("DisabledSmall")
			t.validateVehicleNumber("DisabledMedium")
			t.validateVehicleNumber("DisabledLarge")

			// If there are no regular or disabled small, medium, or large vehicles
			if (!t.fullQrData?.parkingData[0]?.parkingSpaceData?.parkingSpaceReservedList) {
				if (![t.form.parkingSpaceNumberOfRegularSmallVehiclesArrived,
					t.form.parkingSpaceNumberOfRegularMediumVehiclesArrived,
					t.form.parkingSpaceNumberOfRegularLargeVehiclesArrived,
					t.form.parkingSpaceNumberOfDisabledSmallVehiclesArrived,
					t.form.parkingSpaceNumberOfDisabledMediumVehiclesArrived,
					t.form.parkingSpaceNumberOfDisabledLargeVehiclesArrived
				].some(value => value > 0)
				) {
					t.errors.noVehiclesError = true
					t.errors.noVehiclesErrorMessage = "At least one vehicle is required"
				}
			}

			return !Object.values(t.errors).includes(true)
		},

		/**
		 * Validate Vehicle Number
		 *
		 * Validate the number of vehicles required.
		 * Must be either 0, or a positive integer.
		 *
		 * @param vehicleType the type of vehicle to validate
		 */
		validateVehicleNumber(vehicleType) {
			const t = this
			const FORM_FIELD = `parkingSpaceNumberOf${vehicleType}VehiclesArrived`
			const FIELD_VALUE = t.form[FORM_FIELD]
			const REGEX = /^\d+$/

			if (!REGEX.test(FIELD_VALUE)) {
				t.errors[FORM_FIELD] = true
				t.errors[`${FORM_FIELD}ErrorMessage`] = "Must be 0 or a positive number"
			}
		}

	},

	created() {
		const t = this

		// Set the camera to the default camera in development, so it works with webcams
		if (process.env.NODE_ENV === 'development') t.camera = ''

		t.loadData()
	},

}
</script>

<style scoped>
.qrReader-cameraButtons-container {
	display: flex;
	justify-content: flex-end;

	position: absolute;
	top: 16px;
	right: 0;
	z-index: 9;
}

.qrcode-stream-wrapper >>> video {
	border-radius: 16px;
	margin-top: 16px;
}

.expansionPanel >>> .v-expansion-panel-content__wrap {
	padding: 0 16px 16px;
}
</style>
