<template>
	<div class="appGrey pa-4">

		<!--Instructional Text-->
        <div class="text-center">
            <app-text>
                You can’t assign this list to a user that differs to it's existing tasks' assignees.
                <br>
			    <br>
			    Would you like to assign all tasks to this user instead?
		    </app-text>
        </div>


		<v-divider class="mt-4"/>

		<!--Action Buttons-->
		<div class="d-flex flex-column justify-space-between align-center mt-4">
			<!--Accept-->
			<app-btn @click.native="handleUpdateAllAssignees"
					 color="primary"
					 label="Assign all existing tasks to this user"/>
					 
			<!--Cancel-->
			<app-btn @click.native="closeDialog"
					 icon="cancel"
					 label="Cancel & don't assign the list to a user"/>
		</div>
	</div>
</template>

<script>
export default {

	name: "ToDoListAssignAssigneesDialog",

	methods: {

		/**
		 * Close Dialog
		 *
		 * Emit a message back to the parent component to close the dialog.
		 */
         closeDialog() {
			const t = this

			t.$emit('emitCloseDialog')
		},

		/**
		 * Handle Update All Task Assignees
		 *
		 * Emit a message back to the parent component to update all tasks with the new list assignee.
		 */
		 handleUpdateAllAssignees() {
			const t = this

		 	t.$emit('emitUpdateAllTaskAssignees')
		}

	},

}
</script>



<style scoped>

</style>