<template>
	<div class="appWhite rounded-lg pa-4">

		<app-text color="primary" size="normal-bold">{{ cardData.eventData.eventName }}</app-text>

		<app-text color="grey9" size="small">{{ cardData.eventData.eventType }}</app-text>

		<v-divider class="my-2" aria-colcount="greyD"/>

		<app-text color="grey9" size="small">{{ MIX_formatDate(cardData.eventData.eventDate, 'long') }}</app-text>

	</div>
</template>

<script>

export default {

	name: "MyAccreditationEventMobileCard",

	props: ['cardData'],

	data: () => ({}),

	methods: {},

}
</script>

<style scoped>

</style>
