<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<!--Action Bar-->
		<div class="d-flex align-center mt-4">

			<!--Search-->
			<app-form-field form-type="textInput"
							append-icon="icons8-search"
							class="mr-4"
							:clearable="true"
							label="Pay Grade"
							style="width: 100%"
							v-model.trim="searchByPayGrade"/>

			<!--Add Button-->
			<app-btn @click.native="openRightPanel" icon="add" label="Add"/>

			<!--Filter Button - with a numbered badge-->
			<!--<div style="position:relative;">-->
			<!--	<app-btn @click.native="toggleFiltersVisibility"-->
			<!--			 class="ml-4" color="appWhite" icon="filter" icon-color="primary"/>-->
			<!--	<span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>-->
			<!--</div>-->

			<!--More Actions Menu-->
			<!--<div class="ml-4">-->
			<!--	<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>-->
			<!--</div>-->

		</div>

		<!--Clear Filters-->
		<div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
			<app-btn @click.native="clearFilters" icon="close" label="Clear Filters"/>
		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="appWhite rounded-lg mt-4"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.id="{item}">
				<app-text size="small">{{ item.id }}</app-text>
			</template>

			<!--Pay Grade-->
			<template v-slot:item.payRatePayGrade="{item}">
				<app-text size="small">{{ item.payRatePayGrade }}</app-text>
			</template>

			<!--Hourly Rate-->
			<template v-slot:item.payRateHourlyRate="{item}">
				<app-text v-if="item.payRateHourlyRate === '0'" color="grey9" size="small">None</app-text>
				<app-text v-else size="small">£{{ item.payRateHourlyRate }}</app-text>
			</template>

			<!--Holiday Rate-->
			<template v-slot:item.payRateHolidayRate="{item}">
				<app-text v-if="item.payRateHolidayRate === '0'" color="greyD" size="small">None</app-text>
				<app-text v-else size="small">£{{ item.payRateHolidayRate }}</app-text>
			</template>

			<!--Allowance Rate-->
			<template v-slot:item.payRateAllowanceRate="{item}">
				<app-text v-if="item.payRateAllowanceRate === '0'" color="greyD" size="small">None</app-text>
				<div v-else class="d-flex justify-end">
					<app-text size="small">£{{ item.payRateAllowanceRate }}</app-text>
					<app-text class="mx-2" size="small">every</app-text>
					<app-text size="small">{{ item.payRateAllowanceDuration }}</app-text>
					<app-text class="ml-2" size="small">hours</app-text>
				</div>
			</template>

			<!--Shift Fee-->
			<template v-slot:item.payRateFee="{item}">
				<app-text v-if="item.payRateFee === '0'" color="greyD" size="small">None</app-text>
				<app-text v-else size="small">£{{ item.payRateFee }}</app-text>
			</template>

			<!--Action Button-->
			<template v-slot:item.action="{item}">
				<app-icon @click.native="openRightPanel(item)"
						  class="cursorPointer" color="primary" icon="arrowForward"/>
			</template>

		</v-data-table>

		<!--Filters Panel ------------------------------------------------------------------------------------------ -->
		<filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
			<div class="pa-4">


			</div>
		</filter-panel>

		<!--Right Side ----------------------------------------------------------------------------------- Right Side-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">

			<div v-if="isRightPanelVisible"
				 class="appGrey formShadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'">

				<!--Header-->
				<div class="d-flex align-center primary pa-4">
					<app-text color="appWhite" size="normal">Pay Rates</app-text>
					<v-spacer/>
					<app-icon @click.native="closeRightPanel"
							  class="cursorPointer" color="white" icon="close" size="32"/>
				</div>

				<!--Action Bar-->
				<div class="d-flex align-center pa-4">

					<v-spacer/>

					<edit-icon @click.native="editItem" :is-active="!isReadOnly"/>

				</div>

				<!--Tabs-->
				<v-tabs v-model="tabs">

					<!--Pay Rates-->
					<!--<v-tab href="#payRates">-->
					<!--	<app-text size="small">Pay Rates</app-text>-->
					<!--</v-tab>-->

					<!--Pay Rates Form-->
					<v-tab href="#payRatesForm">
						<app-text size="small">Rates Form</app-text>
					</v-tab>

				</v-tabs>

				<!--Tabs Content-->
				<v-tabs-items v-model="tabs">

					<!--Pay Rates-->
					<!--<v-tab-item value="payRates">-->
					<!--	<payroll-pay-rates :is-read-only="isReadOnly"/>-->
					<!--</v-tab-item>-->

					<!--Pay Rates Form-->
					<v-tab-item value="payRatesForm">
						<payroll-pay-rates-form @refreshData="refreshData"
												:form-data="selectedItem"/>
					</v-tab-item>

				</v-tabs-items>

			</div>

		</transition>

	</div>

</template>

<script>

import PayrollPayRatesForm from "@/views/payroll/payRates/payRatesForm/PayrollPayRatesForm.vue";

export default {

	name: "PayRates",

	components: {PayrollPayRatesForm},

	data: () => ({
		isFiltersPanelVisible: false,
		isLoading: false,
		isReadOnly: false,
		isRightPanelVisible: false,
		moreActionsMenuOption: [
			{name: 'Pay Rates', icon: ''},

			{name: 'Export', icon: 'export'}
		],
		searchByPayGrade: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'id', align: 'start', sortable: false, hidden: true},
			{text: 'Pay Grade', value: 'payRatePayGrade', align: 'start', sortable: false},
			{text: 'Base Rate (hr)', value: 'payRateHourlyRate', align: 'end', sortable: false},
			{text: 'Holiday Rate (hr)', value: 'payRateHolidayRate', align: 'end', sortable: false},
			{text: 'Allowance', value: 'payRateAllowanceRate', align: 'end', sortable: false},
			{text: 'Shift Fee', value: 'payRateFee', align: 'end', sortable: false},
			{text: '', value: 'action', align: 'center', sortable: false, width: '48px'},
		],
		tabs: '',

		// Data
		payRatesData: [],
	}),

	computed: {

		/**
		 * Computed Export CSV
		 *
		 * Return the data and headers for the CSV export
		 *
		 * @returns {{headers: {}, data: *[]}}
		 */
		computedExportCSV() {
			const t = this
			let data = []
			let headers = {}

			// Add the readable headers for the CSV columns
			headers = {
				entityId: 'ID',
				siteName: 'Site Name',
				siteTelephone: 'Site Telephone',
				siteAddressLine1: 'Site Address Line 1',
				siteAddressLine2: 'Site Address Line 2',
				siteAddressTown: 'Site Address Town',
				siteAddressCity: 'Site Address City',
				siteAddressCounty: 'Site Address County',
				siteAddressPostcode: 'Site Address Postcode',
				// siteAddressCoords: 'Site Address Coords',
				siteContact: 'Site Contact',
				siteNotes: 'Site Notes',
			}

			// Add the data
			t.computedTableData.forEach(entry => {

				const DATA_OBJECT = {
					entityId: entry?.siteData?.entityId,
					siteName: entry?.siteData?.siteName,
					siteTelephone: entry?.siteData?.siteTelephone,
					siteAddressLine1: entry?.siteData?.siteAddressLine1,
					siteAddressLine2: entry?.siteData?.siteAddressLine2,
					siteAddressTown: entry?.siteData?.siteAddressTown,
					siteAddressCity: entry?.siteData?.siteAddressCity,
					siteAddressCounty: entry?.siteData?.siteAddressCounty,
					siteAddressPostcode: entry?.siteData?.siteAddressPostcode,
					// siteAddressCoords: `${entry?.siteData?.siteAddressCoords?.latitude}, ${entry?.siteData?.siteAddressCoords?.longitude}`,
					siteContact: t.usersData.find(u => u.entityId === entry.siteData.siteContact)?.userName,
					siteNotes: entry?.siteData?.siteNotes,
				}

				data.push(DATA_OBJECT)
			})

			return {headers, data}
		},

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter(h => !h.hidden)

			return headers
		},

		/**
		 * Computed Number of Active Filters
		 *
		 * Count the number of active filters to display in the filters button badge.
		 *
		 * @returns {*}
		 */
		computedNumberOfActiveFilters() {
			const t = this
			return [].reduce((acc, curr) => acc + curr, 0)
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the table data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			let tableData = t.payRatesData

			// Search by Pay Grade
			if (t.searchByPayGrade) {
				const SEARCH_CRITERIA = t.searchByPayGrade.toUpperCase()
				tableData = tableData.filter(d => d.payRatePayGrade.toUpperCase().includes(SEARCH_CRITERIA))
			}

			// Sort by Pay Grade
			tableData = tableData.sort((a, b) => a.payRatePayGrade < b.payRatePayGrade ? -1 : 1)

			return tableData
		},

	},

	methods: {

		/**
		 * Clear Filters
		 *
		 * Clear all the filters.
		 */
		clearFilters() {
			const t = this

			t.filterByArea = ''
			t.filterByDeputy = ''
			t.filterByQuotaLessThan = null
			t.filterByQuotaMoreThan = null
			t.filterBySteward = ''
			t.filterBySupervisor = ''
		},

		/**
		 * Close Right Panel
		 *
		 * Close the right panel.
		 */
		closeRightPanel() {
			const t = this

			t.tabs = ''
			t.selectedItem = {}
			t.isRightPanelVisible = false
		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Export Data
		 *
		 * Export the data to CSV.
		 */
		exportData() {
			const t = this

			t.MIX_exportDocuments(t.computedExportCSV.headers, 'Payroll', t.computedExportCSV.data)
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'Export') t.exportData()
		},

		/**
		 * Load Data
		 *
		 * Load the data.
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadPayRatesData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Pay Rates Data
		 *
		 * Load the Pay Rates data.
		 */
		async loadPayRatesData() {
			const t = this

			// const RESPONSE = await t.MIX_redis_getAll('payRate')
			const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
				'PayRate',
				[],
				[
					{field: 'payRatePayGrade'},
					{field: 'payRateHourlyRate'},
					{field: 'payRateHolidayRate'},
					{field: 'payRateAllowanceRate'},
					{field: 'payRateAllowanceDuration'},
					{field: 'payRateFee'},
				]
			)

			// Handle any errors
			if (RESPONSE?.hasErrors) {
				console.error('Error loading Pay Rates data: ', RESPONSE?.errors)
				t.$sharedState.errorMessage = 'There was an error loading the Pay Rates data, please try again.'
				return
			}

			t.payRatesData = RESPONSE?.data
		},

		/**
		 * Open Form
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		openRightPanel(item) {
			const t = this

			// New
			if (!item?.entityId) {
				t.isReadOnly = false
				t.selectedItem = {}
			}

			// Existing
			else {
				t.isReadOnly = true
				t.selectedItem = item
			}

			t.isRightPanelVisible = true
		},

		/**
		 * Refresh Data
		 *
		 * Refresh the data.
		 */
		refreshData() {
			const t = this

			t.loadData()
			t.closeRightPanel()
		},

		/**
		 * Toggle Filters Visibility
		 *
		 * Toggle the visibility of the filters panel.
		 */
		toggleFiltersVisibility() {
			const t = this

			t.isFiltersPanelVisible = !t.isFiltersPanelVisible
		},

	},

	async mounted() {
		const t = this

		t.loadData()
	}

}
</script>

<style scoped>

</style>
