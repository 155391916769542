<template>
	<div class="appGrey pa-4">

		<!--Instructional Text-->
		<app-text class="mb-4">
			By clicking Publish, you will change this to a <span class="font-weight-bold">live</span> event.
			<br>
			<br>
			Email notifications will be sent out to all team members that have been added to this event,
			and it will be viewable for all the included team members to select their availability for.
			<br>
			<br>
			<span class="font-weight-bold">Do you want to publish this event?</span>
		</app-text>

		<v-divider/>

		<!--Action Buttons-->
		<div class="d-flex justify-space-between mt-4">

			<!--Cancel-->
			<app-btn @click.native="closeDialog"
					 color="grey"
					 icon="cancel"
					 label="Cancel"/>

			<!--Accept-->
			<app-btn @click.native="handleSubmitEvent"
					 color="green"
					 icon="success"
					 label="Yes"/>

		</div>

	</div>
</template>

<script>
export default {

	name: "EventPublishingDialog",

	methods: {

		/**
		 * Close Dialog
		 *
		 * Emit a message back to the parent component to close the dialog.
		 */
		closeDialog() {
			this.$emit('emitCloseEventPublishingDialog')
		},

		/**
		 * Handle Submit Event
		 *
		 * Emit a message back to the parent component to submit the Event.
		 */
		handleSubmitEvent() {
			const t = this

			t.$emit('emitPublishEvent')
		},

	},

}
</script>

<style scoped>

</style>
