<template>
	<v-app id="app">

		<!--Global Error Message-->
		<error-message v-if="sharedState.errorMessage"
					   :errorMessage="sharedState.errorMessage"/>

		<!--Global Success Message-->
		<success-message v-if="sharedState.successMessage"
						 :successMessage="sharedState.successMessage"/>

		<!--App Background image-->
		<div class="d-flex align-center justify-center"
			 style="position:fixed; top: 64px; bottom: 32px; left: 32px; right: 32px; opacity: 0.025; filter: blur(2px) grayscale(100%)">
			<v-img :src="require('@/assets/images/background-logo.svg')"
				   aspect-ratio="1/1" width="100%" max-width="1080"/>
		</div>

		<!--Top App Bar-->
		<app-bar v-if="!['SignIn', 'Register', 'ResetPassword', 'Unauthorised'].includes($route.name)"
				 v-on:emitOpenNavigationDrawer="isNavigationOpen = true"
				 :fullUserData="fullUserData"/>

		<!--Vertical Navigation-->
		<v-navigation-drawer v-if="!['SignIn', 'Register', 'ResetPassword', 'Unauthorised'].includes($route.name)"
							 app
							 clipped
							 :expand-on-hover="$vuetify.breakpoint.width >= 600"
							 :mini-variant="$vuetify.breakpoint.width >= 600"
							 :permanent="$vuetify.breakpoint.width >= 600"
							 v-model="isNavigationOpen"
							 width="304">

			<navigation-drawer-items/>

		</v-navigation-drawer>

		<!--Main View-->
		<v-main class="appGrey"
				:class="$vuetify.breakpoint.width < 600 && !['SignIn', 'Register', 'ResetPassword', 'Unauthorised'].includes($route.name) && 'mt-16 pa-0'">
			<transition name="custom-classes-transition"
						enter-active-class="animate__animated animate__fadeIn animate__faster"
						leave-active-class="animate__animated animate__fadeOut animate__faster"
						mode="out-in">
				<router-view class="pa-4"/>
			</transition>
		</v-main>

		<app-footer/>

		<!--App Update Banner-->
		<app-update-banner/>

	</v-app>
</template>

<script>
import AppBar from "@/components/app/AppBar";
import AppFooter from "@/components/app/AppFooter.vue";
import AppTopNavigation from "@/components/navigation/AppTopNavigation.vue";
import AppUpdateBanner from "@/components/AppUpdateBanner.vue";
import NavigationDrawerItems from "@/components/navigation/NavigationDrawerItems.vue";
import sharedState from "@/sharedState";
import SuccessMessage from "@/components/SuccessMessage.vue";

export default {

	name: 'App',

	components: {
		AppBar,
		AppFooter,
		AppTopNavigation,
		AppUpdateBanner,
		NavigationDrawerItems,
		SuccessMessage
	},

	data: () => ({
		fullUserData: {},
		isNavigationOpen: false,
		sharedState,
	}),

	methods: {

		async loadData() {
			const t = this

			await Promise.all([
				t.loadCurrentUserData()
			])
		},

		async loadCurrentUserData() {
			const t = this

			if (!t.currentUserAuth) return

			const RESPONSE = await t.MIX_redis_getFullUserData(t.MIX_getCurrentUser().userFbId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error fetching user data: ', RESPONSE.errors)
				t.sharedState.errorMessage = 'There was a problem fetching your data, please try again.'
				return
			}

			t.fullUserData = RESPONSE.data

			t.MIX_addToLocalStorage('currentUserData', RESPONSE.data?.userData)
		},

	},

	created() {
		const t = this

		console.log(`App Version ${t.appVersion}`)

		t.loadData()
	},

};
</script>

<style>
#app {
	font-family: Verdana, sans-serif;
}

.cursorPointer {
	cursor: pointer;
}

.cursorNotAllowed {
	cursor: not-allowed;
}

.formShadow {
	box-shadow: 0 8px 16px var(--v-grey3-base);
}

.flash {
	animation: flash 2s infinite;
}

.pulse {
	animation: pulse 2s infinite;
}

.pulseBig {
	animation: pulseBig 2s infinite;
}

@keyframes pulseBig {
	0% {
	}
	50% {
		transform: scale(1.2);
	}
	100% {
	}
}

.badge {
	display: flex;
	align-items: center;
	justify-content: center;

	background-color: var(--v-green-base);
	border-radius: 50%;
	position: absolute;
	top: -8px;
	right: 8px;
	height: 32px;
	width: 32px;
}

.v-navigation-drawer__content {
	min-width: 72px;
}

/* * * * Rich Text Editor Styles * * * */
.richText-disabled .ql-toolbar {
	border: 1px solid var(--v-greyD-base) !important;
	border-radius: 8px 8px 0 0;
	width: 100%;
}

.richText-enabled .ql-toolbar {
	border: 1px solid var(--v-greyD-base) !important;
	border-radius: 8px 8px 0 0;
	width: 100%;
}

.richText-error .ql-toolbar {
	border: 1px solid var(--v-red-base) !important;
	border-radius: 8px 8px 0 0;
	width: 100%;
}

.richText-disabled .ql-container {
	border: 1px solid var(--v-greyD-base) !important;
	border-radius: 0 0 8px 8px;
	width: 100%;
}

.richText-enabled .ql-container {
	border: 1px solid var(--v-greyD-base) !important;
	border-radius: 0 0 8px 8px;
	width: 100%;
}

.richText-error .ql-container {
	border: 1px solid var(--v-red-base) !important;
	border-radius: 0 0 8px 8px;
	width: 100%;
}

</style>
