<template>
	<div class="appWhite rounded-lg pa-4">

		<!--Mass Email Subject-->
		<app-text color="primary" size="normal-bold">{{ cardData.massEmailSubject }}</app-text>

		<!--Mass Email Send To Type-->
		<div class="d-flex align-end mt-1">
			<app-icon class="mr-2" color="primary" icon="email" size="16"/>
			<app-text size="small">{{ cardData.massEmailSendToType }}</app-text>
		</div>

		<!--Mass Email Date Sent-->
		<div class="d-flex align-end mt-1">
			<app-icon class="mr-2" color="primary" icon="events" size="16"/>
			<app-text size="small">{{ MIX_formatDate(cardData.createdDateTime, 'short') }}</app-text>
		</div>

	</div>
</template>

<script>

export default {

	name: "MassEmailMobileCard",

	props: ['cardData'],

	data: () => ({}),

	methods: {},

}
</script>

<style scoped>

</style>
