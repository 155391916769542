<template>
	<div class="appGrey pa-4">

		{{ computedInit }}

		<!--Allocated Spaces-->
		<form-section-title description="Allocate parking spaces to this Organisation"
							title="Allocated Spaces"/>
		<app-form-field form-type="textInput"
						:disabled="isReadOnly"
						label="Allocated Spaces"
						type="number"
						v-model="form.organisationParkingAllocatedSpaces"/>

		<!--Car Park-->
		<form-section-title class="mt-8"
							description="Select the Car Park to allocate to this Organisation"
							title="Car Park"/>
		<app-form-field form-type="autoComplete"
						:disabled="isReadOnly"
						:items="['carParksData']"
						label="Car Park"
						:return-object="true"
						v-model="form.organisationParkingAllocatedCarPark"/>

		<!--Save Button-->
		<div class="d-flex justify-end mt-4">
			<app-btn @click.native="handleSaveButton" color="green" icon="save" label="Save"/>
		</div>

	</div>
</template>

<script>

export default {

	name: "ParkingConfigurationForm",

	props: ['formData', 'isReadOnly'],

	data: () => ({
		form: {
			organisationParkingAllocatedCarPark: '',
			organisationParkingAllocatedSpaces: 0,
		},
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * If there is any form data, assign it to the form.
		 * If there is no form data, it will be blank (new).
		 */
		computedInit() {
			const t = this
			const FORM_DATA = t.$props.formData

			if (FORM_DATA?.entityId) {
				t.form = FORM_DATA

				if (!FORM_DATA.organisationParkingAllocatedSpaces) t.form.organisationParkingAllocatedSpaces = 0
				if (!FORM_DATA.organisationParkingAllocatedCarPark) t.form.organisationParkingAllocatedCarPark = ''
			}
		},

	},

	methods: {

		async handleSaveButton() {
			const t = this

			// Create
			if (!t.form?.entityId) await t.createItem()

			// Update
			else await t.updateItem()
		},

		async updateItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_update('organisation', t.form.entityId, t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating Organisation: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem updating this Organisation, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Updated Organisation'
		}

	},

}
</script>

<style scoped>

</style>
