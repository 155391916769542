<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<div v-else>

		<!--Header-->
		<div class="d-flex align-center justify-space-between">

			<!--Title-->
			<page-title icon="user"
						info="View and administer your account."
						title="My Profile"/>

			<!--Edit Button-->
			<edit-icon v-if="pageTabs === 'overview'" @click.native="editItem" :isActive="!isReadOnly"/>

		</div>

		<v-divider class="greyD my-4"/>

		<!--Tabs-->
		<v-tabs v-model="pageTabs" class="rounded-lg">
			<v-tab href="#overview">
				<app-text size="small">Overview</app-text>
			</v-tab>
			<v-tab v-if="MIX_isFeatureAllowed('Qualifications')"
				   href="#qualifications">
				<app-text size="small">Qualifications</app-text>
			</v-tab>
		</v-tabs>

		<!--Tabs Content-->
		<v-tabs-items v-model="pageTabs">

			<!--Overview-->
			<v-tab-item value="overview">
				<user-form @emitReloadPage="reloadPage"
						   class="appGrey pt-4"
						   :full-user-data="fullCurrentUserData"
						   :isReadOnly="isReadOnly"/>
			</v-tab-item>

			<!--Qualifications-->
			<v-tab-item value="qualifications">
				<user-qualifications-table @emitHandleAddItem="openRightPanel"
										   @emitQualificationSelection="handleQualificationSelection"
										   @emitReloadData="emittedReloadData"
										   class="appGrey"
										   parentView="myProfile"
										   :full-user-data="fullCurrentUserData"
										   :isReadOnly="isReadOnly"
										   :qualification-categories-data="qualificationCategoriesData"
										   :qualification-presets-data="qualificationPresetsData"/>
			</v-tab-item>

		</v-tabs-items>

		<!--Right Panel-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">
			<div v-if="isRightPanelVisible"
				 class="appGrey formShadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'">

				<!--User Qualification Form-->
				<div>

					<!--Header-->
					<div class="d-flex align-center primary pa-4">
						<v-spacer/>
						<app-icon @click.native="closeRightPanel"
								  class="cursorPointer" color="white" icon="close" size="32"/>
					</div>

					<!--Tabs-->
					<v-tabs v-model="tabs">
						<v-tab href="#overview">
							<app-text size="small">Overview</app-text>
						</v-tab>
					</v-tabs>

					<!--Tabs Content-->
					<v-tabs-items v-model="tabs">
						<v-tab-item value="overview">
							<user-qualification-form @closeQualificationForm="closeRightPanel"
													 @emitReloadData="emittedReloadData"
													 class="pa-4"
													 :form-data="selectedItem"
													 :full-user-data="fullCurrentUserData"
													 :isReadOnly="isReadOnly"
													 parentView="myProfile"
													 :qualification-categories-data="qualificationCategoriesData"
													 :qualification-presets-data="qualificationPresetsData"/>
						</v-tab-item>
					</v-tabs-items>

				</div>

			</div>
		</transition>

	</div>

</template>

<script>

import UserForm from "@/views/users/userForm/UserForm";
import UserQualificationForm from "@/views/users/userQualificationForm/UserQualificationForm";
import UserQualificationsTable from "@/views/users/userQualificationsTable/UserQualificationsTable";

export default {

	name: "MyProfile",

	components: {UserForm, UserQualificationForm, UserQualificationsTable},

	data: () => ({
		isLoading: true,
		isReadOnly: true,
		isRightPanelVisible: false,

		// Data
		fullCurrentUserData: {},
		pageTabs: '',
		qualificationPresetsData: [],
		qualificationCategoriesData: [],
		selectedItem: {},
		tabs: '',
	}),

	methods: {

		/**
		 * Close Right Panel
		 *
		 * Close the right panel.
		 */
		closeRightPanel() {
			const t = this

			t.selectedItem = {}
			t.isReadOnly = true
			t.isRightPanelVisible = false
		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Emitted Reload Data
		 *
		 * Reload the data when the child component emits the event.
		 */
		async emittedReloadData() {
			const t = this

			await t.loadData()

			// Close the right panel
			t.closeRightPanel()
		},

		/**
		 * Handle Qualification Selection
		 *
		 * Set the selected Qualification and open the Qualification form.
		 *
		 * @param selectedItem {object} the selected Qualification
		 */
		async handleQualificationSelection(selectedItem) {
			const t = this

			t.selectedItem = selectedItem

			t.openRightPanel(selectedItem)
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadFullCurrentUserData(),
				t.loadQualificationPresetsData(),
				t.loadQualificationCategoriesData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Current User Data
		 *
		 * Load User data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadFullCurrentUserData() {
			const t = this
			const CURRENT_USER_AUTH = t.currentUserAuth

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getFullUserData(CURRENT_USER_AUTH.uid)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting full User data: ', RESPONSE.error)
				return
			}

			// Assign the data
			t.fullCurrentUserData = RESPONSE.data
		},

		/**
		 * Load Qualification Categories Data
		 *
		 * Load the required data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadQualificationCategoriesData() {

			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('qualificationCategory')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Qualfication data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem getting the Qualification data, please try again.'
				return
			}
			// Assign the data
			t.qualificationCategoriesData = RESPONSE.data
		},

		/**
		 * Load Qualification Presets Data
		 *
		 * Load the required data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadQualificationPresetsData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('qualificationPreset')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Qualfication data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem getting the Qualification data, please try again.'
				return
			}

			// Assign the data
			t.qualificationPresetsData = RESPONSE.data
		},

		/**
		 * Open Form
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item {object} the selected key
		 * @param formType {string} the type of form to open
		 */
		openRightPanel(item) {
			const t = this

			if (item?.qualificationData?.entityId) {
				t.selectedItem = {...item}
				t.isReadOnly = true
				t.isRightPanelVisible = true
			}
			if (!item?.qualificationData?.entityId) {
				t.selectedItem = {}
				t.isReadOnly = false
				t.isRightPanelVisible = true
			}

		},

		/**
		 * Reload Page
		 *
		 * Reload the page.
		 */
		reloadPage() {
			const t = this

			t.loadData()
			t.editItem()
		}

	},

	mounted() {
		const t = this

		t.loadData()
	}

}
</script>

<style scoped>

</style>
