<template>
	<div>

		<app-text size="medium-bold">Developer's Page</app-text>

		<v-divider class="my-4"/>

		<app-text>Set all <code>userTeam</code> fields from a string to an array</app-text>

		<div class="d-flex">
			<app-btn @click.native="handleAddTeamField"
					 class="mt-4"
					 color="red"
					 label="Add Team Field"/>
		</div>

		<div class="d-flex">
			<app-btn @click.native="handleConvertUserTeamFields"
					 class="mt-4"
					 color="red"
					 label="Convert"/>
		</div>

	</div>
</template>

<script>

export default {

	name: "DeveloperPage.vue",

	data: () => ({}),

	methods: {

		/**
		 * Handle Add Team Field
		 *
		 * Add a userTeam field to all users, and set the value to a generic team for Stewards, or, an empty string for all others.
		 * Remove the userTeams field.
		 * This is a temporary fix to allow for the conversion of the userTeam field to an array.
		 *
		 * @returns {Promise<void>}
		 */
		async handleAddTeamField() {
			const t = this

			try {
				// Get all users
				const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
					'User',
					[],
					[],
				)

				// Handle any errors
				if (RESPONSE.hasErrors) {
					console.error('Error Loading Current User data: ', RESPONSE.errors)
					t.$sharedState.errorMessage = `Error Users data: ${RESPONSE.errors}`
					return
				}

				// Convert all userTeam fields
				const USERS = RESPONSE.data

				for (const user of USERS) {
					console.log('================')

					// Add the userTeam field
					if (!user.userTeam) user.userTeam = ''
					if (user.userType === 'Steward') user.userTeam = '01HCN84ZJW0SKJMGHF17XD0X39'

					user.userTeams = null

					const UPDATE_RESPONSE = await t.MIX_redis_update('user', user.entityId, user)

					console.log('UPDATE_RESPONSE: ', UPDATE_RESPONSE)

					// Handle any errors
					if (UPDATE_RESPONSE.hasErrors) {
						console.error('Error Updating User data: ', UPDATE_RESPONSE.errors)
						t.$sharedState.errorMessage = `Error Updating User data: ${UPDATE_RESPONSE.errors}`
						return
					}
				}
			}

			catch (e) {
				console.error('Error Adding Team Field: ', e)
				t.$sharedState.errorMessage = `Error Adding Team Field: ${e}`
			}
		},

		/**
		 * Handle Convert User Team Fields
		 *
		 * Convert all userTeam fields from a string to an array, and remove the old field.
		 *
		 * @returns {Promise<void>}
		 */
		async handleConvertUserTeamFields() {
			const t = this

			try {

				// Get all users
				const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
					'User',
					[],
					[],
				)

				// Handle any errors
				if (RESPONSE.hasErrors) {
					console.error('Error Loading Current User data: ', RESPONSE.errors)
					t.$sharedState.errorMessage = `Error Users data: ${RESPONSE.errors}`
					return
				}

				// Convert all userTeam fields
				const USERS = RESPONSE.data

				for (const user of USERS) {
					console.log('================')
					console.log('Converting User Team Fields: ', user)

					user.userTeams = user.userTeam ? [user.userTeam] : []
					user.userTeam = null // Remove the old field

					const UPDATE_RESPONSE = await t.MIX_redis_update('user', user.entityId, user)

					console.log('UPDATE_RESPONSE team: ', UPDATE_RESPONSE.data.userTeam)
					console.log('UPDATE_RESPONSE teams: ', UPDATE_RESPONSE.data.userTeams)

					// Handle any errors
					if (UPDATE_RESPONSE.hasErrors) {
						console.error('Error Updating User data: ', UPDATE_RESPONSE.errors)
						t.$sharedState.errorMessage = `Error Updating User data: ${UPDATE_RESPONSE.errors}`
						return
					}
				}

			} catch (e) {
				console.error('Error Converting User Team Fields: ', e)
				t.$sharedState.errorMessage = `Error Converting User Team Fields: ${e}`
			}
		}

	},

}
</script>

<style scoped>

</style>
