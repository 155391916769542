<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<!--Action Bar-->
		<div class="d-flex justify-space-between align-center pt-4">

			<!--Searches-->
			<div class="d-flex">
				<div @focusin="onSearchFocus" @focusout="onSearchBlur" style="width: 100%">
					<app-form-field form-type="textInput"
									append-icon="icons8-search"
									class="mr-4"
									:clearable="true"
									label="Qualification"
									v-model.trim="searchByQualification"/>
				</div>

				<div @focusin="onSearchFocus" @focusout="onSearchBlur" style="width: 100%">
					<app-form-field form-type="textInput"
									append-icon="icons8-search"
									class="mr-4"
									:clearable="true"
									label="Category"
									v-model.trim="searchByCategory"/>
				</div>
			</div>

			<!--Add Button-->
			<app-btn v-if="!isSearchFocused"
					 @click.native="handleAddNewQualification"
					 icon="add"
					 :label="$vuetify.breakpoint.width < 600 ? '' : 'Add'"/>

		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="appWhite rounded-lg mt-4"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.entityId="{ item }">
				<app-text size="small">{{ item.entityId }}</app-text>
			</template>

			<!--Status-->
			<template v-slot:item.qualificationStatus="{ item }">
				<div class="d-flex justify-center align-center">
					<app-icon v-if="item.qualificationData.qualificationStatus === 'Approved'" color="green"
							  icon="success" size="32"
							  :title="item.qualificationData.qualificationStatus"/>
					<app-icon v-if="item.qualificationData.qualificationStatus === 'Pending'" color="orange"
							  icon="pending" size="32"
							  :title="item.qualificationData.qualificationStatus"/>
					<app-icon v-if="item.qualificationData.qualificationStatus === 'Rejected'" color="red"
							  icon="cancel" size="32"
							  :title="item.qualificationData.qualificationStatus"/>
				</div>
			</template>

			<!--Qualification Name-->
			<!--If no qualificationName, show temporaryQualificationName-->
			<template v-slot:item.qualificationName="{ item }">
				<div class="d-flex align-center">
					<app-text v-if="item.qualificationPresetData?.qualificationName"
							  size="small">
						{{ item.qualificationPresetData?.qualificationName }}
					</app-text>
					<app-text v-if="!item.qualificationPresetData?.qualificationName"
							  size="small">
						{{ item.qualificationPresetData?.qualificationTemporaryName }}
					</app-text>
					<app-text v-if="item.qualificationData?.qualificationTemporaryName"
							  size="small">
						{{ item.qualificationData?.qualificationTemporaryName }}
					</app-text>
				</div>
			</template>

			<!--Qualification Category-->
			<template v-slot:item.qualificationCategoryName="{ item }">
				<app-text v-if="item.qualificationCategoryData?.qualificationCategoryName"
						  size="small">
					{{ getCategoryById(item.qualificationCategoryData.entityId) }}
				</app-text>
				<app-text v-else size="small">-</app-text>
			</template>

			<!--Issue Date-->
			<template v-slot:item.qualificationIssueDate="{ item }">
				<!--If qualification has a issue date-->
				<app-text v-if="item.qualificationData.qualificationIssueDate"
						  size="small">
					{{ MIX_formatDate(item.qualificationData.qualificationIssueDate, 'short') }}
				</app-text>
				<!--If qualification has no issue date-->
				<app-text v-if="!item.qualificationData.qualificationIssueDate" color="grey9" size="small">
					Not set
				</app-text>
			</template>

			<!--Expiry Date-->
			<template v-slot:item.qualificationExpiryDate="{ item }">
				<!--If qualification has a expiry date and it is not expired-->
				<app-text
					v-if="item.qualificationData.qualificationExpiryDate && new Date().getTime() < item.qualificationData.qualificationExpiryDate"
					size="small">
					{{ MIX_formatDate(item.qualificationData.qualificationExpiryDate, 'short') }}
				</app-text>
				<!--If qualification has a expiry date and it has expired-->
				<v-chip
					v-if="item.qualificationData.qualificationExpiryDate && new Date().getTime() > item.qualificationData.qualificationExpiryDate"
					class="px-1 white--text" :color="'red'" label small>
					{{ MIX_formatDate(item.qualificationData.qualificationExpiryDate, 'short') }}
				</v-chip>
				<!--If qualification has no expiry date-->
				<app-text v-if="!item.qualificationData.qualificationExpiryDate" color="grey9" size="small">
					Not set
				</app-text>
			</template>

			<!--Action Button-->
			<template v-slot:item.action="{ item }">
				<div class="d-flex justify-end">
					<app-icon v-if="checkExpiringSoon(item.qualificationData.qualificationExpiryDate)"
							  class="flash"
							  color="red"
							  icon="error"
							  title="Expiring soon"/>
					<app-icon @click.native="handleQualificationSelection(item)"
							  class="cursorPointer"
							  color="primary"
							  icon="arrowForward"/>
				</div>
			</template>

		</v-data-table>

		<!--Mobile Card-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No data message-->
			<app-text v-if="!computedTableData.length"
					  class="text-center mt-4" color="grey9">
				You have no Qualifications to view
			</app-text>
			<user-qualification-mobile-card v-for="item in computedTableData"
											:key="item.qualificationData.entityId"
											@click.native="handleQualificationSelection(item)"
											:cardData="item"
											:qualification-categories-data="qualificationCategoriesData"
											class="mt-4"/>

		</div>

	</div>

</template>

<script>

import UserQualificationForm from "@/views/users/userQualificationForm/UserQualificationForm";
import UserQualificationMobileCard from "@/views/users/userQualificationMobileCard/UserQualificationMobileCard";

export default {

	name: 'UserQualificationsTable',

	components: {UserQualificationForm, UserQualificationMobileCard},

	props: ['fullUserData', 'parentView', 'isReadOnly', 'qualificationCategoriesData', 'qualificationPresetsData'],

	data: () => ({
		isLoading: false,
		isSearchFocused: false,
		searchByCategory: '',
		searchByQualification: '',
		selectedQualification: {},
		tableHeaders: [
			{text: 'ID', value: 'entityId', align: 'left', sortable: false, hidden: true},
			{
				text: 'User ID',
				value: 'qualificationUserId',
				align: 'left',
				sortable: false,
				hidden: true,
			},
			{
				text: 'Status',
				value: 'qualificationStatus',
				align: 'center',
				sortable: false,
				width: '48px',
			},
			{
				text: 'Qualification Name',
				value: 'qualificationName',
				align: 'left',
				sortable: false,
			},
			{
				text: 'Qualification Category',
				value: 'qualificationCategoryName',
				align: 'left',
				sortable: false,
			},
			{
				text: 'Issue Date',
				value: 'qualificationIssueDate',
				align: 'center',
				sortable: false,
			},
			{
				text: 'Expiry Date',
				value: 'qualificationExpiryDate',
				align: 'center',
				sortable: false,
			},
			{
				text: '',
				value: 'action',
				align: 'right',
				sortable: false,
				width: '48px',
			},
		],

		// Data
		qualificationsData: [],
	}),

	computed: {

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter((h) => !h.hidden)

			return headers
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the table data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			const PRESETS_DATA = t.$props.qualificationPresetsData
			const CATEGORIES_DATA = t.$props.qualificationCategoriesData

			let tableData = t.$props.fullUserData.qualificationsData

			// If there are no qualifications, return an empty array
			if (!tableData) return []

			// Add the qualification data to the tableData
			tableData = tableData.map(item => {
				const QUALIFICATION_PRESET_DATA = PRESETS_DATA.find(q => q.entityId === item.qualificationData.qualificationPresetId)
				const QUALIFICATION_CATEGORY = CATEGORIES_DATA.find(c => c.entityId === QUALIFICATION_PRESET_DATA?.qualificationCategoryId)

				return {
					...item,
					qualificationCategoryData: QUALIFICATION_CATEGORY,
					qualificationPresetData: QUALIFICATION_PRESET_DATA,
				}
			})

			// Search by Qualification Name
			if (t.searchByQualification) {
				const SEARCH_CRITERIA = t.searchByQualification.toUpperCase()
				tableData = tableData.filter(item => item.qualificationData.qualificationName.toUpperCase().includes(SEARCH_CRITERIA))
			}

			// Search by Category Name
			if (t.searchByCategory) {
				const SEARCH_CRITERIA = t.searchByCategory.toUpperCase()
				tableData = tableData.filter(item => {
					const CATEGORY_NAME = t.getCategoryById(item.qualificationData.qualificationCategoryId).toUpperCase()
					return CATEGORY_NAME.includes(SEARCH_CRITERIA)
				})
			}

			// Sort by Qualification Name
			// tableData = tableData.sort((a, b) => (a.qualificationData.qualificationName > b.qualificationData.qualificationName ? 1 : -1))

			return tableData
		},
	},

	methods: {

		/**
		 * Check Expiring Soon
		 *
		 * Check if the expiry date is within 1 week.
		 *
		 * @param itemData {object} the item to check
		 * @returns {boolean} true if the expiry date is within 2 weeks
		 */
		checkExpiringSoon(itemData) {
			const t = this

			if (!itemData) return false

			const EXPIRY_DATE = new Date(itemData).getTime()
			const TODAY = new Date().getTime()
			const ONE_WEEK = 1000 * 60 * 60 * 24 * 7

			return EXPIRY_DATE - TODAY < ONE_WEEK
		},

		/**
		 *
		 * Delete Item
		 *
		 * Delete the selected document by its ID.
		 *
		 * @param itemData {object} the item to delete
		 */
		async deleteItem(itemData) {
			const t = this

			const RESPONSE = await t.MIX_redis_delete('qualification', itemData.entityId, itemData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting qualification', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem deleting the qualification, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Deleted qualification'

			// Reload the data
			await t.loadData()
		},

		/**
		 * Emitted Reload Data
		 *
		 * Assign the emitted data to the selected item, and reload the data.
		 * This will enable the form to be kept open and updated, rather than close the form and open it again.
		 *
		 * @param payload {object} the new Qualfications data
		 */
		async emittedReloadData() {
			const t = this

			// Close the form
			if (t.$props.parentView === 'users') {
				t.$emit('emitCloseQualificationForm')
			} else if (t.$props.parentView === 'myProfile') {
				t.$emit('emitCloseRightPanel')
			}

			// Emit the data to the parent component
			t.$emit('emitReloadData')
		},

		/**
		 * Get Category By ID
		 *
		 * Get the category name by its ID.
		 *
		 * @param qualificationCategoryId {string} the category ID
		 * @returns {string} the category name
		 */
		getCategoryById(qualificationCategoryId) {
			const t = this

			const CATEGORY = t.qualificationCategoriesData.find(category => category.entityId === qualificationCategoryId)

			return CATEGORY?.qualificationCategoryName || 'None'
		},

		/**
		 * Handle Add New Qualification
		 *
		 * Set the Qualification form visibility to true and set isReadOnly to false.
		 *
		 */
		handleAddNewQualification() {
			const t = this

			if (t.$props.parentView === 'users') {

				//emit open qualification form
				t.$emit('emitHandleAddQualification')

			} else if (t.$props.parentView === 'myProfile') {

				//emit open right panel
				t.$emit('emitHandleAddItem')
			}
		},

		/**
		 * Handle Qualification Selection
		 *
		 * Set the selected Qualification and open the Qualification form.
		 *
		 * @param itemData {object} the selected Qualification
		 */
		handleQualificationSelection(itemData) {
			const t = this

			t.selectedQualification = itemData

			if (t.$props.parentView === 'users') {
				//emit open qualification form
				t.$emit('emitQualificationSelection', itemData)

				// //emit the selected item
				t.$emit('emitSelectedQualification', t.selectedQualification)

			} else if (t.$props.parentView === 'myProfile') {
				//emit open right panel
				t.$emit('emitQualificationSelection', itemData)

			}
		},

		/**
		 * On Search Blur
		 *
		 * When the search field is blurred, set the isSearchFocused flag to false.
		 */
		onSearchBlur() {
			const t = this

			if (t.$vuetify.breakpoint.width < 600) t.isSearchFocused = false
		},

		/**
		 * On Search Focus
		 *
		 * When the search field is focused, set the isSearchFocused flag to true.
		 * This is used to expand the search when on smaller devices.
		 */
		onSearchFocus() {
			const t = this

			if (t.$vuetify.breakpoint.width < 600) t.isSearchFocused = true
		},
	},
}

</script>
