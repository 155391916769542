<template>
	<div>

		<!--Text Input-->
		<div v-if="formType === 'textInput'">

			<!--Field-->
			<v-text-field @input="updateValue($event)"
						  @click:append="handleAppendClick"
						  :append-icon="appendIcon"
						  :background-color="backgroundColor || 'appWhite'"
						  class="rounded-lg labelColor"
						  :clearable="clearable"
						  :counter="counter"
						  :disabled="disabled"
						  :error="error"
						  :error-messages="errorMessage"
						  flat
						  hide-details="auto"
						  hide-spin-buttons
						  :label="label"
						  :maxlength="maxLength"
						  outlined
						  :placeholder="placeholder || label"
						  :prepend-inner-icon="prependInnerIcon"
						  :type="type || 'text'"
						  :value="value"/>

		</div>

		<!--Text Area-->
		<div v-if="formType === 'textArea'">

			<!--Field-->
			<v-textarea @input="updateValue($event)"
						:background-color="backgroundColor || 'appWhite'"
						class="rounded-lg labelColor"
						:clearable="clearable"
						:counter="counter"
						:disabled="disabled"
						:error="error"
						:error-messages="errorMessage"
						flat
						hide-details="auto"
						:label="label"
						:max-length="counter"
						outlined
						:placeholder="placeholder || label"
						:rows="rows || 4"
						:type="'text' || type"
						:value="value"/>

		</div>

		<!--Select-->
		<div v-if="formType === 'select'">

			<!--Field-->
			<v-select @input="updateValue($event)"
					  :background-color="backgroundColor || 'appWhite'"
					  class="xxx rounded-lg labelColor"
					  :clearable="clearable"
					  :chips="deletableChips || smallChips || chips"
					  :deletable-chips="deletableChips"
					  :disabled="disabled"
					  :error="error"
					  :error-messages="errorMessage"
					  flat
					  hide-details="auto"
					  :hint="hint"
					  :persistent-hint="persistentHint"
					  item-color="primary"
					  :items="items"
					  :item-text="itemText"
					  :item-value="itemValue"
					  :label="label"
					  :multiple="multiple"
					  outlined
					  :prepend-inner-icon="prependInnerIcon"
					  :placeholder="placeholder || label"
					  :menu-props="{bottom: true, offsetY: true}"
					  :return-object="returnObject"
					  :small-chips="smallChips"
					  :value="value"/>

		</div>

		<!--Autocomplete-->
		<div v-if="formType === 'autoComplete'">

			<!--Field-->
			<v-autocomplete @input="updateValue($event)"
							@change="handeChange($event)"
							:background-color="backgroundColor || 'appWhite'"
							:chips="deletableChips || smallChips || chips"
							class="rounded-lg labelColor"
							:clearable="clearable"
							:deletable-chips="deletableChips"
							:disabled="disabled"
							:error="error"
							:error-messages="errorMessage"
							flat
							hide-details="auto"
							item-color="primary"
							:items="items"
							:item-text="itemText"
							:item-value="itemValue"
							:label="label"
							:multiple="multiple"
							:no-data-text="noDataText || 'Nothing to show'"
							outlined
							:placeholder="placeholder || label"
							:return-object="returnObject"
							:menu-props="{bottom: true, offsetY: true}"
							:small-chips="smallChips"
							:value="value"/>

		</div>

	</div>
</template>

<script>
export default {

	name: "AppFormField",

	props: [
		'appendIcon',
		'backgroundColor',
		'chips',
		'clearable',
		'counter',
		'deletableChips',
		'disabled',
		'error',
		'errorMessage',
		'formType',
		'hint',
		'items',
		'itemText',
		'itemValue',
		'label',
		'maxLength',
		'multiple',
		'noDataText',
		'persistentHint',
		'placeholder',
		'prependInnerIcon',
		'returnObject',
		'rows',
		'smallChips',
		'type',
		'value',

		'edgeStyle',
	],

	data: () => ({
		labelStyle: {
			color: 'grey9',
			size: 'small',
		}
	}),

	methods: {

		/**
		 * Update Value
		 *
		 * Emit the value back to the parent's v-model.
		 *
		 * @param value the entered value to emit back
		 */
		updateValue(value) {
			this.$emit('input', value)
		},

		handleAppendClick() {
			this.$emit('appendClick')
		},

		handeChange(value) {
			this.$emit('onChange', value)
		}

	}

}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
	border-color: transparent;
}
.labelColor >>> .v-label{
	color: var(--v-primary-base) !important;
	opacity: 0.5;
}
.xxx >>> input {
	width: 48px !important;
}
</style>
