<template>
	<div class="appGrey pa-4">
		{{ computedInit }}

		<!--Form-->
		<v-row no-gutters>

			<!--List Name-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''" :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textInput"
								:clearable="true"
								:disabled="isReadOnly"
								:error="errors.toDoListName"
								:error-message="errors.toDoListNameErrorMessage"
								label="List Name"
								v-model.trim="form.toDoListName"/>
			</v-col>

			<!--List Description (optional)-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:clearable="true"
								:disabled="isReadOnly"
								label="List Description (optional)"
								v-model.trim="form.toDoListDescription"/>
			</v-col>

			<!--Set list defaults-->
			<form-section-title class="mt-8"
								title="List defaults"
								description="Options selected below will set as the default option for all tasks in this list."/>

			<!--List Priority (dropdown, optional, default value) -->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="autoComplete"
								:clearable="true"
								:disabled="isReadOnly"
								:items="['Low', 'Medium', 'High']"
								label="List Priority"
								v-model.trim="form.toDoListPriority"/>
			</v-col>

			<!--Make Private (checkbox, optional) -->
			<!--If private is set, assigned to cannot be set and must be blank-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<div class="d-flex">

					<!--Checkbox-->
					<div @click="handleIsPrivate"
						 class="appWhite d-flex align-center justify-center rounded-lg mr-4 pa-4"
						 style="height: 56px; width: 56px; border: 1px solid grey">
						<app-icon :color="form.toDoListIsPrivate ? 'green' : 'red'"
								  :disabled="isReadOnly || form.toDoListAssignedTo"
								  :icon="form.toDoListIsPrivate ? 'check' : 'close'"
								  size="32"/>
					</div>

					<!--Label-->
					<div>
						<app-text>Make Private</app-text>
						<app-text v-if="form.toDoListAssignedTo"
								color="orange"
								size="small-bold">
							You cannot make a shared list private
						</app-text>
						<app-text class="mt-1" color="grey9" size="small">
							Private lists will only be visible to you.
						</app-text>

					</div>

				</div>
			</v-col>

			<!--Assign List To (dropdown, optional) -->
			<!--If assigned to is set, private cannot be set and must be false-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="autoComplete"
								:clearable="true"
								:disabled="isReadOnly || form?.toDoListIsPrivate"
								:items="usersData"
								item-text="userName"
								label="Assign List To (optional)"
								:return-object="true"
								v-model.trim="assignToUserObject"/>
			<app-text v-if="form?.toDoListIsPrivate" color="orange" size="small">You cannot share a private list</app-text>					
			</v-col>
			

			<!--Due Date (date picker, optional) -->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<date-picker @emitDate="handleDateSelection($event, 'toDoListDueDate')"
							 :clearable="true"
							 :date="form.toDoListDueDate"
							 :disabled="isReadOnly"
							 label="Due Date (optional)"
							 :max-date="[1, 'years', 'future']"/>
			</v-col>
		</v-row>

		<!--Save Button-->
		<div v-if="!isReadOnly" class="d-flex justify-end mt-4">
			<app-btn @click.native="checkForIssuesBeforeSaving"
					 color="green"
					 icon="save"
					 label="Save"/>
		</div>

		<!--Dialogs-->
		<!--To Do List Assign Assignees Dialog-->
		<app-dialog :is-visible="isToDoListAssignAssigneesDialogVisible">
			<to-do-list-assign-assignees-dialog @emitCloseDialog="closeToDoListAssignAssigneesDialog" @emitUpdateAllTaskAssignees="updateAllTaskAssignees"/>
		</app-dialog>


		<!--To Do List Assign Private Dialog-->
		<app-dialog :is-visible="isToDoListAssignPrivateDialogVisible">
			<to-do-list-assign-private-dialog @emitCloseDialog="closeToDoListAssignPrivateDialog"/>
		</app-dialog>

		<!--To Do List Due Date Dialog-->
		<app-dialog :is-visible="isToDoListDueDateDialogVisible">
			<to-do-list-due-date-dialog @emitCloseDialog="closeToDoListDueDateDialog" @emitUpdateAllTaskDueDates="updateAllTaskDueDates"/>
		</app-dialog>

		<!--To Do List Priority Dialog-->
		<app-dialog :is-visible="isToDoListPriorityDialogVisible">
			<to-do-list-priority-dialog @emitCloseDialog="closeToDoListPriorityDialog" @emitUpdateAllTaskPriorities="updateAllTaskPriorities"/>
		</app-dialog>

		<!--To Do List Private Assign Dialog-->
		<app-dialog :is-visible="isToDoListPrivateAssignDialogVisible">
			<to-do-list-private-assign-dialog @emitCloseDialog="closeToDoListPrivateAssignDialog"/>
		</app-dialog>

	</div>
</template>

<script>
import ToDoListAssignAssigneesDialog from '../toDoListAssignAssigneesDialog/ToDoListAssignAssigneesDialog';
import ToDoListAssignPrivateDialog from '../toDoListAssignPrivateDialog/ToDoListAssignPrivateDialog';
import ToDoListDueDateDialog from '../toDoListDueDateDialog/ToDoListDueDateDialog';
import ToDoListPriorityDialog from '../toDoListPriorityDialog/ToDoListPriorityDialog';
import ToDoListPrivateAssignDialog from '../toDoListPrivateAssignDialog/ToDoListPrivateAssignDialog';

export default {

	name: 'ToDoListForm',

	components: { ToDoListAssignAssigneesDialog, ToDoListAssignPrivateDialog, ToDoListDueDateDialog, ToDoListPriorityDialog, ToDoListPrivateAssignDialog,  },

	props: ['formData', 'isReadOnly', 'selectedListTasks', 'usersData'],

	data: () => ({
		errors: {
			toDoListName: false,
			toDoListNameErrorMessage: '',
		},

		isToDoListAssignAssigneesDialogVisible: false,
		isToDoListAssignPrivateDialogVisible: false,
		isToDoListDueDateDialogVisible: false,
		isToDoListPriorityDialogVisible: false,
		isToDoListPrivateAssignDialogVisible: false,
		tasksWithDifferentAssigneeToList: [],
		tasksWithDifferentPriorityToList: [],

		assignToUserObject: {},

		form: {
			toDoListAssignedTo: '',
			toDoListDescription: '',
			toDoListDueDate: 0,
			toDoListIsPrivate: false,
			toDoListName: '',
			toDoListPriority: 'Low',
			toDoListStatus: false,

			createdDateTime: 0,
			createdUserId: '',
			createdUserName: '',
			modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: '',
			isDeleted: false,
		},
	}),

	computed: {
		/**
		 * Computed Init
		 *
		 * If there is any form data, assign it to the form.
		 * If there is no form data, it will be blank (new).
		 */
		computedInit() {
			const t = this
			const FORM_DATA = {...t.$props.formData}

			if (FORM_DATA?.entityId) {
				t.form = FORM_DATA
				t.assignToUserObject = t.$props.usersData.find(item => item.entityId === FORM_DATA.toDoListAssignedTo)
			}
		},
	},

	methods: {

		checkForIssuesBeforeSaving() {
			const t = this
			const selectedListTasks = t.$props.selectedListTasks

			// Only continue if the form has passed validation
			if (!t.validateForm()) return


			// If list assigned, all tasks isToDoTaskPrivate must be false (public)
			//TODO create a funciton for a second button on the dialog so that this works similarly to the other dialogs
			//needs to link to handleSaveItem in the new function

			//Check if all tasks are public
			const allTasksPublic = selectedListTasks.every((item) => item.toDoTaskIsPrivate === false)

			//If not all tasks are public and list assigned is true, open dialog
			if (!allTasksPublic && t.form.toDoListAssignedTo) {
				t.openToDoListAssignPrivateDialog()
				return
			}


			// If private list selected, all assigned tasks must be blank
			//TODO create a funciton for a second button on the dialog so that this works similarly to the other dialogs
			//needs to link to handleSaveItem in the new function

			//Check if all tasks are unassigned
			const allTasksUnassigned = selectedListTasks.every((item) => item.toDoTaskAssignedTo === null)

			//If not all tasks are unassigned and private list is true, open dialog
			if (!allTasksUnassigned && t.form.toDoListIsPrivate) {
				t.openToDoListPrivateAssignDialog()
				return
			}



			// If list assignee is set, all tasks must be set to the same assignee

			//Check for tasks with different assignee to list assignee being set and return an array of the ones that differ
			const tasksWithDifferentAssigneeToList = selectedListTasks.filter(item => item.toDoTaskAssignedTo !== t.form.toDoListAssignedTo)

			//If not all tasks are the same assignee and list assignee is set, open dialog
			if (tasksWithDifferentAssigneeToList.length) {
				t.tasksWithDifferentAssigneeToList = tasksWithDifferentAssigneeToList
				t.openToDoListAssignAssigneesDialog()
				return
			}



		 	//If list due date is set, all tasks must be set to the same due date

			//Check for tasks with different due date to list due date being set and return an array of the ones that differ
			const tasksWithDifferentDueDateToList = selectedListTasks.filter(item => item.toDoTaskDueDate !== t.form.toDoListDueDate)

			//If not all tasks are the same due date and list due date is set, open dialog
			if (tasksWithDifferentDueDateToList.length) {
				t.openToDoListDueDateDialog()
				return
			}
			

			// If list priority is set, all tasks must be set to the same priority

			//Check for tasks with different priority to list priority being set and return an array of the ones that differ
			const tasksWithDifferentPriorityToList = selectedListTasks.filter(item => item.toDoTaskPriority !== t.form.toDoListPriority)

			//If not all tasks are the same priority and list priority is set, open dialog
			if (tasksWithDifferentPriorityToList.length) {
				t.tasksWithDifferentPriorityToList = tasksWithDifferentPriorityToList
				t.openToDoListPriorityDialog()
				return
			}
		},

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {
				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false
			}
		},

		/**
		 * Create Item
		 *
		 * Create the item in the DB.
		 */
		async createItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_create('toDoList', t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating ToDo List: ', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem creating the ToDo List, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Created List'

			t.$emit('emitReloadPage')
		},

		/**
		 * Close ToDo List Assign Assignees Dialog
		 *
		 * Close the to do list assign assignees dialog.
		 */
		 closeToDoListAssignAssigneesDialog() {
			const t = this

			t.isToDoListAssignAssigneesDialogVisible = false
		},

		/**
		 * Close ToDo List Assigned Private Dialog
		 *
		 * Close the to do list assigned private dialog.
		 */
		 closeToDoListAssignPrivateDialog() {
			const t = this
			t.isToDoListAssignPrivateDialogVisible = false
		},

		/**
		 * Close ToDo List Due Date Dialog
		 *
		 * Close the to do list due date dialog.
		 */
		 closeToDoListDueDateDialog() {
			const t = this
			t.isToDoListDueDateDialogVisible = false
		},

		/**
		 * Close Priority Dialog
		 *
		 * Close the to do list priority dialog.
		 */
		 closeToDoListPriorityDialog() {
			const t = this

			t.isToDoListPriorityDialogVisible = false
		},

		/**
		 * Close Private Dialog
		 *
		 * Close the to do list private dialog.
		 */
		 closeToDoListPrivateAssignDialog() {
			const t = this

			t.isToDoListPrivateAssignDialogVisible = false
		},

		/**
		 * Handle Date Picker
		 *
		 * Takes the emitted payload as $event data from the input field, and the field name,
		 * and sets the date to the specified form field.
		 *
		 * @param date the date to set
		 * @param field the form field to set
		 */
		handleDateSelection(date, field) {
			const t = this

			// Set the date to the current form field
			t.form[field] = Number(date)
		},

		/**
		 * Handle Is Private
		 *
		 * Toggle the isPrivate boolean.
		 */
		handleIsPrivate() {
			const t = this

			// If the list is private, all tasks are private
			if (t.$props.isReadOnly || t.form.toDoListAssignedTo) return

			t.form.toDoListIsPrivate = !t.form.toDoListIsPrivate
		},

		/**
		 * Handle Save Item
		 *
		 * Create/Update the item in the DB, after the form has passed validation.
		 */
		// TODO split this out (successfully) into separate functions
		handleSaveItem() {
			const t = this
		
			t.form.toDoListAssignedTo = t.assignToUserObject?.entityId || ""			

			if (!t.form?.entityId) t.createItem()

			if (t.form?.entityId) t.updateItem()
		},

		/**
		 * Open To Do List Assign Assignees Dialog
		 *
		 * Open the to do list assign assignees dialog.
		 */
		 openToDoListAssignAssigneesDialog() {
			const t = this

			t.isToDoListAssignAssigneesDialogVisible = true
		},

		/**
		 * Open To Do List Assigned Private Dialog
		 *
		 * Open the to do list assigned private dialog.
		 */
		 openToDoListAssignPrivateDialog() {
			const t = this

			t.isToDoListAssignPrivateDialogVisible = true
		},

		/**
		 * Open To Do List Due Date Dialog
		 *
		 * Open the to do list due date dialog.
		 */
		 openToDoListDueDateDialog() {
			const t = this

			t.isToDoListDueDateDialogVisible = true
		},

		/**
		 * Open To Do List Priority Dialog
		 *
		 * Open the to do list priority dialog.
		 */
		 openToDoListPriorityDialog() {
			const t = this

			t.isToDoListPriorityDialogVisible = true
		},

		/**
		 * Open To Do List Private Dialog
		 *
		 * Open the to do list private dialog.
		 */
		 openToDoListPrivateAssignDialog() {
			const t = this

			t.isToDoListPrivateAssignDialogVisible = true
		},

		/**
		 * Update All Task Assignees
		 *
		 * Update all tasks to the same assignee as the list.
		 */
		async updateAllTaskAssignees() {
			const t = this

			for(const item of t.tasksWithDifferentAssigneeToList) {
				item.toDoTaskAssignedTo = t.assignToUserObject.entityId
				await t.updateTask(item)
			}

			t.handleSaveItem()
		 },

		/**
		 * Update All Task Due Dates
		 *
		 * Update all tasks to the same due date as the list.
		 */
		async updateAllTaskDueDates() {
			const t = this

			for(const item of t.selectedListTasks) {
				item.toDoTaskDueDate = t.form.toDoListDueDate
				await t.updateTask(item)
			}

			t.handleSaveItem()
		},

		/**
		 * Update All Task Priorities
		 *
		 * Update all tasks to the same priority as the list.
		 */
		async updateAllTaskPriorities() {
			const t = this

			for(const item of t.tasksWithDifferentPriorityToList) {
				item.toDoTaskPriority = t.form.toDoListPriority
				await t.updateTask(item)
			}

			t.handleSaveItem()
		},

		/**
		 * Update Item
		 *
		 * Update the item in the DB.
		 */
		async updateItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_update('toDoList', t.form.entityId, t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating ToDo List: ', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem updating the ToDo List, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Updated List'

			// Close all dialog types
			t.isToDoListAssignAssigneesDialogVisible = false
			t.isToDoListDueDateDialogVisible = false
			t.isToDoListPriorityDialogVisible = false

			t.$emit('emitReloadPage')

			//TODO: change reload page to reload data
			//(hint) see SWAPP tasks
		},

		/**
		 * Update Task
		 *
		 * Update the Task in the DB.
		 */
		 async updateTask(taskData) {
			const t = this

			const RESPONSE = await t.MIX_redis_update('toDoTask', taskData.entityId, taskData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating ToDo Task: ', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem updating the ToDo Task, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Updated Task'
		},

		/**
		 * Validate Form
		 *
		 * Validate the required form fields and return a boolean if the form has passed, or not.
		 *
		 * @returns {boolean} - if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// ToDo List Name
			if (!t.form?.toDoListName) {
				t.errors.toDoListName = true
				t.errors.toDoListNameErrorMessage = 'A List Name is required.'
			}

			return !Object.values(t.errors).includes(true)
		},
	},

}
</script>

<style scoped></style>
