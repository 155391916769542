<template>
	<div class="appGrey pa-4">

		<!--Instructional Text-->
        <div class="text-center">
            <app-text>
			    You can’t assign this list to another user as it contains private tasks.
			    <br>
			    <br>
			    Please make all tasks public and try again.
		    </app-text>
        </div>


		<v-divider class="mt-4"/>

		<!--Action Buttons-->
		<div class="d-flex justify-center mt-4">

			<!--Cancel-->
			<app-btn @click.native="closeDialog"
					 icon="cancel"
					 label="Close"/>
		</div>

	</div>
</template>

<script>
export default {

	name: "ToDoListAssignPrivateDialog",

	methods: {

		/**
		 * Close Dialog
		 *
		 * Emit a message back to the parent component to close the dialog.
		 */
         closeDialog() {
			const t = this

			t.$emit('emitCloseDialog')
		},
		
	},

}
</script>



<style scoped>

</style>