<template>
	<div class="appGrey pa-4">

		<!--Instructional Text-->
        <div class="text-center">
            <app-text>
                You can’t assign a due date to this list that differs to it's existing tasks due dates.			    <br>
			    <br>
			    Would you like to update all tasks to this due date instead?
		    </app-text>
        </div>


		<v-divider class="mt-4"/>

		<!--Action Buttons-->
		<div class="d-flex flex-column justify-space-between align-center mt-4">
			<!--Accept-->
			<app-btn @click.native="handleUpdateAllDueDates"
					 color="primary"
					 label="Update all tasks with new due date"/>
					 
			<!--Cancel-->
			<app-btn @click.native="closeDialog"
					 icon="cancel"
					 label="Cancel & don't update list due date"/>
		</div>
	</div>
</template>

<script>
export default {

	name: "ToDoListDueDateDialog",

	methods: {

		/**
		 * Close Dialog
		 *
		 * Emit a message back to the parent component to close the dialog.
		 */
         closeDialog() {
			const t = this

			t.$emit('emitCloseDialog')
		},

		/**
		 * Handle Update All Task Due Dates
		 *
		 * Emit a message back to the parent component to update all tasks with the new due date.
		 */
		 handleUpdateAllDueDates() {
			const t = this

		 	t.$emit('emitUpdateAllTaskDueDates')
		}

	},

}
</script>



<style scoped>

</style>