<template>
	<div class="appGrey" style="min-height: 100%">

		<page-loading-animation :is-loading="isLoading"/>

		<!--Teams-->
		<div v-if="!isLoading">

			<!--Form-->
			<div class="d-flex align-center justify-space-between">

				<!--Teams Selection-->
				<app-form-field form-type="autoComplete"
								:deletable-chips="true"
								:disabled="!isSelectionEditable"
								:items="computedAvailableTeamsData"
								item-text="teamName"
								label="Teams"
								:multiple="true"
								:return-object="true"
								:small-chips="true"
								style="width: 100%"
								v-model="tempEventTeamsData"/>

				<!--Select All Button-->
				<app-btn v-if="isSelectionEditable"
						 @click.native="handleAddAllButton"
						 class="ml-4"
						 :color="(tempEventTeamsData.length === computedAvailableTeamsData.length) && 'grey'"
						 :label="tempEventTeamsData.length === computedAvailableTeamsData.length ? 'All Selected' : 'Select All'"/>

				<!--Save Button-->
				<app-btn v-if="isSelectionEditable"
						 @click.native="saveEventTeams(tempEventTeamsData)"
						 class="ml-4"
						 color="green"
						 :disabled="!tempEventTeamsData.length"
						 icon="save"
						 label="Save"/>

				<!--Edit Button-->
				<edit-icon @click.native="editItem" :isActive="isSelectionEditable"/>

			</div>

			<!--User On Multiple Teams Dialog-->
			<div v-if="computedCheckForUsersOnMultipleTeams.length"
				 class="appWhite rounded-lg mt-4 pa-4" style="outline: 1px solid #ff0000">

				<!--Icon | Title-->
				<div class="d-flex align-center">
					<app-icon class="flash mr-4" color="orange" icon="error" size="48"/>
					<div>
						<app-text color="red" size="normal">
							Users on multiple teams with conflicting times.
						</app-text>
						<app-text color="grey9" size="small">
							Please ensure the team's timings don't clash before publishing the event.
							<br>
							If you haven't set any team times, all teams will be using the event times.
						</app-text>
					</div>
				</div>

				<v-divider class="greyD my-4"/>

				<!--User & Teams Data-->
				<div v-for="item in computedCheckForUsersOnMultipleTeams" :key="item.userName"
					 class="d-flex align-center">
					<app-text size="small-bold">{{ item.userName }}</app-text>
					<div v-for="team in item.teamData" @key="item.userName">
						<app-text class="ml-2" size="small">- {{ team.teamName }}</app-text>
					</div>
				</div>

			</div>

			<!--Table-->
			<v-data-table class="appWhite rounded-lg mt-4"
						  :items="computedTableData"
						  :headers="computedTableHeaders"
						  expanded.sync="expanded"
						  :single-expand="false"
						  show-expand>

				<!--Team Members Expansion Panel-->
				<template v-slot:expanded-item="{headers, item}">
					<td class="appGrey pt-4" :colspan="headers.length">

						<!--No Users Message-->
						<app-text v-if="!item.usersData.length" class="d-flex justify-center pb-4">
							There are no users to show
						</app-text>

						<!--Event Not Published Message-->
						<!--NOTE: This needs to be here as there will be no EventUser records which will break the table-->
						<app-text v-else-if="!eventData.eventIsPublished" class="d-flex justify-center pb-4">
							Publish this event to view user's event details
						</app-text>

						<div v-else>
							<div v-for="user in item.usersData" :key="user.entityId"
								 class="d-flex align-center mb-4 pb-4"
								 style="border-bottom: 1px solid #DDDDDD">

								<!--User Event Status-->
								<v-chip class="d-flex justify-center mr-4"
										:color="getStatusColour(user.eventUserData.eventUserStatus)"
										text-color="white"
										small
										style="width: 104px">
									{{ user.eventUserData.eventUserStatus }}
								</v-chip>

								<!--User Details-->
								<div style="width: 200px">

									<!--User Name-->
									<app-text size="small-bold">{{ user.userData.userName }}</app-text>

									<!--User Internal ID | More Info Icon-->
									<div class="d-flex align-end">
										<app-text color="grey9" size="small">{{ user.userData.userInternalIdNumber }}
										</app-text>
										<app-icon @click.native="openUserInfoDialog(user.userData)"
												  class="ml-2" color="grey" icon="info" size="16" title="More Info"/>
									</div>

									<!--User Role-->
									<app-text color="grey9" size="small">
										{{ user.userData.userRole === 'User' ? 'Steward' : user.userData.userRole }}
									</app-text>

								</div>

								<v-spacer/>

								<!--User Start/Finish Times-->
								<div class="d-flex flex-column align-center ml-4" style="min-width: 96px">
									<app-text size="small-bold">Start & Finish</app-text>
									<app-text v-if="user.eventUserData.eventUserStartTime" size="small">
										{{ user.eventUserData.eventUserStartTime }}
										-
										{{ user.eventUserData.eventUserFinishTime }}
									</app-text>
									<app-text v-else color="greyD" size="small">Not Set</app-text>
								</div>

								<v-spacer/>

								<!--Change Status Buttons-->
								<div class="d-flex ml-4">
									<app-btn @click.native="handleUserEventStatusButton(user, 'Available')" class="mr-2"
											 color="orange" label="Available" labelSize="small"/>
									<app-btn @click.native="handleUserEventStatusButton(user, 'Confirmed')" class="mr-2"
											 color="green" label="Confirm" labelSize="small"/>
									<app-btn @click.native="handleUserEventStatusButton(user, 'Reserved')" class="mr-2"
											 color="blue" label="Reserve" labelSize="small"/>
									<app-btn @click.native="handleUserEventStatusButton(user, 'Not Required')"
											 class="mr-2"
											 color="red" label="Not Required" labelSize="small"/>
									<app-btn @click.native="handleUserEventStatusButton(user, 'Unavailable')"
											 class="mr-2"
											 color="grey3" label="Unavailable" labelSize="small"/>
									<app-btn @click.native="handleUserEventStatusButton(user, 'No Response')"
											 class="mr-2"
											 color="grey9" label="No Response" labelSize="small"/>
								</div>

								<!--User Menu-->
								<more-actions-menu @emitMenuSelection="emittedUserMenuSelection($event, item, user)"
												   :button="true"
												   :menuList="moreActionsUserMenuOption"/>

							</div>
						</div>

					</td>
				</template>

				<!--Team Name-->
				<template v-slot:item.teamName="{item}">
					<app-text size="small">{{ item.eventTeamExtraData.teamName }}</app-text>
				</template>

				<!--Steward Start/Finish-->
				<template v-slot:item.eventTeamStewardsTimes="{item}">
					<app-text size="small">
						{{ item.eventTeamData.eventTeamStewardsStartTime }}
						-
						{{ item.eventTeamData.eventTeamStewardsFinishTime }}
					</app-text>
				</template>

				<!--Supervisor Start/Finish-->
				<template v-slot:item.eventTeamSupervisorsTimes="{item}">
					<app-text size="small">
						{{ item.eventTeamData.eventTeamSupervisorsStartTime }}
						-
						{{ item.eventTeamData.eventTeamSupervisorsFinishTime }}
					</app-text>
				</template>

				<!--Quota-->
				<template v-slot:item.eventTeamQuota="{item}">
					<app-text size="small">{{ item.eventTeamData.eventTeamQuota }}</app-text>
				</template>

				<!--Members-->
				<template v-slot:item.numberOfTeamMembers="{item}">
					<app-text size="small">{{ item.eventTeamExtraData.numberOfTeamMembers }}</app-text>
				</template>

				<!--Available-->
				<template v-slot:item.numberOfAvailableMembers="{item}">
					<app-text color="orange" size="small-bold">{{ item.eventTeamExtraData.numberOfAvailableMembers }}
					</app-text>
				</template>

				<!--Confirmed-->
				<template v-slot:item.numberOfConfirmedMembers="{item}">
					<app-text color="green" size="small-bold">{{ item.eventTeamExtraData.numberOfConfirmedMembers }}
					</app-text>
				</template>

				<!--Reserved-->
				<template v-slot:item.numberOfReservedMembers="{item}">
					<app-text color="blue" size="small-bold">{{ item.eventTeamExtraData.numberOfReservedMembers }}
					</app-text>
				</template>

				<!--Balance-->
				<template v-slot:item.teamBalance="{item}">
					<app-text
						:color="item.eventTeamExtraData.teamBalance === 0 ? 'grey' : item.eventTeamExtraData.teamBalance < 0 ? 'red' : 'green'"
						size="small-bold">{{ item.eventTeamExtraData.teamBalance }}
					</app-text>
				</template>

				<!--Action-->
				<template v-slot:item.action="{item}">
					<more-actions-menu @emitMenuSelection="emittedMenuSelection($event, item)"
									   :button="false"
									   :menuList="moreActionsMenuOption"/>
				</template>

			</v-data-table>

		</div>

		<!--Dialogs ----------------------------------------------------------------------------------------- Dialogs-->

		<!--Steward Times-->
		<app-dialog :is-visible="isStewardsTimesDialogVisible">
			<event-stewards-times-dialog @emitCloseDialog="closeStewardsTimesDialog"
										 @emitUpdateStewardsTimes="emittedUpdateStewardsTimes"
										 :startTime="selectedEventTeamTableRow.eventTeamStewardsStartTime"
										 :finishTime="selectedEventTeamTableRow.eventTeamStewardsFinishTime"/>
		</app-dialog>

		<!--Supervisor/Deputy Times-->
		<app-dialog :is-visible="isSupervisorsTimesDialogVisible">
			<event-supervisors-times-dialog @emitCloseDialog="closeSupervisorsTimesDialog"
											@emitUpdateSupervisorsTimes="emittedUpdateSupervisorsTimes"
											:startTime="selectedEventTeamTableRow.eventTeamSupervisorsStartTime"
											:finishTime="selectedEventTeamTableRow.eventTeamSupervisorsFinishTime"/>
		</app-dialog>

		<!--Quota-->
		<app-dialog :is-visible="isTeamQuotaDialogVisible">
			<event-quota-dialog @emitCloseDialog="closeTeamQuotaDialog"
								@emitUpdatedTeamQuota="emittedUpdatedTeamQuota"
								:teamQuota="selectedEventTeamTableRow.eventTeamQuota"/>
		</app-dialog>

		<!--Remove Team-->
		<app-dialog :is-visible="isRemoveTeamDialogVisible">
			<event-remove-team-dialog @emitCloseDialog="closeRemoveTeamDialog"
									  @emitDeleteEventTeam="emittedDeleteEventTeam"/>
		</app-dialog>

		<!--User Times-->
		<app-dialog :is-visible="isUserTimesDialogVisible">
			<event-user-times-dialog @emitCloseDialog="closeUserTimesDialog"
									 @emitUpdateUserTimes="emittedUpdateUserTimes"
									 :startTime="selectedUserData?.eventUserData?.eventUserStartTime || selectedEventTeamTableRow.eventTeamStewardsStartTime"
									 :finishTime="selectedUserData?.eventUserData?.eventUserFinishTime || selectedEventTeamTableRow.eventTeamStewardsFinishTime"
									 :user-data="selectedUserData"/>
		</app-dialog>

		<!--User Information-->
		<app-dialog :is-visible="isUserInfoDialogVisible">
			<event-user-information @closeDialog="closeUserInfoDialog"
									:user-data="selectedUserData"/>
		</app-dialog>

		<!--Update User Event Status-->
		<app-dialog :is-visible="isUpdateUserEventStatusDialogVisible">
			<event-user-status-change-dialog @closeDialog="closeUpdateUserEventStatusDialog"
											 @updateUserEventStatus="emittedUpdateUserEventStatus"
											 :new-user-status="newUserEventStatus"
											 :user-data="selectedUserData"/>
		</app-dialog>

	</div>
</template>

<script>
import EventStewardsTimesDialog from "@/views/events/admin/eventStewardsTimesDialog/EventStewardsTimesDialog.vue";
import EventSupervisorsTimesDialog
	from "@/views/events/admin/eventSupervisorsTimesDialog/EventSupervisorsTimesDialog.vue";
import EventQuotaDialog from "@/views/events/admin/eventQuotaDialog/EventQuotaDialog.vue";
import EventRemoveTeamDialog from "@/views/events/admin/eventRemoveTeamDialog/EventRemoveTeamDialog.vue";
import EventUserTimesDialog from "@/views/events/admin/eventUserTimesDialog/EventUserTimesDialog.vue";
import EventUserInformation from "@/views/events/admin/eventUserInformation/EventUserInformation.vue";
import EventUserStatusChangeDialog
	from "@/views/events/admin/eventUserStatusChangeDialog/EventUserStatusChangeDialog.vue";

export default {

	name: "EventTeamsForm",

	components: {
		EventUserStatusChangeDialog,
		EventUserInformation,
		EventUserTimesDialog,
		EventRemoveTeamDialog, EventQuotaDialog, EventSupervisorsTimesDialog, EventStewardsTimesDialog
	},

	props: ['eventData', 'isReadOnly', 'teamsData'],

	data: () => ({

		expanded: [],

		currentEventTeamsData: [],
		isLoading: true,
		isUpdateUserEventStatusDialogVisible: false,
		isSelectionEditable: false,
		isRemoveTeamDialogVisible: false,
		isStewardsTimesDialogVisible: false,
		isSupervisorsTimesDialogVisible: false,
		isTeamQuotaDialogVisible: false,
		isUserInfoDialogVisible: false,
		isUserTimesDialogVisible: false,
		moreActionsMenuOption: [
			{name: 'Steward Start/Finish Time', icon: 'time'},
			{name: 'Supervisor Start/Finish Time', icon: 'time'},
			{name: 'Event Quota', icon: 'users'},
			{name: 'Remove', icon: 'delete'},
		],
		moreActionsUserMenuOption: [
			{name: 'Change Start/Finish Time', icon: 'time'},
		],
		newUserEventStatus: '',
		selectedEventTeamTableRow: {},
		selectedUserData: {},
		tableHeaders: [
			{text: 'Id', value: 'id', sortable: false, align: 'start', hidden: true},
			{text: 'Name', value: 'teamName', sortable: false, align: 'start'},
			{text: 'Steward Times', value: 'eventTeamStewardsTimes', sortable: false, align: 'center'},
			{text: 'Supervisor Times', value: 'eventTeamSupervisorsTimes', sortable: false, align: 'center'},
			{text: 'Quota', value: 'eventTeamQuota', sortable: false, align: 'center'},
			{text: 'Members', value: 'numberOfTeamMembers', sortable: false, align: 'center'},
			{text: 'Available', value: 'numberOfAvailableMembers', sortable: false, align: 'center'},
			{text: 'Confirmed', value: 'numberOfConfirmedMembers', sortable: false, align: 'center'},
			{text: 'Reserved', value: 'numberOfReservedMembers', sortable: false, align: 'center'},
			{text: 'Balance', value: 'teamBalance', sortable: false, align: 'center'},
			{text: '', value: 'action', sortable: false, align: 'center', width: '10px'},
		],
		tempEventTeamsData: [],

		// Data
		eventTeamsData: [],
		eventTeamsFiguresData: [],
		eventUsersData: [],
	}),

	computed: {

		/**
		 * Computed Table Data
		 *
		 * Create and return an array of modified EventTeams data.
		 * Each table object contains the EventTeam data, and the extra data required for the table.
		 *
		 * @returns {array} - an array of modified EventTeam (with extra table data), objects
		 */
		computedTableData() {
			const t = this
			const TEAMS_DATA = t.$props.teamsData
			const EVENT_TEAMS_FIGURES_DATA = t.eventTeamsFiguresData
			const USERS_DATA = t.usersData
			const EVENT_USERS_DATA = t.eventUsersData
			let currentEventTeamsData = t.currentEventTeamsData
			let tableData = []

			currentEventTeamsData.forEach(et => {

				// Get the Team data for the current EventTeam
				const TEAM_DATA = TEAMS_DATA.find(team => team.entityId === et.eventTeamTeamId)
				const TEAM_FIGURES_DATA = EVENT_TEAMS_FIGURES_DATA.find(tf => tf.teamId === TEAM_DATA.entityId)
				const TEAM_USERS_DATA = USERS_DATA.filter(u => u.userTeams?.includes(TEAM_DATA.entityId))

				let usersData = []

				TEAM_USERS_DATA.forEach(user => {
					const eventUserData = EVENT_USERS_DATA.find(eu => eu.eventUserUserId === user.entityId && eu.eventUserTeamId === TEAM_DATA.entityId) || {}

					usersData.push({
						userData: user,
						eventUserData: eventUserData
					})
				})

				// Sort users by Supervisor, Deputy, and Steward, and then by Name
				const SUPERVISORS = usersData.filter(user => (user.userData.userRole === 'Supervisor' || user.userData.userPayGrade === 'Chief Steward'))?.sort((a, b) => a.userData.userName < b.userData.userName ? -1 : 1)
				const DEPUTIES = usersData.filter(user => (user.userData.userRole === 'Deputy' || user.userData.userPayGrade === 'Deputy Chief Steward'))?.sort((a, b) => a.userData.userName < b.userData.userName ? -1 : 1)
				const STEWARDS = usersData.filter(user => (user.userData.userRole === 'User' || user.userData.userPayGrade === 'Senior Supervisor'))?.sort((a, b) => a.userData.userName < b.userData.userName ? -1 : 1)
				usersData = [...SUPERVISORS, ...DEPUTIES, ...STEWARDS]

				// Create a table object (has transient values so needs to be constructed)
				const TABLE_OBJECT = {
					id: et.entityId,
					eventTeamData: {
						...et,
					},
					eventTeamExtraData: {
						teamName: TEAM_DATA.teamName,
						numberOfTeamMembers: TEAM_FIGURES_DATA.numberOfTeamMembers,
						numberOfAvailableMembers: TEAM_FIGURES_DATA.numberOfAvailableMembers,
						numberOfConfirmedMembers: TEAM_FIGURES_DATA.numberOfConfirmedMembers,
						numberOfReservedMembers: TEAM_FIGURES_DATA.numberOfReservedMembers,
						teamBalance: TEAM_FIGURES_DATA.teamBalance,
					},
					usersData
				}

				// Add the table object to the table data array
				tableData.push(TABLE_OBJECT)
			})

			// Sort the table data by the Team Name
			tableData = tableData.sort((a, b) => a.eventTeamExtraData.teamName < b.eventTeamExtraData.teamName ? -1 : 1)

			return tableData
		},

		computedTableHeaders() {
			const t = this
			let headers = t.tableHeaders

			// Remove any hidden columns
			headers = t.tableHeaders.filter(header => !header.hidden)

			return headers
		},

		/**
		 * Available Teams Data
		 *
		 * Return an array of available Teams, (not already saved to this Event).
		 *
		 * @returns {array} An array of available Teams
		 */
		computedAvailableTeamsData() {
			const t = this
			const TEAMS_DATA = t.$props.teamsData
			const CURRENT_EVENT_TEAMS_DATA = t.currentEventTeamsData

			// Filter out the Teams that are already saved to this Event
			let availableTeamsData = TEAMS_DATA.filter(team => !CURRENT_EVENT_TEAMS_DATA.find(et => et.eventTeamTeamId === team.entityId))

			// Sort by Team Name
			availableTeamsData.sort((a, b) => a.teamName < b.teamName ? -1 : 1)

			return availableTeamsData
		},

		/**
		 * Check For Users On Multiple Teams
		 *
		 * Check for users that are on multiple teams with conflicting times.
		 * If any are found, return an array of objects containing the userName and the team names.
		 *
		 * @returns {*[]} - an array of objects containing the userName and team names
		 */
		computedCheckForUsersOnMultipleTeams() {
			const t = this
			const EVENT_TEAMS_DATA = t.currentEventTeamsData
			const TEAMS_DATA = t.teamsData
			const USERS_DATA = t.usersData
			let multipleTeamedUsersData = []
			let processedUserNames = new Set()

			// Create a map for faster lookups
			const TEAMS_DATA_MAP = new Map(TEAMS_DATA.map(td => [td.entityId, td.teamName]))

			/**
			 * Parses a time string into minutes.
			 *
			 * @param {string} timeStr - The time string in the format "HH:MM"
			 * @returns {number} The time in minutes
			 */
			const parseTime = timeStr => {
				const [hours, minutes] = timeStr.split(':').map(Number)
				return hours * 60 + minutes
			}

			/**
			 * Gets the start and finish times for a user on a specific team, considering event-level, team-level, and user-level overrides.
			 *
			 * @param {Object} event - The event data
			 * @param {Object} team - The team data
			 * @param {Object} user - The user data
			 * @returns {Object} An object containing the start and finish times as strings
			 */
			const getUserTimes = (event, team, user) => {
				const role = user.userRole
				let startTime, finishTime

				// Event-level times
				if (role === 'User') {
					startTime = event.eventStewardsStartTime
					finishTime = event.eventStewardsFinishTime
				} else {
					startTime = event.eventSupervisorsStartTime
					finishTime = event.eventSupervisorsFinishTime
				}

				// Override with team-level times if they exist
				if (team) {
					if (role === 'User') {
						startTime = team.eventTeamStewardsStartTime || startTime
						finishTime = team.eventTeamStewardsFinishTime || finishTime
					} else {
						startTime = team.eventTeamSupervisorsStartTime || startTime
						finishTime = team.eventTeamSupervisorsFinishTime || finishTime
					}
				}

				// Override with user-level times if they exist
				if (user.eventUserStartTime) {
					startTime = user.eventUserStartTime
					finishTime = user.eventUserFinishTime
				}

				return {startTime, finishTime}
			}

			// Loop through all users
			USERS_DATA.forEach(user => {

				// Check if user has already been processed to avoid duplicates
				if (processedUserNames.has(user.userName)) return

				// Filter user's teams from event teams data
				const USERS_TEAMS = EVENT_TEAMS_DATA.filter(et => user.userTeams?.includes(et.eventTeamTeamId))

				// If user is on multiple teams
				if (USERS_TEAMS.length > 1) {
					let conflict = false
					let userTeamTimes = []

					// Collect user times for each team
					USERS_TEAMS.forEach(team => {
						const times = getUserTimes(t.$props.eventData, team, user)
						userTeamTimes.push({
							teamName: TEAMS_DATA_MAP.get(team.eventTeamTeamId) || '',
							startTime: times.startTime,
							finishTime: times.finishTime,
							parsedStartTime: parseTime(times.startTime),
							parsedFinishTime: parseTime(times.finishTime)
						})
					})

					// Check for time conflicts
					for (let i = 0; i < userTeamTimes.length; i++) {
						for (let j = i + 1; j < userTeamTimes.length; j++) {
							const t1 = userTeamTimes[i]
							const t2 = userTeamTimes[j]
							if ((t1.parsedStartTime < t2.parsedFinishTime && t1.parsedFinishTime > t2.parsedStartTime) ||
								(t2.parsedStartTime < t1.parsedFinishTime && t2.parsedFinishTime > t1.parsedStartTime)) {
								conflict = true
								break
							}
						}
						if (conflict) break
					}

					if (conflict) {
						multipleTeamedUsersData.push({
							userName: user.userName,
							teamData: userTeamTimes.map(times => ({
								teamName: times.teamName,
								startTime: times.startTime,
								finishTime: times.finishTime
							}))
						})

						// Mark this user as processed
						processedUserNames.add(user.userName)
					}
				}
			})

			return multipleTeamedUsersData
		}

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Close Remove Team Dialog
		 *
		 * Close the Remove Team dialog.
		 */
		closeRemoveTeamDialog() {
			const t = this

			t.isRemoveTeamDialogVisible = false
		},

		/**
		 * Close Stewards Times Dialog
		 *
		 * Close the Times dialog.
		 */
		closeStewardsTimesDialog() {
			const t = this

			t.isStewardsTimesDialogVisible = false
		},

		/**
		 * Close Stewards Times Dialog
		 *
		 * Close the Times dialog.
		 */
		closeSupervisorsTimesDialog() {
			const t = this

			t.isSupervisorsTimesDialogVisible = false
		},

		/**
		 * Close Team Quota Dialog
		 *
		 * Close the Quota dialog.
		 */
		closeTeamQuotaDialog() {
			const t = this

			t.isTeamQuotaDialogVisible = false
		},

		/**
		 * Close Update User Event Status Dialog
		 *
		 * Close the Update User Event Status dialog.
		 */
		closeUpdateUserEventStatusDialog() {
			const t = this

			t.isUpdateUserEventStatusDialogVisible = false
		},

		/**
		 * Close User Info Dialog
		 *
		 * Close the User Info dialog.
		 */
		closeUserInfoDialog() {
			const t = this

			t.isUserInfoDialogVisible = false
		},

		/**
		 * Close User Times Dialog
		 *
		 * Close the Times dialog.
		 */
		closeUserTimesDialog() {
			const t = this

			t.isUserTimesDialogVisible = false
		},

		/**
		 * Delete Event Team
		 *
		 * Delete the selected EventTeam from this Event.
		 * If there are any EventUser document (Event has been published), these will also be removed.
		 *
		 * @param eventTeam - the EventTeam to remove
		 * @returns {Promise<void>}
		 */
		async deleteEventTeam(eventTeam) {
			const t = this

			const RESPONSE = await t.MIX_redis_deleteEventTeamFromEvent(eventTeam.entityId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error Removing Team: ', RESPONSE.error)
				return
			}

		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isSelectionEditable = !t.isSelectionEditable
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection - a string-based menu option
		 * @param tableRow - the EventTeams data from the table
		 */
		emittedMenuSelection(selection, tableRow) {
			const t = this

			// Set the selected EventTeam row data
			t.selectedEventTeamTableRow = tableRow.eventTeamData

			if (selection === 'Steward Start/Finish Time') t.openStewardsTimesDialog()
			if (selection === 'Supervisor Start/Finish Time') t.openSupervisorsTimesDialog()
			if (selection === 'Event Quota') t.openTeamQuotaDialog()
			if (selection === 'Remove') t.openRemoveTeamDialog()

		},

		/**
		 * Handle Emitted User Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection - a string-based menu option
		 * @param tableData - the EventTeams data from the table
		 * @param userData - the User data from the table (userData & eventUserData)
		 */
		emittedUserMenuSelection(selection, tableData, userData) {
			const t = this

			// Set the selected EventTeam row data
			t.selectedEventTeamTableRow = tableData.eventTeamData

			// Set the selected EventTeam row data
			t.selectedUserData = userData

			if (selection === 'Change Start/Finish Time') t.openUserTimesDialog()
		},

		/**
		 * Emitted Delete Event Team
		 *
		 * Call to delete the EventTeam, close the dialog, and reload the page.
		 *
		 * @returns {Promise<void>}
		 */
		emittedDeleteEventTeam() {
			const t = this

			t.deleteEventTeam(t.selectedEventTeamTableRow)

			t.$sharedState.successMessage = 'Removed Team'

			t.closeRemoveTeamDialog()
			t.reloadPage()
		},

		/**
		 * Emitted Update Quota
		 *
		 * Take the emitted Quota and update the EventTeam.
		 *
		 * @param teamQuota - the new Quota to update to
		 */
		emittedUpdatedTeamQuota(teamQuota) {
			const t = this
			let eventTeamData = t.selectedEventTeamTableRow

			eventTeamData.eventTeamQuota = teamQuota

			t.updateEventTeam()
		},

		/**
		 * Emitted Update Stewards Times
		 *
		 * Take the emitted data from the time picker and update the start/finish times.
		 *
		 * @param times - the new start/finish times to update to
		 */
		emittedUpdateStewardsTimes(times) {
			const t = this
			let eventTeamData = t.selectedEventTeamTableRow

			eventTeamData.eventTeamStewardsStartTime = times.newStartTime
			eventTeamData.eventTeamStewardsFinishTime = times.newFinishTime

			t.updateEventTeam()
		},

		/**
		 * Emitted Update Supervisors Times
		 *
		 * Take the emitted data from the time picker and update the start/finish times.
		 *
		 * @param times - the new start/finish times to update to
		 */
		emittedUpdateSupervisorsTimes(times) {
			const t = this
			let eventTeamData = t.selectedEventTeamTableRow

			eventTeamData.eventTeamSupervisorsStartTime = times.newStartTime
			eventTeamData.eventTeamSupervisorsFinishTime = times.newFinishTime

			t.updateEventTeam()
		},

		/**
		 * Emitted Update User Times
		 *
		 * Take the emitted data from the time picker and update the start/finish times.
		 *
		 * @param times - the new start/finish times to update to
		 */
		emittedUpdateUserTimes(times) {
			const t = this
			let userData = t.selectedUserData

			userData.eventUserData.eventUserStartTime = times.newStartTime
			userData.eventUserData.eventUserFinishTime = times.newFinishTime

			t.updateEventUser()
		},

		emittedUpdateUserEventStatus() {
			const t = this
			let userData = t.selectedUserData

			userData.eventUserData.eventUserStatus = t.newUserEventStatus

			t.updateEventUser()
		},

		getStatusColour(status) {
			let colour

			if (status === 'Available') colour = 'orange'
			if (status === 'Confirmed') colour = 'green'
			if (status === 'Reserved') colour = 'blue'
			if (status === 'Not Required') colour = 'red'
			if (status === 'Unavailable') colour = 'grey3'
			if (status === 'No Response') colour = 'grey9'

			return colour
		},

		/**
		 * Handle Add All Button
		 *
		 * Handles the action when the "Select All" button is pressed.
		 * Adds all teams from the database to the event that are not already associated with the event,
		 * or present in the temporary selection list.
		 *
		 * @returns {void}
		 */
		handleAddAllButton() {
			const t = this

			// Fetch the teams already added to the event
			const CURRENT_EVENT_TEAMS = t.currentEventTeamsData

			// Check if the temporary teams list already includes all available teams.
			// If so, exit early and do nothing.
			if (t.tempEventTeamsData.length === t.computedAvailableTeamsData.length) return

			// Loop through all teams
			t.teamsData.forEach(team => {
				// Add teams that are:
				// 1) Not already added to the event.
				// 2) Not already present in the tempEventTeamsData.
				if (
					!CURRENT_EVENT_TEAMS.some(eventTeam => eventTeam.eventTeamTeamId === team.entityId)
					&&
					!t.tempEventTeamsData.some(tempTeam => tempTeam.entityId === team.entityId)
				) {
					t.tempEventTeamsData.push(team)
				}
			})
		},

		handleUserEventStatusButton(user, status) {
			const t = this

			t.selectedUserData = user
			t.newUserEventStatus = status

			t.openUpdateUserEventStatusDialog()
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this
			const EVENT_DATA = t.$props.eventData

			t.isLoading = true

			await Promise.all([
				t.loadEventTeamsData(EVENT_DATA.entityId),
				t.loadEventTeamsFiguresByEvent(EVENT_DATA.entityId),
				t.loadUsersData(),
				t.loadEventUsersData(EVENT_DATA.entityId),
			])

			t.isLoading = false
		},

		/**
		 * Load EventTeams Data
		 *
		 * Load EventTeams data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadEventTeamsData(eventId) {
			const t = this

			// Fetch the data
			// const RESPONSE = await t.MIX_redis_getEventTeamsByEvent(eventId)
			const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
				'EventTeam',
				[{whereKey: 'eventTeamEventId', whereValue: eventId}],
				[]
			)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting EventTeams: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.currentEventTeamsData = RESPONSE.data
		},

		/**
		 * Load EventTeams Figures By Event
		 *
		 * Load the EventTeams status figures for the current Event.
		 * This will return an array of objects, each with the calculated figures for the statuses.
		 *
		 * @returns {Promise<void>}
		 */
		async loadEventTeamsFiguresByEvent(eventId) {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getEventTeamsFiguresByEvent(eventId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting EventUsers status figures: ', RESPONSE.error)
				return
			}

			t.eventTeamsFiguresData = RESPONSE.data
		},

		/**
		 * Load Users Data
		 *
		 * Load the Users data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadUsersData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
				'User',
				[{whereKey: 'userType', whereValue: 'Steward'}, {whereKey: 'userStatus', whereValue: 'Approved'}],
				[]
			)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Users: ', RESPONSE.error)
				return
			}

			// Sort by userName
			RESPONSE.data = RESPONSE.data.sort((a, b) => a.userName < b.userName ? -1 : 1)

			t.usersData = RESPONSE.data
		},

		/**
		 * Load EventUsers Data
		 *
		 * Load the EventUsers data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadEventUsersData(eventId) {
			const t = this

			// Fetch the data
			// const RESPONSE = await t.MIX_redis_getEventUsersWhere([{whereKey: 'eventUserEventId', whereValue: eventId}])
			const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
				'EventUser',
				[{whereKey: 'eventUserEventId', whereValue: eventId}],
				[]
			)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting EventUsers: ', RESPONSE.error)
				return
			}

			t.eventUsersData = RESPONSE.data
		},

		/**
		 * Open Update User Event Status Dialog
		 *
		 * Open the Update User Event Status dialog.
		 */
		openUpdateUserEventStatusDialog() {
			const t = this

			t.isUpdateUserEventStatusDialogVisible = true
		},

		/**
		 * Open Remove Team Dialog
		 *
		 * Open the Remove Team dialog.
		 */
		openRemoveTeamDialog() {
			const t = this

			t.isRemoveTeamDialogVisible = true
		},

		/**
		 * Open Stewards Times Dialog
		 *
		 * Open the Times dialog.
		 */
		openStewardsTimesDialog() {
			const t = this

			t.isStewardsTimesDialogVisible = true
		},

		/**
		 * Open Stewards Times Dialog
		 *
		 * Open the Times dialog.
		 */
		openSupervisorsTimesDialog() {
			const t = this

			t.isSupervisorsTimesDialogVisible = true
		},

		/**
		 * Open Quota Dialog
		 *
		 * Open the Quota dialog.
		 */
		openTeamQuotaDialog() {
			const t = this

			t.isTeamQuotaDialogVisible = true
		},

		openUserInfoDialog(userData) {
			const t = this

			t.selectedUserData = userData
			t.isUserInfoDialogVisible = true
		},

		/**
		 * Open User Times Dialog
		 *
		 * Open the Times dialog.
		 */
		openUserTimesDialog() {
			const t = this

			t.isUserTimesDialogVisible = true
		},

		/**
		 * Reload Data
		 *
		 * Emit back to the parent to reload the data.
		 */
		reloadData() {
			const t = this

			t.$emit('emitReloadData')
		},

		/**
		 * Reload Page
		 *
		 * Emit back to the parent to reload the page.
		 */
		reloadPage() {
			const t = this

			t.$emit('emitReloadPage')
		},

		/**
		 * Save Event Teams
		 *
		 * Send the new eventTeamsData array off to the server to create/update the Teams working this Event.
		 * This will update the EventTeams collection.
		 */
		async saveEventTeams(eventTeamsData) {
			const t = this

			const EVENT_DATA = t.$props.eventData

			if (!t.tempEventTeamsData.length) return

			eventTeamsData = {eventData: EVENT_DATA, eventTeamsData}

			// Create EventTeams
			const RESPONSE = await t.MIX_redis_createEventTeams(eventTeamsData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating EventTeams: ', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Updated Event Teams'

			t.isSelectionEditable = false

			t.reloadPage()
		},

		/**
		 * Update Event Team
		 *
		 * Update a single Event Team with the required new data.
		 * Close time dialogs when done.
		 *
		 * @returns {Promise<void>}
		 */
		async updateEventTeam() {
			const t = this

			const RESPONSE = await t.MIX_redis_update('eventTeam', t.selectedEventTeamTableRow.entityId, t.selectedEventTeamTableRow)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating EventTeam: ', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Updated'

			t.closeStewardsTimesDialog()
			t.closeSupervisorsTimesDialog()
			t.closeTeamQuotaDialog()

			await t.loadData()
			t.reloadData()
		},

		async updateEventUser() {
			const t = this
			const EVENT_USER_DATA = t.selectedUserData.eventUserData

			const RESPONSE = await t.MIX_redis_update('EventUser', EVENT_USER_DATA.entityId, EVENT_USER_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating EventUser: ', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Updated'

			t.closeUserTimesDialog()
			t.closeUpdateUserEventStatusDialog()
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			return !Object.values(t.errors).includes(true)
		},

	},

	async mounted() {
		const t = this

		await t.loadData()
	},

}
</script>

<style scoped>

</style>
