<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<!--Qualifications (Presets) Action Bar-->
		<div class="d-flex align-center mt-4">

			<!--Searches-->
			<div @focusin="onSearchFocus" @focusout="onSearchBlur" style="width: 100%">
				<app-form-field form-type="textInput"
								append-icon="icons8-search"
								class="mr-4"
								:clearable="true"
								label="User Name"
								v-model.trim="searchByUser"/>
			</div>

			<!--More Actions Menu-->
			<more-actions-menu v-if="!isSearchFocused"
							   @emitMenuSelection="handleEmittedMenuSelection"
							   :menuList="moreActionsMenuOption"/>
		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="appWhite rounded-lg mt-4"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.entityId="{ item }">
				<app-text size="small">{{ item.entityId }}</app-text>
			</template>

			<!--Status-->
			<template v-slot:item.qualificationStatus="{ item }">
				<div class="d-flex justify-center align-center">
					<app-icon v-if="item.qualificationData?.qualificationStatus === 'Approved'" color="green" icon="success" size="32"
							  :title="item.qualificationStatus"/>
					<app-icon v-if="item.qualificationData?.qualificationStatus === 'Pending'" color="orange" icon="pending" size="32"
							  :title="item.qualificationStatus"/>
					<app-icon v-if="item.qualificationData?.qualificationStatus === 'Rejected'" color="red" icon="cancel" size="32"
							  :title="item.qualificationStatus"/>
				</div>
			</template>

			<!--User Name-->
			<template v-slot:item.qualificationUserId="{ item }">
				<div class="d-flex align-center">
					<app-text size="small">
						{{ getUserById(item.qualificationData?.qualificationUserId) }}
					</app-text>
				</div>
			</template>

			<!--Qualification Name-->
			<!--If no qualificationName, show temporaryQualificationName-->
			<template v-slot:item.qualificationName="{ item }">
				<div class="d-flex align-center">

					<app-text v-if="item.qualificationTemporaryName" size="small">
						{{ item.qualificationData?.qualificationTemporaryName }}
					</app-text>

					<app-text v-if="item.qualificationData?.qualificationTemporaryName" size="small">
						{{ item.qualificationData?.qualificationTemporaryName }}
					</app-text>

					<app-text v-else size="small">
						{{ item.qualificationPresetData?.qualificationName }}
					</app-text>

				</div>
			</template>

			<!--Qualification Category-->
			<template v-slot:item.qualificationCategoryName="{ item }">
				<app-text size="small">{{ item.qualificationCategoryData?.qualificationCategoryName || '-' }}</app-text>
			</template>

			<!--Issue Date-->
			<template v-slot:item.qualificationIssueDate="{ item }">
				<!--If list has a expiry date-->
				<app-text v-if="item.qualificationData?.qualificationIssueDate"
						  size="small">
					{{ MIX_formatDate(item.qualificationData?.qualificationIssueDate, 'short') }}
				</app-text>
				<!--If list has no expiry date-->
				<app-text v-if="!item.qualificationData?.qualificationIssueDate" color="grey9" size="small">
					Not set
				</app-text>
			</template>

			<!--Expiry Date-->
			<template v-slot:item.qualificationExpiryDate="{ item }">
				<!--If list has a expiry date and it is not expired-->
				<app-text v-if="item.qualificationData?.qualificationExpiryDate && new Date().getTime() < item.qualificationData?.qualificationExpiryDate"
						  size="small">
					{{ MIX_formatDate(item.qualificationData?.qualificationExpiryDate, 'short') }}
				</app-text>
				<!--If list has a expiry date and it has expired-->
				<v-chip v-if="item.qualificationData?.qualificationExpiryDate && new Date().getTime() > item.qualificationData?.qualificationExpiryDate"
						class="px-1 white--text"
						:color="'red'"
						label
						small>
					{{ MIX_formatDate(item.qualificationData?.qualificationExpiryDate, 'short') }}
				</v-chip>
				<!--If list has no expiry date-->
				<app-text v-if="!item.qualificationData?.qualificationExpiryDate" color="grey9" size="small">
					Not set
				</app-text>
			</template>

			<!--Action Button-->
			<template v-slot:item.action="{ item }">
				<div class="d-flex justify-end">
					<app-icon v-if="checkExpiringSoon(item.qualificationData?.qualificationExpiryDate)"
							  class="flash"
							  color="red"
							  icon="error"
							  title="Expiring soon"/>
					<app-icon @click.native="handleQualificationSelection(item)"
							  class="cursorPointer"
							  color="primary"
							  icon="arrowForward"/>
				</div>
			</template>

		</v-data-table>

		<!--Mobile Card-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No data message-->
			<app-text v-if="!computedTableData.length"
					  class="text-center mt-4" color="grey9">You have no Lists to view
			</app-text>

			<qualifications-admin-mobile-card v-for="item in computedTableData"
											  :key="item.entityId"
											  @click.native="handleQualificationSelection(item)"
											  :cardData="item"
											  :qualification-categories-data="qualificationCategoriesData"
											  class="mt-4"/>
		</div>

	</div>

</template>

<script>
import QualificationsAdminForm from "@/views/qualifications/qualificationsAdminForm/QualificationsAdminForm";
import QualificationsAdminMobileCard
	from "@/views/qualifications/qualificationsAdminMobileCard/QualificationsAdminMobileCard";

export default {

	name: 'QualificationsAdminTable',

	components: {QualificationsAdminForm, QualificationsAdminMobileCard},

	props: ['qualificationCategoriesData', 'qualificationPresetsData', 'tableTab', 'usersData'],

	data: () => ({

		isLoading: false,
		isSearchFocused: false,
		moreActionsMenuOption: [{name: 'Export', icon: 'export'}],
		searchByUser: '',

		tableHeaders: [
			{text: 'ID', value: 'id', align: 'left', sortable: false, hidden: true},
			{
				text: 'User ID',
				value: 'qualificationUserId',
				align: 'left',
				sortable: false,
				hidden: true,
			},
			{
				text: 'Status',
				value: 'qualificationStatus',
				align: 'center',
				sortable: false,
				width: '48px',
			},
			{
				text: 'User Name',
				value: 'qualificationUserId',
				align: 'left',
				sortable: false,
			},
			{
				text: 'Qualification Name',
				value: 'qualificationName',
				align: 'left',
				sortable: false,
			},
			{
				text: 'Qualification Category',
				value: 'qualificationCategoryName',
				align: 'left',
				sortable: false,
			},
			{
				text: 'Issue Date',
				value: 'qualificationIssueDate',
				align: 'center',
				sortable: false,
			},
			{
				text: 'Expiry Date',
				value: 'qualificationExpiryDate',
				align: 'center',
				sortable: false,
			},
			{
				text: '',
				value: 'action',
				align: 'right',
				sortable: false,
				width: '48px',
			},
		],

		// Data
		qualificationsData: [],
		selectedQualification: {},
	}),

	computed: {

		/**
		 * Computed Export CSV
		 *
		 * Return the data and headers for the CSV export
		 *
		 * @returns {{headers: {}, data: *[]}}
		 */
		computedExportCSV() {
			const t = this
			let data = []
			let headers = {}

			// Add the readable headers for the CSV columns
			headers = {
				status: 'Status',
				userName: 'User Name',
				qualificationName: 'Qualification Name',
				qualificationCategory: 'Qualification Category',
				qualificationIssueDate: 'Issue Date',
				qualificationExpiryDate: 'Expiry Date',
			}

			// Add the data
			t.computedTableData.forEach(item => {

				const DATA_OBJECT = {
					status: item?.qualificationStatus,
					userName: t.getUserById(item?.qualificationUserId),
					qualificationName: item?.qualificationName || item?.qualificationTemporaryName,
					qualificationCategory: t.getCategoryById(item?.qualificationCategoryId),
					qualificationIssueDate: item?.qualificationIssueDate ? t.MIX_formatDate(item?.qualificationIssueDate, 'short') : 'Not set',
					qualificationExpiryDate: item?.qualificationExpiryDate ? t.MIX_formatDate(item?.qualificationExpiryDate, 'short') : 'Not set',
				}

				data.push(DATA_OBJECT)
			})

			return {headers, data}
		},

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter((h) => !h.hidden)

			return headers
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the table data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			let tableData = t.qualificationsData
			const QUALIFICATION_PRESETS_DATA = t.$props.qualificationPresetsData
			const QUALIFICATION_CATEGORIES_DATA = t.$props.qualificationCategoriesData

			// Pending/All Users Tab Selection
			if (t.$props.tableTab === 'pending') tableData = tableData.filter((item) => item.qualificationStatus === 'Pending')

			// Search by User
			if (t.searchByUser) {
				const SEARCH_CRITERIA = t.searchByUser.toUpperCase()
				tableData = tableData.filter(item => {
					const USER_NAME = t.getUserById(item.qualificationUserId).toUpperCase()
					return USER_NAME.includes(SEARCH_CRITERIA)
				})
			}

			// Create a qualification object with the preset and category data
			tableData = tableData.map(item => {
				const PRESET = QUALIFICATION_PRESETS_DATA.find(preset => preset.entityId === item.qualificationPresetId)
				const CATEGORY = QUALIFICATION_CATEGORIES_DATA.find(category => category.entityId === PRESET?.qualificationCategoryId)

				return {
					qualificationData: item,
					qualificationPresetData: PRESET,
					qualificationCategoryData: CATEGORY
				}
			})

			// Sort by Qualification Name
			tableData = tableData.sort((a, b) => (a.qualificationName > b.qualificationName ? 1 : -1))

			return tableData
		},
	},

	methods: {

		/**
		 * Check Expiring Soon
		 *
		 * Check if the expiry date is within 1 week.
		 *
		 * @param itemData {object} the item to check
		 * @returns {boolean} true if the expiry date is within 2 weeks
		 */
		checkExpiringSoon(itemData) {
			const t = this

			if (!itemData) return false

			const EXPIRY_DATE = new Date(itemData).getTime()
			const TODAY = new Date().getTime()
			const ONE_WEEK = 1000 * 60 * 60 * 24 * 7

			return EXPIRY_DATE - TODAY < ONE_WEEK
		},

		/**
		 * Export Data
		 *
		 * Export the data to CSV.
		 */
		exportData() {
			const t = this

			t.MIX_exportDocuments(t.computedExportCSV.headers, 'Qualifications', t.computedExportCSV.data)
		},

		/**
		 * Get Category By ID
		 *
		 * Get the category name by its ID.
		 *
		 * @param qualificationCategoryId {string} the category ID
		 * @returns {string} the category name
		 */
		getCategoryById(qualificationCategoryId) {
			const t = this
			const CATEGORIES_DATA = t.$props.qualificationCategoriesData

			const CATEGORY = CATEGORIES_DATA.find(category => category.entityId === qualificationCategoryId)

			return CATEGORY?.qualificationCategoryName || 'None'
		},

		/**
		 * Get User By ID
		 *
		 * Get the username by its ID.
		 *
		 * @param qualificationUserId {string} the category ID
		 * @returns {string} the category name
		 */
		getUserById(qualificationUserId) {
			const t = this

			const USER = t.$props.usersData.find(user => user.entityId === qualificationUserId)

			return USER?.userName || 'None'
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'Export') t.exportData()
		},

		/**
		 * Handle Qualification Selection
		 *
		 * Set the selected Qualification and open the Qualification form.
		 *
		 * @param itemData {object} the selected Qualification
		 */
		handleQualificationSelection(itemData) {
			const t = this

			t.selectedQualification = itemData

			t.$emit('emitQualificationSelection', itemData.qualificationData)
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadQualificationsData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Qualification Data
		 *
		 * Load the required data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadQualificationsData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('qualification')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Qualfication data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem getting the Qualification data, please try again.'
				return
			}

			// Assign the data
			t.qualificationsData = RESPONSE.data
		},

		/**
		 * On Search Blur
		 *
		 * When the search field is blurred, set the isSearchFocused flag to false.
		 */
		onSearchBlur() {
			const t = this

			if (t.$vuetify.breakpoint.width < 600) t.isSearchFocused = false
		},

		/**
		 * On Search Focus
		 *
		 * When the search field is focused, set the isSearchFocused flag to true.
		 * This is used to expand the search when on smaller devices.
		 */
		onSearchFocus() {
			const t = this

			if (t.$vuetify.breakpoint.width < 600) t.isSearchFocused = true
		},

	},

	async mounted() {
		const t = this

		await t.loadData()
	},
}

</script>
