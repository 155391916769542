<template>
	<div class="appWhite rounded-lg pa-4">

		<div class="d-flex align-center">
			<app-icon class="mr-4" color="orange" icon="error" size="48"/>
			<app-text size="medium">Oops!</app-text>
		</div>

		<v-divider class="greyD my-4"/>

		<app-text>
			Images cannot be pasted into the text editor.
			<br>
			<br>
			Please use the image upload button provided.
		</app-text>

		<v-divider class="greyD my-4"/>

		<app-btn @click.native="closeDialog"
				 :block="true"
				 class="mt-4"
				 label="Close"/>

	</div>
</template>

<script>

export default {

	name: "MassEmailImagePasteDialog",

	data: () => ({}),

	methods: {

		closeDialog() {
			this.$emit('closeDialog')
		}

	},

}
</script>

<style scoped>

</style>
