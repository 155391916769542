<template>
	<div class="appGrey" style="min-height: 100%">

		{{ computedInit }}

		<!--Form-->
		<v-row no-gutters>

			<!--Name-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="true"
								label="Name"
								v-model.trim="form.createdUserName"/>
			</v-col>

			<!--Team-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:items="teamsData"
								item-text="teamName"
								label="Team"
								:return-object="true"
								v-model="displayTeamName"/>
			</v-col>

			<!--Event-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:error="errors.debriefReportEvent"
								:error-message="errors.debriefReportEventErrorMessage"
								:items="eventsData"
								item-text="eventName"
								label="Event"
								:return-object="true"
								v-model="selectedEventData"/>
			</v-col>

			<!--Date-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="true"
								:error="errors.debriefReportDate"
								:error-message="errors.debriefReportDateErrorMessage"
								label="Date"
								v-model="displayEventDate"/>
			</v-col>

			<!--Issues with Equipment-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:disabled="isReadOnly"
								:error="errors.debriefReportEquipmentIssues"
								:error-message="errors.debriefReportEquipmentIssuesErrorMessage"
								label="Issues with Equipment"
								v-model.trim="form.debriefReportEquipmentIssues"/>
			</v-col>

			<!--Personal Issues-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:disabled="isReadOnly"
								:error="errors.debriefReportPersonalIssues"
								:error-message="errors.debriefReportPersonalIssuesErrorMessage"
								label="Personal Issues"
								v-model.trim="form.debriefReportPersonalIssues"/>
			</v-col>

			<!--Defects-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:disabled="isReadOnly"
								:error="errors.debriefReportDefects"
								:error-message="errors.debriefReportDefectsErrorMessage"
								label="Defects"
								v-model.trim="form.debriefReportDefects"/>
			</v-col>

			<!--First Aid Incidents/Accidents-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:disabled="isReadOnly"
								:error="errors.debriefReportFirstAid"
								:error-message="errors.debriefReportFirstAidErrorMessage"
								label="First Aid Incidents/Accidents"
								v-model.trim="form.debriefReportFirstAid"/>
			</v-col>

			<!--Recommendations-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:disabled="isReadOnly"
								:error="errors.debriefReportComments"
								:error-message="errors.debriefReportCommentsErrorMessage"
								label="Recommendations"
								v-model.trim="form.debriefReportComments"/>
			</v-col>

		</v-row>

		<!--Save Button-->
		<div class="d-flex justify-end mt-4">
				<app-btn v-if="!isReadOnly"
						 @click.native="handleSaveItem"
						 color="green"
						 icon="save"
						 label="Save"/>
		</div>

	</div>
</template>

<script>
export default {

	name: "DebriefForm",

	props: ['eventsData', 'fullReportData', 'isReadOnly', 'teamsData'],

	data: () => ({
		errors: {
			debriefReportDate: false,
			debriefReportDateErrorMessage: '',
			debriefReportDefects: false,
			debriefReportDefectsErrorMessage: '',
			debriefReportEquipmentIssues: false,
			debriefReportEquipmentIssuesErrorMessage: '',
			debriefReportEvent: false,
			debriefReportEventErrorMessage: '',
			debriefReportFirstAid: false,
			debriefReportFirstAidErrorMessage: '',
			debriefReportPersonalIssues: false,
			debriefReportPersonalIssuesErrorMessage: '',
			debriefReportComments: false,
			debriefReportCommentsErrorMessage: '',
			debriefReportTeam: false,
			debriefReportTeamErrorMessage: '',
		},
		form: {
			entityId: '',

			debriefReportDate: 0,
			debriefReportDefects: '',
			debriefReportEquipmentIssues: '',
			debriefReportEvent: '',
			debriefReportFirstAid: '',
			debriefReportPersonalIssues: '',
			debriefReportComments: '',
			debriefReportTeam: '',

			createdUserId: '',
			createdUserName: '',
			createdDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			modifiedDateTime: 0,
			isDeleted: false,
			deletedUserId: '',
			deletedUserName: '',
			deletedDateTime: 0,
		},
		selectedEventData: {},
		displayEventDate: '',
		displayTeamName: '',
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * If there is any form data, assign it to the form.
		 * If there is no form data, it will be blank (new).
		 */
		computedInit() {
			const t = this
			const FORM_DATA = t.$props?.fullReportData
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()

			if (FORM_DATA?.reportData?.entityId) {
				t.form = FORM_DATA.reportData
				t.form.createdUserId = FORM_DATA.reportData.createdUserId
				t.form.createdUserName = FORM_DATA.reportData.createdUserName
				t.form.debriefReportTeam = FORM_DATA.reportData.debriefReportTeam
				t.displayTeamName = FORM_DATA.teamData?.teamName
				t.selectedEventData = FORM_DATA.eventData
			} else {
				t.form.createdUserId = CURRENT_USER_DATA.entityId
				t.form.createdUserName = CURRENT_USER_DATA.userName
			}
		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		handleSaveItem() {
			const t = this

			if (!t.validateForm()) return

			// Set the team name to the form
			t.form.debriefReportTeam = t.displayTeamName.entityId

			if (!t.form.entityId) t.saveItem()
			else if (t.form.entityId) t.updateItem()
		},

		/**
		 * Save Item
		 *
		 * Save the form to the DB.
		 */
		async saveItem() {
			const t = this
			const FORM_DATA = t.form

			const RESPONSE = await t.MIX_redis_create('debriefReport', FORM_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating Debrief Report: ', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Created Report'

			t.$emit('emitReloadPage')
		},

		/**
		 * Update Item
		 *
		 * Update the form to the DB.
		 */
		async updateItem() {
			const t = this
			const FROM_DATA = t.form

			const RESPONSE = await t.MIX_redis_update('debriefReport', FROM_DATA.entityId, FROM_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating Debrief Report: ', RESPONSE.error)
				return
			}

			t.$emit('emitReloadPage')
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Event
			if (!t.form.debriefReportEvent) {
				t.errors.debriefReportEvent = true
				t.errors.debriefReportEventErrorMessage = 'Required'
			}

			// Equipment Issues
			if (!t.form.debriefReportEquipmentIssues) {
				t.errors.debriefReportEquipmentIssues = true
				t.errors.debriefReportEquipmentIssuesErrorMessage = 'Required'
			}

			// Personal Issues
			if (!t.form.debriefReportPersonalIssues) {
				t.errors.debriefReportPersonalIssues = true
				t.errors.debriefReportPersonalIssuesErrorMessage = 'Required'
			}

			// Defects
			if (!t.form.debriefReportDefects) {
				t.errors.debriefReportDefects = true
				t.errors.debriefReportDefectsErrorMessage = 'Required'
			}

			// First Aid
			if (!t.form.debriefReportFirstAid) {
				t.errors.debriefReportFirstAid = true
				t.errors.debriefReportFirstAidErrorMessage = 'Required'
			}

			// Recommendations
			if (!t.form.debriefReportComments) {
				t.errors.debriefReportComments = true
				t.errors.debriefReportCommentsErrorMessage = 'Required'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

	watch: {

		/**
		 * Selected Event Data
		 *
		 * When an Event is selected from the dropdown, set the required form data.
		 * Also set the display data (formatted event date)
		 *
		 * @param eventData - the selected event data
		 */
		selectedEventData(eventData) {
			const t = this

			t.form.debriefReportEvent = eventData?.entityId
			t.form.debriefReportDate = eventData?.eventDate

			t.displayEventDate = t.MIX_formatDate(eventData?.eventDate, 'short')
		}

	},

}
</script>

<style scoped>

</style>
