<template>
	<v-expansion-panels flat style="z-index: 0">
		<v-expansion-panel class="appWhite rounded-lg mt-4">

			<!--Header-->
			<v-expansion-panel-header class="d-flex flex-column align-start">

				<!--Name | Area-->
				<div class="d-flex flex-column">
					<app-text color="primary" size="normal-bold">{{ teamUsersData.teamData.teamName }}</app-text>
					<app-text class="mt-2" color="grey9" size="small">{{ teamUsersData.teamData.teamArea }}</app-text>
				</div>

				<!--Team Members vs Quota-->
				<div class="d-flex mt-4">
					<app-text size="normal-bold">
						{{ teamUsersData.supervisorsData.length + teamUsersData.deputiesData.length + teamUsersData.stewardsData.length }}
						/
						{{ teamUsersData.teamData.teamQuota }}
					</app-text>
					<app-text class="ml-2" color="grey9"> team members</app-text>
				</div>

			</v-expansion-panel-header>

			<!--Content-->
			<v-expansion-panel-content>

				<!--Supervisors-->
				<app-text color="primary" size="normal-bold">Supervisors</app-text>
				<v-divider class="my-2"/>
				<app-text v-if="!teamUsersData.supervisorsData.length"
						  class="text-center" color="grey9">This Team has no Supervisors</app-text>
				<team-mobile-card-user-details v-for="item in teamUsersData.supervisorsData" :key="item.entityId"
											   class="mb-8"
											   :userData="item"/>

				<!--Deputies-->
				<app-text color="primary" class="mt-8" size="normal-bold">Deputies</app-text>
				<v-divider class="my-2"/>
				<app-text v-if="!teamUsersData.deputiesData.length"
						  class="text-center" color="grey9">This Team has no Deputies</app-text>
				<team-mobile-card-user-details v-for="item in teamUsersData.deputiesData" :key="item.entityId"
											   class="mb-8"
											   :userData="item"/>

				<!--Stewards-->
				<app-text color="primary" class="mt-8" size="normal-bold">Stewards</app-text>
				<v-divider class="my-2"/>
				<app-text v-if="!teamUsersData.stewardsData.length"
						  class="text-center" color="grey9">This Team has no Stewards</app-text>
				<team-mobile-card-user-details v-for="item in teamUsersData.stewardsData" :key="item.entityId"
											   class="mb-8"
											   :userData="item"/>

			</v-expansion-panel-content>

		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
import TeamMobileCardUserDetails
	from "@/views/teams/teamMobileCard/teamMobileCardUserDetails/TeamMobileCardUserDetails";

export default {

	name: "TeamMobileCard",

	components: {TeamMobileCardUserDetails},

	props: ['teamUsersData'],

}
</script>

<style scoped>

</style>
