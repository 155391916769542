<template>
	<div class="appWhite rounded-lg pa-4" style="width: fit-content">

		<!--Icon | Title-->
		<div class="d-flex align-center">
			<app-icon class="flash mr-4" color="orange" icon="error" size="32"/>
			<app-text size="normal-bold">Users on Multiple Teams</app-text>
		</div>

		<v-divider class="greyD my-4"/>

		<!--Message-->
		<app-text color="grey9">
			The following users are on multiple teams with conflicting times.
			Please resolve these conflicts before continuing.
		</app-text>

		<!--UserName | Team Names | Start Time - Finish Time-->
		<div v-for="item in conflictData">

			<!--UserName-->
			<app-text class="mt-4" size="normal-bold">{{ item.userName }}</app-text>

			<!--Team Names | Start Time - Finish Time-->
			<div v-for="team in item.teamData">
				<app-text class="ml-2">• {{ team.teamName }} ({{ team.startTime }} - {{ team.finishTime }})</app-text>
			</div>

		</div>

		<v-divider class="greyD my-4"/>

		<!--Close Button-->
		<app-btn @click.native="closeDialog" :block="true" class="mt-4" label="Close"/>

	</div>
</template>

<script>

export default {

	name: "EventUsersOnMultipleTeamsConflictDialog",

	props: ['conflictData'],

	data: () => ({}),

	methods: {

		closeDialog() {
			this.$emit('closeDialog')
		}

	},

}
</script>

<style scoped>

</style>
