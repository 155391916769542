<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<div v-else>

		<!--Header-->
		<page-title :divider="true"
					icon="email"
					info="Send mass emails targeting those you need to keep informed."
					title="Mass Emails"/>

		<!--Action Bar-->
		<div class="d-flex align-center mt-4">

			<!--Search-->
			<app-form-field form-type="textInput"
							append-icon="icons8-search"
							class="mr-4"
							:clearable="true"
							label="Email Subject"
							style="width: 100%"
							v-model.trim="searchBySubject"/>

			<!--Add Button-->
			<app-btn @click.native="openRightPanel" class="mr-4" icon="add" label="Add"/>

			<!--Filter Button - with a numbered badge-->
			<div style="position:relative;">
				<app-btn @click.native="toggleFiltersVisibility"
						 class="" color="appWhite" icon="filter" icon-color="primary"/>
				<span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>
			</div>

			<!--More Actions Menu-->
			<!--<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>-->

		</div>

		<!--Clear Filters-->
		<div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
			<app-btn @click.native="clearFilters" icon="close" label="Clear Filters"/>
		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="appWhite rounded-lg mt-4"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.entityId="{item}">
				<app-text size="small">{{ item.entityId }}</app-text>
			</template>

			<!--Subject-->
			<template v-slot:item.massEmailSubject="{item}">
				<app-text size="small">{{ item.massEmailSubject }}</app-text>
			</template>

			<!--Recipients-->
			<template v-slot:item.massEmailSendToType="{item}">
				<app-text size="small">{{ item.massEmailSendToType }}</app-text>
			</template>

			<!--Date Sent-->
			<template v-slot:item.createdDateTime="{item}">
				<app-text size="small">
					{{ MIX_formatDate(item.createdDateTime, 'long') }}
					@
					{{ MIX_formatDateTimeToTime(item.createdDateTime) }}
				</app-text>
			</template>

			<!--Action Button-->
			<template v-slot:item.action="{item}">
				<app-icon @click.native="openRightPanel(item)"
						  class="cursorPointer" color="primary" icon="arrowForward"/>
			</template>

		</v-data-table>

		<!--Mobile Cards-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No data message-->
			<app-text v-if="!computedTableData.length"
					  class="text-center mt-4" color="grey9">You have no Emails to view
			</app-text>

			<mass-email-mobile-card v-for="item in computedTableData" :key="item.entityId"
									@click.native="openRightPanel(item)"
									class="mt-4"
									:card-data="item"/>

		</div>

		<!--Filters Panel ------------------------------------------------------------------------------------------ -->
		<filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
			<div class="pa-4">

				<!--Recipients-->
				<page-break-title title="Recipients"/>
				<div class="mt-4">
					<app-form-field form-type="autoComplete"
									:deletable-chips="true"
									:items="['Users', 'Levels', 'Types', 'All']"
									label="Recipients"
									:multiple="true"
									:small-chips="true"
									v-model.trim="filterByRecipients"/>
				</div>

				<!--Users-->
				<page-break-title class="mt-8"
								  description="Select a User to return all emails they've been included in."
								  title="Users"/>
				<div class="mt-2">
					<app-form-field form-type="autoComplete"
									:clearable="true"
									:items="usersData"
									item-text="userName"
									label="Users"
									:return-object="true"
									v-model.trim="filterByUser"/>
				</div>

				<!--Dates-->
				<page-break-title class="mt-8"
								  description="Select a date range to filter the emails by (defaults from now to a year ago)."
								  title="Dates"/>
				<div class="mt-4" style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 16px">
					<date-picker @emitDate="filterByDateFrom = Number($event)"
								 :clearable="false"
								 :date="filterByDateFrom"
								 label="Date from..."
								 :min-date="[1, 'years', 'past']"/>

					<date-picker @emitDate="filterByDateTo = Number($event)"
								 :clearable="false"
								 :date="filterByDateTo"
								 label="...Date up to"
								 :min-date="[1, 'years', 'past']"/>
				</div>


			</div>
		</filter-panel>

		<!--Right Side ----------------------------------------------------------------------------------- Right Side-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">

			<div v-if="isRightPanelVisible"
				 class="appGrey formShadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 100%'">

				<!--Header-->
				<div class="d-flex align-center primary pa-4">
					<app-text color="appWhite" size="normal">{{ selectedItem?.massEmailSubject || 'New' }}</app-text>
					<v-spacer/>
					<app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close"
							  size="32"/>
				</div>

				<!--Action Bar-->
				<div class="d-flex align-center pa-4">

					<v-spacer/>

					<!--<edit-icon @click.native="editItem" :isActive="!isReadOnly"/>-->
					<delete-icon @emitDeleteItem="deleteItem(selectedItem)"
								 item-name="massEmailSubject"
								 :selected-item="selectedItem"/>

				</div>

				<!--Tabs-->
				<v-tabs v-model="tabs">

					<!--Overview-->
					<v-tab href="#overview">
						<app-text size="small">Overview</app-text>
					</v-tab>

				</v-tabs>

				<!--Tabs Content-->
				<v-tabs-items v-model="tabs">

					<!--Overview-->
					<v-tab-item value="overview">
						<mass-emails-form @emitReloadPage="emittedReloadPage"
										  :is-read-only="isReadOnly"
										  :form-data="selectedItem"
										  :users-data="usersData"/>
					</v-tab-item>

				</v-tabs-items>

			</div>

		</transition>

	</div>

</template>

<script>

import MassEmailsForm from "@/views/massEmails/massEmailsForm/MassEmailsForm.vue";
import UsersMobileCard from "@/views/users/userMobileCard/UserMobileCard.vue";
import MassEmailMobileCard from "@/views/massEmails/massEmailMobileCard/MassEmailMobileCard.vue";

export default {

	name: "MassEmails",

	components: {MassEmailMobileCard, UsersMobileCard, MassEmailsForm},

	data: () => ({
		filterByRecipients: [],
		filterByUser: {},
		filterByDateFrom: new Date().setFullYear(new Date().getFullYear() - 1),
		filterByDateTo: new Date().getTime(),
		isFiltersPanelVisible: false,
		isLoading: false,
		isReadOnly: false,
		isRightPanelVisible: false,
		moreActionsMenuOption: [
			{name: 'Export', icon: 'export'}
		],
		searchBySubject: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'entityId', align: 'start', sortable: false, hidden: true},
			{text: 'Subject', value: 'massEmailSubject', align: 'start', sortable: false},
			{text: 'Recipients', value: 'massEmailSendToType', align: 'start', sortable: false},
			{text: 'Date Sent', value: 'createdDateTime', align: 'start', sortable: false, width: '304px'},
			{text: '', value: 'action', align: 'center', sortable: false, width: '48px'},
		],
		tabs: '',

		// Data
		massEmailsData: [],
		usersData: [],
	}),

	computed: {

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter(h => !h.hidden)

			return headers
		},

		/**
		 * Computed Number of Active Filters
		 *
		 * Count the number of active filters to display in the filters button badge.
		 *
		 * @returns {*}
		 */
		computedNumberOfActiveFilters() {
			const t = this
			return [
				t.filterByRecipients.length,
				t.filterByUser?.entityId ? 1 : 0,
			].reduce((acc, curr) => acc + curr, 0)
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the table data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			let tableData = t.massEmailsData

			// Search by Subject
			if (t.searchBySubject) {
				const SEARCH_CRITERIA = t.searchBySubject.toUpperCase()
				tableData = tableData.filter(item => item.massEmailSubject.toUpperCase().includes(SEARCH_CRITERIA))
			}

			// Filter by Recipients
			if (t.filterByRecipients.length) {
				tableData = tableData.filter(item => t.filterByRecipients.includes(item.massEmailSendToType))
			}

			// Filter by User
			if (t.filterByUser?.entityId) {
				tableData = tableData.filter(email => {

					if (email.massEmailSendToType === 'All') return true

					switch (email.massEmailSendToType) {
						case 'Users':
							return email.massEmailSendToListUsers.includes(t.filterByUser.entityId)
						case 'Levels':
							return email.massEmailSendToListLevels.includes(t.filterByUser.userLevel)
						case 'Types':
							return email.massEmailSendToListTypes.includes(t.filterByUser.userType)
						default:
							return true
					}
				})
			}

			// Filter by Dates
			if (t.filterByDateFrom && t.filterByDateTo) {
				tableData = tableData.filter(email => {
					// Add 86,399,999 milliseconds to include the entire day
					const adjustedDateTo = t.filterByDateTo + 86399999
					return email.createdDateTime >= t.filterByDateFrom && email.createdDateTime <= adjustedDateTo
				})
			}

			tableData = tableData.sort((a, b) => a.createdDateTime < b.createdDateTime ? 1 : -1)

			return tableData
		},

	},

	methods: {

		/**
		 * Clear Filters
		 *
		 * Clear all the filters.
		 */
		clearFilters() {
			const t = this

			t.filterByRecipients = []
			t.filterByUser = {}
		},

		/**
		 * Close Right Panel
		 *
		 * Close the right panel.
		 */
		closeRightPanel() {
			const t = this

			t.tabs = ''
			t.selectedItem = {}
			t.isRightPanelVisible = false
		},

		/**
		 *
		 * Delete Item
		 *
		 * Delete the selected document by its ID.
		 *
		 * @param itemData {object} the item to delete
		 */
		async deleteItem(itemData) {
			const t = this

			const RESPONSE = await t.MIX_redis_delete('massEmail', itemData.entityId, itemData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting Mass Email: ', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Deleted Mass Email'

			// Reload the data
			await t.loadData()

			// Close the panel
			t.closeRightPanel()
		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Emitted Reload Page
		 *
		 * Close the panel and Reload the data to refresh the page.
		 */
		emittedReloadPage() {
			const t = this

			t.closeRightPanel()
			t.loadData()
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'New') t.openRightPanel()
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadMassEmailsData(),
				t.loadUsersData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Mass Emails Data
		 *
		 * Load all the mass emails data.
		 *
		 * @returns {Promise<void>}
		 */
		async loadMassEmailsData() {
			const t = this

			// const RESPONSE = await t.MIX_redis_getAll('massEmail')
			const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
				'MassEmail',
				[],
				[
					{field: 'createdDateTime'},
					{field: 'massEmailSendToType'},
					{field: 'massEmailText'},
					{field: 'massEmailSubject'},
					{field: 'massEmailSendToListLevels'},
					{field: 'massEmailSendToListTypes'},
					{field: 'massEmailSendToListUsers'},
				]
			)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading Mass Emails: ', RESPONSE.error)
				return
			}

			t.massEmailsData = RESPONSE.data
		},

		/**
		 * Load Users Data
		 *
		 * Load all the Users data.
		 */
		async loadUsersData() {
			const t = this

			// const RESPONSE = await t.MIX_redis_getUsersWhere([{whereKey: 'userStatus', whereValue: 'Approved'}])
			const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
				'User',
				[{whereKey: 'userStatus', whereValue: 'Approved'}],
				[
					{field: 'userEmail'},
					{field: 'userName'},
					{field: 'userLevel'},
					{field: 'userType'}
				]
			)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading Users: ', RESPONSE.error)
				return
			}

			// Filter out any users without an email
			const RAW_DATA = RESPONSE.data.filter(user => user.userEmail)
			t.usersData = RAW_DATA?.sort((a, b) => a.userName > b.userName ? 1 : -1)
		},

		/**
		 * Open Form
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		openRightPanel(item) {
			const t = this

			if (item?.entityId) {
				t.selectedItem = item
				t.isReadOnly = true
			} else {
				t.selectedItem = {}
				t.isReadOnly = false
			}

			t.isRightPanelVisible = true
		},

		/**
		 * Toggle Filters Visibility
		 *
		 * Toggle the visibility of the filters panel.
		 */
		toggleFiltersVisibility() {
			const t = this

			t.isFiltersPanelVisible = !t.isFiltersPanelVisible
		},

	},

	mounted() {
		const t = this

		// Load the data
		t.loadData()
	},

}
</script>

<style scoped>

</style>
