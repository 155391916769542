<template>
	<div class="appGrey pa-4">

		{{ computedInit }}

		<!--Instructions-->
		<app-text>
			Complete the form to keep
			<strong>{{ selectedItem.userData.userName }}</strong>
			on this team, but extend their shift.
		</app-text>

		<!--Time Selection-->
		<div class="d-flex mt-4">

			<!--Start-->
			<time-picker @emitTime="handleTimeSelection($event, 'eventExtensionStartTime')"
						 :error="errors.eventExtensionStartTime"
						 :error-message="errors.eventExtensionStartTimeErrorMessage"
						 label="Start Time"
						 :time="form.eventExtensionStartTime"/>

			<div class="mx-2"/>

			<!--Finish-->
			<time-picker @emitTime="handleTimeSelection($event, 'eventExtensionFinishTime')"
						 :error="errors.eventExtensionFinishTime"
						 :error-message="errors.eventExtensionFinishTimeErrorMessage"
						 label="Finish Time"
						 :time="form.eventExtensionFinishTime"/>
		</div>

		<!--Role-->
		<app-form-field form-type="select"
						class="mt-4"
						:error="errors.eventExtensionRoleTo"
						:error-message="errors.eventExtensionRoleToErrorMessage"
						:items="['Supervisor', 'Deputy', 'Steward']"
						label="Role"
						placeholder="What role is this user extending as?"
						v-model="form.eventExtensionRoleTo"/>

		<!--Notes-->
		<app-form-field form-type="textArea"
						class="mt-4"
						:error="errors.eventExtensionNotes"
						:error-message="errors.eventExtensionNotesErrorMessage"
						label="Extension Notes"
						placeholder="Why is this shift being extended?"
						v-model.trim="form.eventExtensionNotes"/>

		<v-divider class="mt-4"/>

		<!--Action Buttons-->
		<div class="d-flex justify-space-between mt-4">

			<!--Cancel-->
			<app-btn @click.native="closeDialog"
					 color="grey"
					 icon="cancel"
					 label="Cancel"/>

			<!--Yes-->
			<app-btn @click.native="handleYesButton"
					 color="green"
					 icon="success"
					 label="Save"/>

		</div>

	</div>
</template>

<script>
export default {

	name: "EventControlPanelExtensionDialog",

	props: ['selectedItem'],

	data: () => ({
		errors: {
			eventExtensionStartTime: false,
			eventExtensionStartTimeErrorMessage: '',
			eventExtensionFinishTime: false,
			eventExtensionFinishTimeErrorMessage: '',
			eventExtensionRoleTo: false,
			eventExtensionRoleToErrorMessage: '',
			eventExtensionNotes: false,
			eventExtensionNotesErrorMessage: '',
		},
		form: {
			entityId: '',

			eventExtensionEarlyFinishNotes: '',
			eventExtensionEarlyFinishTime: '0',
			eventExtensionEventId: '',
			eventExtensionFinishTime: '0',
			eventExtensionHours: 0,
			eventExtensionIsNoShow: false,
			eventExtensionIsNoShowNotes: '',
			eventExtensionLateArrivalNotes: '',
			eventExtensionLateArrivalTime: '0',
			eventExtensionNotes: '',
			eventExtensionRoleFrom: '',
			eventExtensionRoleTo: '',
			eventExtensionSignInTime: '0',
			eventExtensionSignOutNotes: '',
			eventExtensionSignOutTime: '0',
			eventExtensionStartTime: '0',
			eventExtensionSwappInTime: '0',
			eventExtensionSwappOutTime: '0',
			eventExtensionSwappStatus: 'Out',
			eventExtensionTeamId: '',
			eventExtensionUserId: '',

			createdUserId: '',
			createdUserName: '',
			createdDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			modifiedDateTime: 0,
			isDeleted: false,
			deletedUserId: '',
			deletedUserName: '',
			deletedDateTime: 0,
		},
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * Initialise the component.
		 */
		computedInit() {
			const t = this
			const FORM_DATA = t.$props.selectedItem

			// If the selected item has eventExtensionData, set the form to it
			if (FORM_DATA.eventExtensionData?.entityId) t.form = FORM_DATA.eventExtensionData
		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Close Dialog
		 *
		 * Emit back to the parent to close the dialog box.
		 */
		closeDialog() {
			const t = this

			t.$emit('emitCloseEventControlPanelExtensionDialog')
		},

		/**
		 * Handle Time Selection
		 *
		 * Take the time from the picker and store it.
		 *
		 * @param time the time string from the picker
		 * @param field the form field to set
		 */
		handleTimeSelection(time, field) {
			const t = this

			// Set the time to the current form field
			t.form[field] = time
		},

		/**
		 * Handle Yes Button
		 *
		 * Set the time either to the selected time, or now if no selection is made.
		 * Emit back to the parent to update the EventUser document's time.
		 */
		handleYesButton() {
			const t = this
			const SELECTED_ITEM = t.$props.selectedItem
			const FORM_DATA = t.form

			// Only continue if the form is valid
			if (!t.validateForm()) return

			if (FORM_DATA.eventExtensionRoleTo === 'Steward') FORM_DATA.eventExtensionRoleTo = 'User'
			FORM_DATA.eventExtensionEventId = SELECTED_ITEM.eventData.entityId
			FORM_DATA.eventExtensionHours = t.MIX_formatTimeToHHMM(FORM_DATA.eventExtensionStartTime, FORM_DATA.eventExtensionFinishTime)?.decimalTime
			FORM_DATA.eventExtensionRoleFrom = SELECTED_ITEM.userData.userRole
			FORM_DATA.eventExtensionTeamId = SELECTED_ITEM.teamData.entityId
			FORM_DATA.eventExtensionUserId = SELECTED_ITEM.userData.entityId

		},

		/**
		 * Validate Form
		 *
		 * Validate the form and return true or false if it is valid, or not.
		 *
		 * @returns {boolean}
		 */
		validateForm() {
			const t = this

			// Clear any existing errors
			t.clearErrors()

			// Start Time
			if (t.form.eventExtensionStartTime === '0') {
				t.errors.eventExtensionStartTime = true
				t.errors.eventExtensionStartTimeErrorMessage = 'Start Time is required.'
			}
			// Else, if the start time is after the finish time
			else if (t.form.eventExtensionStartTime > t.form.eventExtensionFinishTime) {
				t.errors.eventExtensionStartTime = true
				t.errors.eventExtensionStartTimeErrorMessage = 'Start Time cannot be after Finish Time.'
			}

			// Finish Time
			if (t.form.eventExtensionFinishTime === '0') {
				t.errors.eventExtensionFinishTime = true
				t.errors.eventExtensionFinishTimeErrorMessage = 'Finish Time is required.'
			}
			// Else, if the finish time is before the start time
			else if (t.form.eventExtensionFinishTime < t.form.eventExtensionStartTime) {
				t.errors.eventExtensionFinishTime = true
				t.errors.eventExtensionFinishTimeErrorMessage = 'Finish Time cannot be before Start Time.'
			}

			// Role
			if (!t.form.eventExtensionRoleTo) {
				t.errors.eventExtensionRoleTo = true
				t.errors.eventExtensionRoleToErrorMessage = 'A Role is required.'
			}

			// Notes
			if (!t.form.eventExtensionNotes.trim()) {
				t.errors.eventExtensionNotes = true
				t.errors.eventExtensionNotesErrorMessage = 'Notes are required.'
			}

			// Return true if there are no errors
			return !Object.values(t.errors).includes(true)
		},

	},

}
</script>

<style scoped>

</style>
