<template>
	<div>

		<!--Action Bar-->
		<div class="d-flex align-center mt-4">

			<!--Search-->
			<app-form-field form-type="textInput"
							append-icon="icons8-search"
							class="mr-4"
							:clearable="true"
							label="Issue"
							style="width: 100%"
							v-model.trim="searchByIssue"/>

			<!--Add Button-->
			<app-btn @click.native="openRightPanel" class="mr-4" icon="add" label="Add"/>

			<!--Filter Button - with a numbered badge-->
			<div style="position:relative;">
				<app-btn @click.native="toggleFiltersVisibility"
						 class="mr-4" color="appWhite" icon="filter" icon-color="primary"/>
				<span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>
			</div>

			<!--More Actions Menu-->
			<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>

		</div>

		<!--Clear Filters-->
		<div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
			<app-btn @click.native="clearFilters" icon="close" label="Clear Filters"/>
		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="appWhite rounded-lg mt-4"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.id="{item}">
				<app-text size="small">{{ item.id }}</app-text>
			</template>

			<!--Status-->
			<template v-slot:item.observationStatus="{item}">
				<app-icon v-if="item.observationStatus === 'Pending'"
						  color="red" icon="draft" size="32" :title="item.observationStatus"/>
				<app-icon v-if="item.observationStatus === 'In Progress'"
						  color="orange" icon="pending" size="32" :title="item.observationStatus"/>
				<app-icon v-if="item.observationStatus === 'On Hold'"
						  color="red" icon="onHold" size="32" :title="item.observationStatus"/>
				<app-icon v-if="item.observationStatus === 'Resolved'"
						  color="green" icon="success" size="32" :title="item.observationStatus"/>
			</template>

			<!--Title-->
			<template v-slot:item.observationTitle="{item}">
				<app-text size="small">{{ item.observationTitle }}</app-text>
			</template>

			<!--Type-->
			<template v-slot:item.observationType="{item}">
				<app-text size="small">{{ item.observationType }}</app-text>
			</template>

			<!--Reported By User-->
			<template v-slot:item.createdUserName="{item}">
				<app-text size="small">{{ item.createdUserName }}</app-text>
			</template>

			<!--Date Time Occurred-->
			<template v-slot:item.observationDateOccurred="{item}">
				<app-text size="small">
					{{ MIX_formatDate(item.observationDateOccurred, 'short') }} @ {{ item.observationTimeOccurred }}
				</app-text>
			</template>

			<!--Action Button-->
			<template v-slot:item.action="{item}">
				<app-icon @click.native="openRightPanel(item)"
						  class="cursorPointer" color="primary" icon="arrowForward"/>
			</template>

		</v-data-table>

		<!--Mobile Cards-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No Reports Message-->
			<app-text v-if="!computedTableData.length" class="text-center mt-4" color="grey9">
				You have no Reports to view
			</app-text>

			<observation-mobile-card v-for="item in computedTableData" :key="item.id"
									 @click.native="openRightPanel(item)"
									 class="mt-4"
									 :report="item"/>

		</div>

		<!--Filters Panel ------------------------------------------------------------------------------------------ -->
		<filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
			<div class="pa-4">

				<!--Statuses-->
				<page-break-title title="Statuses"/>
				<div class="mt-4"
					 style="display: grid; grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); grid-gap: 16px">
					<app-btn @click.native="handleFilterStatusSelection('Pending')"
							 :block="true"
							 color="red"
							 label="Pending"
							 :outlined="!filterByStatuses.includes('Pending')"/>
					<app-btn @click.native="handleFilterStatusSelection('In Progress')"
							 :block="true"
							 color="orange"
							 label="In Progress"
							 :outlined="!filterByStatuses.includes('In Progress')"/>
					<app-btn @click.native="handleFilterStatusSelection('On Hold')"
							 :block="true"
							 color="red"
							 label="On Hold"
							 :outlined="!filterByStatuses.includes('On Hold')"/>
					<app-btn @click.native="handleFilterStatusSelection('Resolved')"
							 :block="true"
							 color="green"
							 label="Resolved"
							 :outlined="!filterByStatuses.includes('Resolved')"/>
				</div>

				<!--Priorities-->
				<page-break-title class="mt-8" title="Priorities"/>
				<div class="mt-4">
					<app-form-field form-type="autoComplete"
									:deletable-chips="true"
									:items="observationPriorityOptions"
									label="Priorities"
									:multiple="true"
									:small-chips="true"
									v-model="filterByPriorities"/>
				</div>

				<!--Sites-->
				<page-break-title class="mt-8" title="Sites"/>
				<div class="mt-4">
					<app-form-field form-type="autoComplete"
									:deletable-chips="true"
									:items="sitesData"
									item-text="siteName"
									label="Sites"
									:multiple="true"
									:small-chips="true"
									v-model="filterBySites"/>
				</div>

				<!--Location-->
				<page-break-title class="mt-8" title="Location"/>
				<div class="mt-4">
					<app-form-field form-type="textInput"
									label="Location"
									v-model.trim="filterByLocation"/>
				</div>

				<!--Reporter-->
				<page-break-title class="mt-8" title="Reporter"/>
				<div class="mt-4">
					<app-form-field form-type="textInput"
									label="Reporter"
									v-model.trim="filterByReporter"/>
				</div>

			</div>
		</filter-panel>

		<!--Right Side ----------------------------------------------------------------------------------- Right Side-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">

			<div v-if="isRightPanelVisible"
				 class="appGrey formShadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'">

				<!--Header-->
				<div class="d-flex align-center primary pa-4">
					<app-text color="appWhite" size="normal">{{ selectedItem?.observationTitle || 'New' }}</app-text>
					<v-spacer/>
					<app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close"
							  size="32"/>
				</div>

				<!--Action Bar-->
				<div class="d-flex align-center pa-4">

					<!--Status Icons-->
					<app-icon v-if="selectedItem?.observationStatus === 'Pending'"
							  color="red" icon="draft" size="48"/>
					<app-icon v-if="selectedItem?.observationStatus === 'In Progress'"
							  color="orange" icon="pending" size="48"/>
					<app-icon v-if="selectedItem?.observationStatus === 'On Hold'"
							  color="red" icon="onHold" size="48"/>
					<app-icon v-if="selectedItem?.observationStatus === 'Resolved'"
							  color="green" icon="success" size="48"/>

					<v-spacer/>

					<edit-icon
						v-if="!['reporting', 'evidence'].includes(tabs) && selectedItem?.observationStatus !== 'Resolved' && MIX_getCurrentUser().userLevel === 'Staff-Admin'"
						@click.native="editItem" :isActive="!isReadOnly"/>
					<!--<delete-icon v-if="tabs !== 'evidence'" @emitDeleteItem="deleteItem(selectedItem)"-->
					<!--			 itemName="observationTitle"-->
					<!--			 :selectedItem="selectedItem"/>-->

				</div>

				<!--Tabs-->
				<v-tabs v-model="tabs">

					<!--Reporting-->
					<v-tab href="#reporting">
						<app-text size="small">Reporting</app-text>
					</v-tab>

					<!--Evidence-->
					<v-tab v-if="selectedItem?.entityId" href="#evidence">
						<app-text size="small">Evidence</app-text>
					</v-tab>

					<!--Responding-->
					<v-tab v-if="selectedItem?.entityId" href="#responding">
						<app-text size="small">Responding</app-text>
					</v-tab>

				</v-tabs>

				<!--Tabs Content-->
				<v-tabs-items v-model="tabs">

					<!--Reporting-->
					<v-tab-item value="reporting">
						<observation-reporting-form @emitReloadData="closeRightPanel"
													class="pa-4"
													:formData="selectedItem"
													:isReadOnly="isReadOnly"
													:sitesData="sitesData"/>
					</v-tab-item>

					<!--Evidence-->
					<v-tab-item value="evidence">
						<observation-evidence-form class="pa-4"
												   :formData="selectedItem"
												   :isReadOnly="isReadOnly"/>
					</v-tab-item>

					<!--Responding-->
					<v-tab-item value="responding">
						<observation-responding-form @emitReloadData="closeRightPanel"
													 class="pa-4"
													 :formData="selectedItem"
													 :isReadOnly="isReadOnly"/>
					</v-tab-item>

				</v-tabs-items>

			</div>

		</transition>

	</div>
</template>

<script>
import ObservationMobileCard from "@/views/reporting/observations/observationMobileCard/ObservationMobileCard.vue";
import ObservationReportingForm
	from "@/views/reporting/observations/observationReportingForm/ObservationReportingForm.vue";
import ObservationRespondingForm
	from "@/views/reporting/observations/observationRespondingForm/ObservationRespondingForm.vue";
import ObservationEvidenceForm
	from "@/views/reporting/observations/observationEvidenceForm/ObservationEvidenceForm.vue";

export default {

	name: "ObservationsPage",

	components: {
		ObservationEvidenceForm,
		ObservationRespondingForm,
		ObservationReportingForm,
		ObservationMobileCard
	},

	data: () => ({
		isFiltersPanelVisible: false,
		filterByLocation: '',
		filterByPriorities: [],
		filterByReporter: '',
		filterByResponder: '',
		filterBySites: [],
		filterByStatuses: [],
		isRightPanelVisible: false,
		isLoading: true,
		isReadOnly: false,
		moreActionsMenuOption: [
			{name: 'Export', icon: 'export'}
		],
		reportType: 'Stadium Reports',
		searchByIssue: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'id', align: 'start', sortable: false, hidden: true},
			{text: 'Status', value: 'observationStatus', align: 'center', sortable: false, width: '10px'},
			{text: 'Date Occurred', value: 'observationDateOccurred', align: 'start', sortable: false, width: '200px'},
			{text: 'Issue', value: 'observationTitle', align: 'start', sortable: false},
			{text: 'Type', value: 'observationType', align: 'start', sortable: false},
			{text: 'Reporter', value: 'createdUserName', align: 'start', sortable: false},
			{text: '', value: 'action', align: 'center', sortable: false, width: '48px'},
		],
		tabs: '',

		// Data
		observationsData: [],
		sitesData: [],
	}),

	computed: {

		/**
		 * Computed Export CSV
		 *
		 * Return the data and headers for the CSV export
		 *
		 * @returns {{headers: {}, data: *[]}}
		 */
		computedExportCSV() {
			const t = this
			let data = []
			let headers = {}

			// Add the readable headers for the CSV columns
			headers = {
				entityId: 'ID',
				observationTitle: 'Issue',
				observationType: 'Type',
				observationPriority: 'Priority',
				observationDateOccurred: 'Date Occurred',
				observationTimeOccurred: 'Time Occurred',
				observationSite: 'Site',
				observationLocation: 'Location',
				observationReportingDescription: 'Reporting Description',
				observationReportingStepsTaken: 'Steps Taken',
				observationRespondingUser: 'Responding User',
				observationRespondingDescription: 'Responding Description',
				observationStatus: 'Status',
			}

			// Add the data
			t.computedTableData.forEach(entry => {

				console.log(entry)

				const DATA_OBJECT = {
					entityId: entry.entityId,
					observationTitle: entry.observationTitle,
					observationType: entry.observationType,
					observationPriority: entry.observationPriority,
					observationDateOccurred: t.MIX_formatDate(entry.observationDateOccurred, 'numeric'),
					observationTimeOccurred: entry.observationTimeOccurred,
					observationSite: entry.observationSite,
					observationLocation: entry.observationLocation,
					observationReportingDescription: entry.observationReportingDescription,
					observationReportingStepsTaken: entry.observationReportingStepsTaken,
					observationRespondingUser: entry.observationRespondingUser,
					observationRespondingDescription: entry.observationRespondingDescription,
					observationStatus: entry.observationStatus,
				}

				data.push(DATA_OBJECT)
			})

			return {headers, data}
		},

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			let headers = t.tableHeaders

			headers = headers.filter(h => !h.hidden)

			return headers
		},

		/**
		 * Computed Number of Active Filters
		 *
		 * Count the number of active filters to display in the filters button badge.
		 *
		 * @returns {*}
		 */
		computedNumberOfActiveFilters() {
			const t = this
			return [
				t.filterByLocation ? 1 : 0,
				t.filterByPriorities.length,
				t.filterByReporter ? 1 : 0,
				t.filterBySites.length,
				t.filterByStatuses.length,
			].reduce((acc, curr) => acc + curr, 0)
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the form data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()
			let observationsData = t.observationsData

			// Staff Admin and Steward Management can see all, everyone else can only see their own
			if (!['Staff-Admin', 'Steward-Management'].includes(CURRENT_USER_DATA.userLevel)) observationsData = observationsData.filter(obs => obs.createdUserId === CURRENT_USER_DATA.entityId)

			// Search by Issue
			if (t.searchByIssue) {
				const SEARCH_CRITERIA = t.searchByIssue.toUpperCase()
				observationsData = observationsData.filter(o => {
					const OBSERVATION_TITLE = o.observationTitle.toUpperCase()
					return OBSERVATION_TITLE.includes(SEARCH_CRITERIA)
				})
			}

			// Filter by Status
			if (t.filterByStatuses.length) observationsData = observationsData.filter(o => t.filterByStatuses.includes(o.observationStatus))

			// Filter by Priority
			if (t.filterByPriorities.length) observationsData = observationsData.filter(o => t.filterByPriorities.includes(o.observationPriority))

			// Filter by Site
			if (t.filterBySites.length) observationsData = observationsData.filter(o => t.filterBySites.includes(o.observationSite))

			// Filter by Location
			if (t.filterByLocation) {
				const SEARCH_CRITERIA = t.filterByLocation.toUpperCase()
				observationsData = observationsData.filter(o => {
					const OBSERVATION_LOCATION = o.observationLocation.toUpperCase()
					return OBSERVATION_LOCATION.includes(SEARCH_CRITERIA)
				})
			}

			// Filter by Reporter
			if (t.filterByReporter) {
				const SEARCH_CRITERIA = t.filterByReporter.toUpperCase()
				observationsData = observationsData.filter(o => {
					const OBSERVATION_REPORTER = o.createdUserName.toUpperCase()
					return OBSERVATION_REPORTER.includes(SEARCH_CRITERIA)
				})
			}

			// Sort by Title
			observationsData = observationsData.sort((a, b) => a.observationDateOccurred < b.observationDateOccurred ? 1 : -1)

			return observationsData
		},

	},

	methods: {

		/**
		 * Clear Filters
		 *
		 * Clear all the filters.
		 */
		clearFilters() {
			const t = this

			t.filterByLocation = ''
			t.filterByPriorities = []
			t.filterByReporter = ''
			t.filterByResponder = ''
			t.filterBySites = []
			t.filterByStatuses = []
		},

		/**
		 * Close Right Panel
		 *
		 * Close the right panel and reload the data to refresh the page.
		 */
		closeRightPanel() {
			const t = this

			t.tabs = ''
			t.selectedItem = {}
			t.isRightPanelVisible = false
			t.loadData()
		},

		/**
		 * Delete Item
		 *
		 * Delete the selected item (only marks as deleted).
		 *
		 * @param itemData {object} the item to delete
		 */
		async deleteItem(itemData) {
			const t = this

			const RESPONSE = await t.MIX_redis_delete('observation', itemData.entityId, itemData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting Observation Report: ', RESPONSE.error)
				return
			}

			t.closeRightPanel()
		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Export Data
		 *
		 * Export the data to CSV.
		 */
		exportData() {
			const t = this

			t.MIX_exportDocuments(t.computedExportCSV.headers, 'Observations', t.computedExportCSV.data)
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'Export') t.exportData()
		},

		/**
		 * Handle Filter Status Selection
		 *
		 * Add or remove the selected status from the filterStatuses array.
		 *
		 * @param status {string} the status to add or remove
		 */
		handleFilterStatusSelection(status) {
			const t = this

			if (t.filterByStatuses.includes(status)) t.filterByStatuses = t.filterByStatuses.filter(s => s !== status)
			else t.filterByStatuses.push(status)
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadObservationsData(),
				t.loadSitesData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Observations Data
		 *
		 * Load Observations data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadObservationsData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('observation')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Observations: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.observationsData = RESPONSE.data
		},

		/**
		 * Load Sites Data
		 *
		 * Load Sites data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadSitesData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('site')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Sites: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.sitesData = RESPONSE.data
		},

		/**
		 * Open Form
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		openRightPanel(item) {
			const t = this

			t.isReadOnly = !!item?.entityId

			t.selectedItem = item

			t.isRightPanelVisible = true
		},

		/**
		 * Toggle Filters Visibility
		 *
		 * Toggle the visibility of the filters panel.
		 */
		toggleFiltersVisibility() {
			const t = this

			t.isFiltersPanelVisible = !t.isFiltersPanelVisible
		},

	},

	async created() {
		const t = this

		await t.loadData()
	},

}
</script>

<style scoped>
</style>
