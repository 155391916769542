<template>
	<v-overlay class="d-flex flex-column align-center" color="white" opacity="0.5" :value="isLoading">

		<v-progress-circular color="primary" indeterminate size="160">

			<v-img :src="require('@/assets/images/company-logo.svg')" width="96" max-height="96" contain/>

		</v-progress-circular>

		<app-btn @click.native="MIX_go('/')" :block="true" class="mt-8" color="primary" label="Cancel"/>

	</v-overlay>
</template>

<script>

export default {

	name: 'PageLoadingAnimation',

	props: ['isLoading'],

}
</script>

<style>

</style>
